// packages
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { RadioGroup, RadioButton } from '@upsiide/ui-components';
import { useTranslation } from 'react-i18next';

// utilities
import cn from 'src/utilities/bem-cn';
import * as misc from 'src/utilities/misc';
import useIsKeyboardNavigation from 'src/utilities/hooks/useIsKeyboardNavigation';

// components
import ImageZoom from 'src/components/new/ImageZoom';
import Input from 'src/components/shared/Input';

// styles
import './styles.scss';

const className = 'question-single-select';
const el = (name, mod) => cn(className, name, mod);

const SingleSelect = ({
	question,
	disabled,
	value,
	options,
	onChange,
	otherSelected,
	onOtherChange,
	currentAttribute,
}) => {
	// state
	const [otherValue, setOtherValue] = useState(null);
	const [focusedOptionId, setFocusedOptionId] = useState(null);
	const [isGroupFocused, setIsGroupFocused] = useState(false);

	const optionsRef = useRef([]);

	const isKeyboardNavigation = useIsKeyboardNavigation();

	const imageOnly = misc.getQuestionSetting(question, 'image_only') === 'true';

	const { t } = useTranslation('main');

	const optionsHaveAsset = useMemo(() => options.find((option) => option.asset), [options]);

	const classNames = useMemo(() => {
		const hasOther = options.filter((o) => o.isOtherSpecify).length > 0 ? ' has-other-please-specify' : '';
		const hasNoneOfTheAbove = options.filter((o) => o.isNoneOfTheAbove).length > 0 ? ' has-none-of-the-above' : '';
		return `${className} ${!optionsHaveAsset ? ' no-asset' : ''}${
			imageOnly ? ' image-only' : ''
		}${hasOther}${hasNoneOfTheAbove}`;
	}, [options, optionsHaveAsset, imageOnly]);

	// functions
	const onOtherValueChange = (updatedValue) => {
		if (onOtherChange) {
			onOtherChange(updatedValue);
		}
		setOtherValue(updatedValue);
	};

	const handleRadioButtonFocus = useCallback(
		(e, optionId) => {
			const isRadio = e.target.type === 'radio';
			if (!isKeyboardNavigation || !isRadio) return;
			setFocusedOptionId(optionId);
		},
		[isKeyboardNavigation],
	);

	const handleRadioButtonBlur = useCallback(() => setFocusedOptionId(null), []);

	const moveFocus = useCallback(
		(e, index, move = 1) => {
			const nextIndex = index + move;

			if (nextIndex >= 0 && nextIndex > options.length - 1) return;

			e.preventDefault();

			const nextOptionElement = optionsRef.current[nextIndex];

			nextOptionElement?.focus();
		},
		[options.length],
	);

	const handleRadioButtonKeyDown = useCallback(
		(e, index, includesAsset) => {
			// * Tab key navigation
			const isTabPressed = e.key === 'Tab';
			const isShiftPressed = e.shiftKey;

			if (!isTabPressed || (!isShiftPressed && includesAsset) || (isShiftPressed && index === 0)) return;

			const move = isShiftPressed ? -1 : 1;

			moveFocus(e, index, move);
		},
		[moveFocus],
	);

	const handleImageKeyDown = (e, index) => {
		e.stopPropagation();

		// * Tab key navigation
		const isTabPressed = e.key === 'Tab';

		if (!isTabPressed) return;

		const isShiftPressed = e.shiftKey;

		const move = isShiftPressed ? 0 : 1;

		moveFocus(e, index, move);
	};

	// life cycle
	useEffect(() => {
		setOtherValue('');
	}, [currentAttribute]);

	useEffect(() => {
		const pageContainer = document.querySelector('.scroll-container');
		if (pageContainer) {
			pageContainer.style.scrollBehavior = 'auto';
			pageContainer.scrollTo(0, 0);
		}
		// Reset scroll container
		const scrollContainer = document.querySelector(`[class*='scroll-container']`);
		if (scrollContainer) {
			scrollContainer.classList.remove('scrolled-to-bottom');
			scrollContainer.classList.remove('remove-scroll-indicator');
		}
	}, []);

	// render functions
	const renderImageOption = (option, index) =>
		option.asset && (
			<ImageZoom onKeyDown={(e) => handleImageKeyDown(e, index)} className={el('option-image-container')}>
				<img
					className={`${el('option-image')} ${
						misc.getQuestionSetting(question, 'option_image_cropping') === 'crop'
							? el('option-image-crop')
							: ''
					}`}
					alt={option.label || option.value}
					src={misc.getAssetVariationUrl(option, ['large', 'full', 'medium', 'thumbnail'])}
				/>
			</ImageZoom>
		);

	const renderSingleOption = (option, index) => {
		const isChecked = (value || []).indexOf(option.id.toString()) > -1;

		return (
			<RadioButton
				id={option.id.toString()}
				name={`question-${question.id}`} // Match the RadioGroup name
				disabled={disabled}
				checked={isChecked}
				key={option.id}
				onClick={(e) => {
					onChange(e);
				}}
				value={option.id.toString()}
				style={{ outline: focusedOptionId === option.id ? '2px solid #28B681' : 'none', outlineOffset: '2px' }}
				inputProps={{
					onFocus: (e) => handleRadioButtonFocus(e, option.id),
					onBlur: handleRadioButtonBlur,
					onKeyDown: (e) => handleRadioButtonKeyDown(e, index, !!option.asset),
					'aria-label':
						option.label ||
						option?.translations?.find(({ languageCode }) => languageCode === option?.languageCode)
							?.label ||
						option.value ||
						'',
				}}
				ref={(ref) => (optionsRef.current[index] = ref)}
			>
				{!imageOnly || !option.asset || option.isNoneOfTheAbove || option.isOtherSpecify ? (
					<span
						className={`${el('option-label')}${option.asset ? ' has-asset' : ''}${
							option.isOtherSpecify && otherSelected ? ' hidden' : ''
						}`}
						aria-hidden="true"
					>
						{option.label || option.value}
					</span>
				) : null}
				{option.isOtherSpecify && otherSelected && (
					<Input
						id="other-input-field"
						className={el('other-input')}
						placeholder={t('please-specify')}
						value={otherValue}
						onChange={onOtherValueChange}
						onFocus={handleRadioButtonBlur}
					/>
				)}
				{renderImageOption(option, index)}
			</RadioButton>
		);
	};

	return (
		<RadioGroup
			className={classNames}
			name={`question-${question.id}`} // Add unique name to group radio buttons
			role="radiogroup"
			onFocus={(e) => {
				// * If focus moves into the group, always focus the first radio button
				if (!isGroupFocused) {
					const isComingFromOutside = !optionsRef.current.some((radio) => radio.contains(e.relatedTarget));
					if (isComingFromOutside) {
						setIsGroupFocused(true);
						optionsRef.current?.[0]?.focus();
					}
				}
			}}
			onBlur={(e) => {
				// * Check if the focus moved completely out of the radio group
				if (!e.currentTarget.contains(e.relatedTarget)) {
					setIsGroupFocused(false);
				}
			}}
		>
			{options.map((option, index) => renderSingleOption(option, index))}
		</RadioGroup>
	);
};

SingleSelect.propTypes = {
	question: PropTypes.object.isRequired,
	disabled: PropTypes.bool,
	value: PropTypes.string,
	options: PropTypes.array,
	onChange: PropTypes.func,
	otherSelected: PropTypes.bool,
	onOtherChange: PropTypes.func,
	currentAttribute: PropTypes.string,
};

export default SingleSelect;
