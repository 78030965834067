// packages
import React, { useEffect, useRef } from 'react';
import { PropTypes } from 'prop-types';

// utilities
import cn from 'src/utilities/bem-cn';

// components
import Button from 'src/components/new/Button';
import AiBanner from '../AiBanner';

// styles
import './styles.scss';

const className = 'welcome-checker';

const el = (name) => cn(className, name);

const WelcomeChecker = ({ handleContinue, setAreYouSure }) => {
	// state
	const initFocusRef = useRef(null);

	// life cycle
	useEffect(() => {
		initFocusRef.current?.focus();
	}, []);

	return (
		<div className={className}>
			<div className={el('container')}>
				<h2 className={el('title')} tabIndex="-1" ref={initFocusRef}>
					This survey contains video questions
				</h2>

				<div className={el('content')}>
					<span className={el('desc')}>
						If you choose to participate, you’ll be asked to record a video response using your camera and
						microphone.
					</span>
				</div>

				<div className={el('footer')}>
					<Button isBold label="Continue" onClick={handleContinue} aria-describedby="permission-terms" />

					<Button isBold color="secondary" label="Skip video questions" onClick={() => setAreYouSure(true)} />
				</div>
				<div className={el('disclaimer-container')}>
					<span id="permission-terms" className={el('disclaimer-text')}>
						By clicking ‘Continue,’ you consent to provide video responses and grant Upsiide and our clients
						a non-exclusive license to use, reproduce, and distribute your video for internal research and
						analysis. This consent is indefinite unless withdrawn in writing.
					</span>
				</div>
			</div>
			<div className={el('left-side-container')}>
				<AiBanner />
			</div>
		</div>
	);
};

WelcomeChecker.propTypes = {
	handleContinue: PropTypes.func.isRequired,
	setAreYouSure: PropTypes.func.isRequired,
};

export default WelcomeChecker;
