import React from 'react';

const NotAllowedIcon = () => (
	<svg width="20px" height="33px" viewBox="0 0 20 33">
		<defs>
			<linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-1">
				<stop stopColor="#F0F0F0" offset="0%" />
				<stop stopColor="#D6D6D6" offset="100%" />
			</linearGradient>
		</defs>
		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g transform="translate(-193.000000, -84.000000)">
				<g transform="translate(193.000000, 84.000000)">
					<path
						d="M10,29.5 C6.416,29.5 3.5,26.584 3.5,23 C3.5,21.78 3.837,20.609 4.48,19.579 L13.421,28.52 C12.391,29.163 11.22,29.5 10,29.5 L10,29.5 Z M6.607,17.463 C7.63,16.831 8.792,16.5 10,16.5 C13.584,16.5 16.5,19.416 16.5,23 C16.5,24.208 16.169,25.37 15.537,26.393 L6.607,17.463 Z M10,13.5 C4.762,13.5 0.5,17.762 0.5,23 C0.5,28.238 4.762,32.5 10,32.5 C15.238,32.5 19.5,28.238 19.5,23 C19.5,17.762 15.238,13.5 10,13.5 L10,13.5 Z"
						id="cursor"
						fill="url(#linearGradient-1)"
					/>
					<path
						d="M0,16.4219 L0,0.4069 L11.591,12.0259 L4.55,12.0259 L4.399,12.1499 L0,16.4219 Z"
						id="cursor"
						fill="#FFFFFF"
					/>
					<path
						d="M1,2.814 L1,14.002 L3.969,11.136 L4.129,10.997 L9.165,10.997 L1,2.814 Z"
						id="cursor"
						fill="#000000"
					/>
				</g>
			</g>
		</g>
	</svg>
);

export default NotAllowedIcon;
