// packages
import React from 'react';

// styles
import './styles.scss';

const className = 'heatmap-cursor-crosshair-icon';

const CrosshairIcon = () => (
	<svg width="15px" height="15px" viewBox="0 0 15 15" {...{ className }}>
		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g transform="translate(-302.000000, -35.000000)">
				<g transform="translate(302.000000, 35.000000)">
					<path
						d="M15,6 L8.99,6 L8.99,0 L6.01,0 L6.01,6 L0,6 L0,9 L6.01,9 L6.01,15 L8.99,15 L8.99,9 L15,9 L15,6 Z"
						id="cursor"
						fill="#FFFFFF"
					/>
					<path
						d="M13.9902,7.0103 L7.9902,7.0103 L7.9902,1.0003 L7.0102,1.0003 L7.0102,7.0103 L1.0102,7.0103 L1.0102,7.9903 L7.0102,7.9903 L7.0102,14.0003 L7.9902,14.0003 L7.9902,7.9903 L13.9902,7.9903 L13.9902,7.0103 Z"
						id="cursor"
						fill="#231F1F"
					/>
				</g>
			</g>
		</g>
	</svg>
);

export default CrosshairIcon;
