// packages
import React, { useEffect, useMemo, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';
// assets
import noCameraCanvasPlaceholder from 'public/images/person-placeholder.svg';
import RoundedMic from 'public/images/rounded-mic.svg';
import RoundedCamera from 'public/images/rounded-camera.svg';
import ArrowOutward from 'public/images/arrow_outward.svg';
import CameraAndMicIcon from 'public/images/camera-mic-icon.svg';
import ChromeSettingIcon from 'public/images/setting-icon.svg';
import FirefoxSettingIcon from 'public/images/firefox-setting-icon.svg';
import EdgeSettingIcon from 'public/images/edge-setting-icon.svg';
import EdgePadlock from 'public/images/edge-padlock.png';
import FirefoxPadlock from 'public/images/firefox-padlock.png';

// utilities
import cn from 'src/utilities/bem-cn';
import { detectBrowser } from 'src/utilities/misc';
// hooks
import useIsMobile from 'src/utilities/hooks/useIsMobile';
import useElementRect from 'src/utilities/hooks/useElementRect';
// components
import Button from 'src/components/new/Button';
import Preview from '../PreviewChecker/Preview';
import ModalPermissionSettings from '../ModalPermissionSettings';
import AiBanner from '../AiBanner';
// selectors
import * as selectors from '../../../../../../selectors';
// styles
import './styles.scss';

const className = 'permission-checker';
const el = (name) => cn(className, name);
const FREQUENCY_BIN_COUNT = 2 ** 5;
const dataArray = new Uint8Array(FREQUENCY_BIN_COUNT);
const PermissionCheck = ({ qualityTerminate, setShowPreview, setAreYouSure }) => {
	const [squareWrapperRect, squareWrapperRef] = useElementRect();

	const [hasClickedEnabled, setHasClickedOnEnabled] = useState(false);
	const squareWrapperSideSize = useMemo(() => squareWrapperRect.width, [squareWrapperRect.width]);
	const canvasWidth = useMemo(() => squareWrapperSideSize || 600, [squareWrapperSideSize]);
	const canvasHeight = useMemo(() => squareWrapperSideSize || 600, [squareWrapperSideSize]);
	// refs

	const cameraSettings = useRef({
		originX: 0,
		originY: 0,
		destW: 380,
		destH: 380,
		translateX: 0,
		scaleX: 1,
		canvasWidth,
		canvasHeight,
	});
	const permissionSettingsRef = useRef({ camera: false, microphone: false });
	const timeoutRef = useRef(true);
	const initFocusRef = useRef(null);
	// hooks
	const isMobile = useIsMobile();
	// state
	const [permissionSettings, setPermissionSettings] = useState(false);
	const [isSystemDenied, setIsSystemDenied] = useState(false);
	const [canGoNext, setCanGoNext] = useState(false);
	const [audioPermission, setAudioPermission] = useState(false);
	const [cameraPermission, setCameraPermission] = useState(false);
	const [isVoiceDetected, setIsVoiceDetected] = useState(false);

	const sections = useSelector((state) => selectors.getSections(state));

	const deviceListRef = useRef([]);
	const audioBarsRef = useRef(null);
	const audioContextRef = useRef(null);
	const browser = detectBrowser();

	const allowAudioOnly = useMemo(() => {
		const videoQuestions = [];
		for (const section of sections) {
			videoQuestions.push(
				...(section?.questions?.filter(({ style }) => style === 'guided-video-question') || []),
			);
		}
		return !videoQuestions.some(
			({ settings }) => settings.find(({ label }) => label === 'allow_audio_only')?.value === 'false',
		);
	}, [sections]);

	// memos
	const SettingIcon = useMemo(() => {
		if (browser === 'Edge') {
			return EdgeSettingIcon;
		}
		if (browser === 'Firefox') {
			return FirefoxSettingIcon;
		}
		return ChromeSettingIcon;
	}, []);

	const PadLock = useMemo(() => {
		if (browser === 'Edge') {
			return EdgePadlock;
		}
		if (browser === 'Firefox') {
			return FirefoxPadlock;
		}
		return ChromeSettingIcon;
	}, []);

	const PadlockStyle = useMemo(() => {
		if (browser === 'Edge') {
			return {
				position: 'relative',
				top: 6,
				marginRight: 4,
				marginLeft: 4,
			};
		}
		if (browser === 'Firefox') {
			return {
				position: 'relative',
				top: 6,
				marginRight: 4,
				marginLeft: 4,
			};
		}
		return {
			position: 'relative',
			top: 2,
			marginRight: 6,
			marginLeft: 4,
		};
	}, []);

	const userPlatform = useMemo(() => {
		const ios = navigator.userAgent.match(/(iPhone|iPod)/i) ? 'ios' : '';
		const android = navigator.userAgent.match(/(android)/i) ? 'android' : '';
		// console.log('platform', ios || android || 'desktop', navigator.userAgent);
		if (!ios && !android) return 'desktop';
		return ios || android;
	}, []);

	// effects

	useEffect(() => {
		// Initial focus for screen readers
		initFocusRef.current?.focus();
	}, []);

	useEffect(() => {
		sessionStorage.removeItem('moderation-disqualify');
		const innerScrollContainer = document.querySelector(`.scroll-container__inner`);
		if (innerScrollContainer) {
			innerScrollContainer.classList.add('scroll-container__inner--rtl');
		}
		return () => {
			innerScrollContainer.classList.remove('scroll-container__inner--rtl');
		};
	}, [isMobile]);

	useEffect(() => {
		if (canvasWidth) {
			cameraSettings.current.canvasWidth = canvasWidth;
		}
		if (canvasHeight) {
			cameraSettings.current.canvasHeight = canvasHeight;
		}
	}, [canvasWidth, canvasHeight]);

	useEffect(
		() => () => {
			timeoutRef.current = false;
			if (navigator?.allMediaStreams?.length) {
				navigator.allMediaStreams.forEach((stream) => {
					stream.getTracks().forEach((track) => {
						track.stop();
					});
				});
			}
		},
		[],
	);

	useEffect(() => {
		if (audioPermission === 'denied' || cameraPermission === 'denied') {
			window.removeEventListener('beforeunload', qualityTerminate);
		}
	}, [audioPermission, cameraPermission, qualityTerminate]);

	// functions

	const checkAllowPermission = async ({ isAudio = false, isVideo = false }) => {
		if (isAudio && isVideo) return getPermission({ audio: true, video: true });
		if (isAudio) return getPermission({ audio: true });
		if (isVideo) return getPermission({ video: true });
	};

	const saveMediaStream = (mediaStream) => {
		if (!navigator?.allMediaStreams) {
			navigator.allMediaStreams = [];
		}
		navigator.allMediaStreams.push(mediaStream);
	};

	const getPermission = async ({ audio = false, video = false }) => {
		try {
			setHasClickedOnEnabled(true);

			const devices = await navigator.mediaDevices.enumerateDevices();
			const cameras = devices.filter((device) => device.kind === 'videoinput');
			deviceListRef.current = cameras;
			const media = await navigator.mediaDevices.getUserMedia({ audio, video });
			saveMediaStream(media);
			if (!audio || !video) {
				if (audio && media) {
					setAudioPermission('granted');
					setCanGoNext(true);
					permissionSettingsRef.current.microphone = true;
				}
				if (video && media) {
					setCameraPermission('granted');
					permissionSettingsRef.current.camera = true;
				}
			} else if (media) {
				setAudioPermission('granted');
				setCameraPermission('granted');
				setCanGoNext(true);
				permissionSettingsRef.current.microphone = true;
				permissionSettingsRef.current.camera = true;
			} else {
				setAudioPermission('denied');
				setCameraPermission('denied');
			}
		} catch (error) {
			if (userPlatform !== 'ios') {
				if (audio) {
					setAudioPermission('denied');
				}
				if (video) {
					setCameraPermission('denied');
				}
				if (audio && video) {
					setAudioPermission('denied');
					setCameraPermission('denied');
				}
				setShowPreview('denied');

				if (error?.toString()?.includes('dismissed')) {
					if (audio && video) {
						checkPermissionsPoll('microphone and camera');
					} else if (audio) {
						checkPermissionsPoll('microphone');
					} else if (video) {
						checkPermissionsPoll('camera');
					}
				} else if (error?.toString()?.includes('system')) {
					setIsSystemDenied(true);
					if (audio && video) {
						checkPermissionsPoll('microphone and camera');
					} else if (audio) {
						checkPermissionsPoll('microphone');
					} else if (video) {
						checkPermissionsPoll('camera');
					}
				} else if (error?.toString()?.includes('denied')) {
					if (audio && video) {
						checkPermissionsPoll('microphone and camera');
					} else if (audio) {
						checkPermissionsPoll('microphone');
					} else if (video) {
						checkPermissionsPoll('camera');
					}
				}
			} else {
				// it's ios and has being blocked by system
				if (audio) {
					setAudioPermission('denied');
				}
				if (video) {
					setCameraPermission('denied');
				}
				if (audio && video) {
					setAudioPermission('denied');
					setCameraPermission('denied');
				}
				setShowPreview('denied');
				if (error?.toString()?.includes('system')) {
					setIsSystemDenied(true);
				}
			}
			console.log(`No permission was given? => `, error);
		}
	};

	const renderMicrophoneState = () => {
		if (audioPermission === false)
			return (
				<div className={el('ai-content')} onClick={() => getPermission({ audio: true })}>
					<img src={RoundedMic} alt="RoundedMic" />
					<div className={el('permission-row')}>
						<span className={el('permission-text')}>Microphone</span>
						<span className={el('permission-subtext')}>Required</span>
					</div>
					<Button
						isBold
						fullWidth={false}
						label="Enable Access"
						onClick={() => getPermission({ audio: true })}
					/>
				</div>
			);
		if (isMobile && audioPermission === 'denied') {
			return (
				<div
					className={el('ai-content-blocked')}
					aria-hidden
					onClick={() => {
						setPermissionSettings(true);
					}}
				>
					<div className={el('ai-blocked')}>
						<img src={RoundedMic} alt="RoundedMic" />
						<div className={el('permission-row')}>
							<span className={el('permission-text')}>Microphone</span>
							<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
								<span className={el('permission-subtext')}>Required</span>
							</div>
						</div>
						<div className={el('button-blocked-mobile')} style={{ width: 40, marginLeft: 'auto' }}>
							<span className={el('text-blocked')}>Blocked </span>
						</div>
					</div>

					{renderAiMobileInstructions()}
				</div>
			);
		}
		if (!isMobile && audioPermission === 'denied')
			return (
				<div
					className={el('ai-content-blocked')}
					onClick={() => {
						setPermissionSettings(true);
					}}
				>
					<div className={el('ai-blocked')}>
						<img src={RoundedMic} alt="RoundedMic" />
						<div className={el('permission-row')}>
							<span className={el('permission-text')}>Microphone</span>
							<span className={el('permission-subtext')}>Required</span>
						</div>
						<div className={el('button-blocked')}>
							<span className={el('text-blocked')}>
								{isSystemDenied ? 'Blocked in settings' : 'Blocked'}
							</span>
						</div>
					</div>

					<div className={el('ai-instructions')}>
						<span>Upsiide does not have permission to use your microphone.</span>{' '}
						{SettingIcon ? (
							<>
								<span>Click the icon </span>{' '}
								<img className={el('img-setting')} alt="setting icon" src={SettingIcon} />
								<span>on the left side of the URL bar to open site permission settings.</span>{' '}
							</>
						) : (
							<span>Click the "Safari" menu on the top left side to open site </span>
						)}
						<span>
							<button type="button" tabIndex={0} onClick={() => setPermissionSettings(true)}>
								Detailed instructions <img src={ArrowOutward} alt="Arrow" />
							</button>
						</span>
					</div>
				</div>
			);
		if (audioPermission === 'granted')
			return (
				<div className={el('ai-content')}>
					<img src={RoundedMic} alt="RoundedMic" />
					<div className={el('permission-row')}>
						<span className={el('permission-text')}>Microphone</span>
						<span className={el('permission-subtext')}>Required</span>
					</div>
					<div className={el('button-allowed')}>
						<span className={el('text-allowed')}>Enabled</span>
					</div>
				</div>
			);
	};
	const renderCameraState = () => {
		if (cameraPermission === false)
			return (
				<div className={el('ai-content')} onClick={() => getPermission({ video: true })}>
					<img src={RoundedCamera} alt="RoundedCamera" />
					<div className={el('permission-row')}>
						<span className={el('permission-text')}>Camera</span>
						<span className={el('permission-subtext')}>Optional</span>
					</div>
					<Button
						isBold
						fullWidth={false}
						label="Enable Access"
						onClick={() => getPermission({ video: true })}
					/>
				</div>
			);
		if (isMobile && cameraPermission === 'denied')
			return (
				<div
					className={el('ai-content-blocked')}
					aria-hidden
					onClick={() => {
						setPermissionSettings(true);
					}}
				>
					<div className={el('ai-blocked')}>
						<img src={RoundedCamera} alt="RoundedCamera" />
						<div className={el('permission-row')}>
							<span className={el('permission-text')}>Camera</span>
							<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
								<span className={el('permission-subtext')}>Optional</span>
							</div>
						</div>
						<div className={el('button-blocked-mobile')} style={{ width: 40, marginLeft: 'auto' }}>
							<span className={el('text-blocked')}>Blocked</span>
						</div>
					</div>

					{renderAiMobileInstructions()}
				</div>
			);
		if (!isMobile && cameraPermission === 'denied')
			return (
				<div
					className={el('ai-content-blocked')}
					onClick={() => {
						setPermissionSettings(true);
					}}
				>
					<div className={el('ai-blocked')}>
						<img src={RoundedCamera} alt="RoundedCamera" />
						<div className={el('permission-row')}>
							<span className={el('permission-text')}>Camera</span>
							<span className={el('permission-subtext')}>Optional</span>
						</div>
						<div className={el('button-blocked')}>
							<span className={el('text-blocked')}>
								{isSystemDenied ? 'Blocked in settings' : 'Blocked'}
							</span>
						</div>
					</div>

					<div className={el('ai-instructions')}>
						<span>Upsiide does not have permission to use your camera.</span>{' '}
						{SettingIcon ? (
							<>
								<span>Click the icon </span>{' '}
								<img className={el('img-setting')} alt="setting icon" src={SettingIcon} />
								<span>on the left side of the URL bar to open site permission settings.</span>{' '}
							</>
						) : (
							<span>Click the "Safari" menu on the top left side to open site </span>
						)}
						<span>
							<button type="button" tabIndex={0} onClick={() => setPermissionSettings(true)}>
								Detailed instructions <img src={ArrowOutward} alt="Arrow" />
							</button>
						</span>
					</div>
				</div>
			);
		if (cameraPermission === 'granted')
			return (
				<div className={el('ai-content')}>
					<img src={RoundedCamera} alt="RoundedCamera" />
					<div className={el('permission-row')}>
						<span className={el('permission-text')}>Camera</span>
						<span className={el('permission-subtext')}>Optional</span>
					</div>
					<div className={el('button-allowed')}>
						<span className={el('text-allowed')}>Enabled</span>
					</div>
				</div>
			);
	};

	const renderAllowAudio = () => (
		<div className={el('ai-container')}>
			{renderMicrophoneState()}
			{renderCameraState()}
		</div>
	);

	const renderAiMobileInstructions = () => {
		const isIos = userPlatform === 'ios';
		if (isIos) {
			return (
				<div className={el('ai-instructions-mobile')}>
					<span>Allow Upsiide to access your microphone and camera.</span>{' '}
					<span>
						<span>
							Detailed instructions <img src={ArrowOutward} alt="Arrow" />
						</span>
					</span>
				</div>
			);
		}
		return (
			<div className={el('ai-instructions-mobile')}>
				<span>Upsiide does not have permission to use your camera and microphone.</span>{' '}
				<span>Tap the icon </span>{' '}
				<img className={el('img-setting')} alt="setting icon" src={PadLock} style={PadlockStyle} />
				<span>on the left side of the URL bar to enable permissions. </span>
				{/* <img className={el('upsiide-url-bar')} alt="setting icon" src={UpsiideBar} /> */}
				<span>
					<span>
						Detailed instructions <img src={ArrowOutward} alt="Arrow" />
					</span>
				</span>
			</div>
		);
	};

	const renderAllowPermission = () => {
		if (audioPermission === 'granted' && cameraPermission === 'granted') {
			return (
				<div className={el('ai-container')}>
					<div className={el('ai-content-full')}>
						<div className={el('ai-permission')}>
							<img src={CameraAndMicIcon} alt="RoundedCamera" />
						</div>
						<div className={el('permission-row')}>
							<span className={el('permission-text')}>Microphone and camera</span>
						</div>
						<div className={el('button-allowed')}>
							<span className={el('text-allowed')}>Enabled</span>
						</div>
					</div>
				</div>
			);
		}
		if (isMobile && audioPermission === 'denied' && cameraPermission === 'denied') {
			return (
				<div
					className={el('ai-content-blocked')}
					style={{ paddingBottom: 12 }}
					onClick={() => {
						setPermissionSettings(true);
					}}
				>
					<div className={el('ai-blocked')} style={{ marginBottom: 0 }}>
						<div className={el('ai-permission')}>
							<img src={CameraAndMicIcon} alt="RoundedCamera" />
						</div>
						<div className={el('permission-row')}>
							<span className={el('permission-text')}>Microphone and camera</span>
							<div className={el('button-blocked-mobile')} style={{ width: isSystemDenied ? 94 : 40 }}>
								<span className={el('text-blocked')}>Blocked</span>
							</div>
						</div>
					</div>

					{renderAiMobileInstructions()}
				</div>
			);
		}
		if (!isMobile && audioPermission === 'denied' && cameraPermission === 'denied') {
			return (
				<div
					className={el('ai-content-blocked')}
					onClick={() => {
						setPermissionSettings(true);
					}}
				>
					<div className={el('ai-blocked')}>
						<div className={el('ai-permission')}>
							<img src={CameraAndMicIcon} alt="RoundedCamera" />
						</div>
						<div className={el('permission-row')}>
							<span className={el('permission-text')}>Microphone and camera</span>
						</div>
						<div className={el('button-blocked')}>
							<span className={el('text-blocked')}>Blocked</span>
						</div>
					</div>

					<div className={el('ai-instructions')}>
						<span>Upsiide does not have permission to use your camera and microphone.</span>{' '}
						{SettingIcon ? (
							<>
								<span>Click the icon </span>{' '}
								<img className={el('img-setting')} alt="setting icon" src={SettingIcon} />
								<span>on the left side of the URL bar to open site permission settings.</span>{' '}
							</>
						) : (
							<span>Click the "Safari" menu on the top left side to open site </span>
						)}
						<span>
							{' '}
							<button type="button" tabIndex={0} onClick={() => setPermissionSettings(true)}>
								Detailed instructions <img src={ArrowOutward} alt="Arrow" />
							</button>
						</span>
					</div>
				</div>
			);
		}
		return (
			<div className={el('ai-container')} onClick={() => getPermission({ video: true, audio: true })}>
				<div className={el('ai-content-full')}>
					<div className={el('ai-permission')}>
						<img src={CameraAndMicIcon} alt="RoundedCamera" />
					</div>
					<div className={el('permission-row')}>
						<span className={el('permission-text')}>Microphone and camera</span>
					</div>
					<Button
						isBold
						fullWidth={false}
						label="Enable Access"
						onClick={() => getPermission({ video: true, audio: true })}
					/>
				</div>
			</div>
		);
	};

	useEffect(() => {
		if (audioBarsRef.current && audioPermission === 'granted') {
			startAudioPreview();
		}
	}, [audioBarsRef, audioPermission]);

	const drawBars = (analyser) => {
		try {
			let binI = document.getElementById(`bin-${0}`);
			if (!binI) {
				requestAnimationFrame(() => drawBars(analyser));
			} else {
				analyser.getByteFrequencyData(dataArray);
				const drawThreshold = 5;
				const barHeight = 150;
				const minBarHeight = 1;
				for (let i = 0; i < FREQUENCY_BIN_COUNT; i++) {
					binI = document.getElementById(`bin-${i}`);
					const freqPctg =
						(dataArray[i] / 255) * ((1 - minBarHeight / barHeight) * 100) +
						(minBarHeight / barHeight) * 100 -
						20;
					binI.style.height = `${freqPctg >= drawThreshold ? freqPctg : drawThreshold}%`;
				}
				requestAnimationFrame(() => drawBars(analyser));
			}
		} catch (error) {
			console.log('error', error);
			cancelAnimationFrame(() => drawBars(analyser));
		}
	};

	const startAudioPreview = async () => {
		if (!audioContextRef.current) {
			audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
		}
		const localAudioStream = await navigator.mediaDevices.getUserMedia({
			audio: true,
		});
		saveMediaStream(localAudioStream);
		// const context = new (window.AudioContext || window.webkitAudioContext)();
		const context = audioContextRef.current;
		const source = context.createMediaStreamSource(localAudioStream);
		const analyser = context.createAnalyser();
		source.connect(analyser);

		analyser.fftSize = 8 * FREQUENCY_BIN_COUNT;
		drawBars(analyser);

		let voiceDetectedAt = performance.now();
		let hasEverTalked = false;
		const detectTime = 2000;
		const statusHoldTime = 500;
		const voiceRms = 32;

		const processAudio = () => {
			const data = new Uint8Array(analyser.frequencyBinCount);
			analyser.getByteFrequencyData(data);
			let rms = 0;

			for (let byteFrequency of data) {
				if (byteFrequency > 120) byteFrequency = 120;
				rms += byteFrequency * byteFrequency;
			}

			rms = Math.sqrt(rms / data.length);
			const silenceTime = performance.now() - voiceDetectedAt;
			const shouldStop = silenceTime >= detectTime && hasEverTalked;
			if (rms > voiceRms) {
				hasEverTalked = true;
				voiceDetectedAt = performance.now();
				setIsVoiceDetected(true);
			} else if (shouldStop) {
				setIsVoiceDetected(false);
				// cancelAnimationFrame(processAudio);
				// return;
			} else if (silenceTime >= statusHoldTime) {
				setIsVoiceDetected(false);
			}
			window.requestAnimationFrame(processAudio);
		};

		processAudio();
	};

	const checkPermissionsPoll = (type) => {
		// console.log('polling permissions for ', type);
		setTimeout(() => {
			if (!timeoutRef.current) return;
			if (type === 'camera' && !permissionSettingsRef?.current?.camera) {
				getPermission({ video: true });
			} else if (type === 'microphone' && !permissionSettingsRef?.current?.microphone) {
				getPermission({ audio: true });
			} else if (
				type === 'microphone and camera' &&
				!permissionSettingsRef?.current?.microphone &&
				!permissionSettingsRef?.current?.camera
			) {
				getPermission({ audio: true, video: true });
			}
		}, 2000);
	};

	const renderHasSelectedAudioOnly = () => null;
	const renderNoPermission = () => null;

	const renderBarBins = () => {
		const indexes = Array(FREQUENCY_BIN_COUNT)
			.fill(0)
			.map((_, i) => i)
			.sort((a, b) => {
				const aOdd = a % 2 === 0;
				const bOdd = b % 2 === 0;
				if (aOdd === bOdd) return aOdd ? a - b : b - a;
				return aOdd ? 1 : -1;
			});
		return indexes.map((index) => <div className={el('frequency-bar')} id={`bin-${index}`} key={`bin-${index}`} />);
	};

	const renderPreview = () => {
		if (cameraPermission !== 'granted' && !hasClickedEnabled) return <AiBanner />;
		if (cameraPermission === 'granted')
			return (
				<Preview
					renderHasSelectedAudioOnly={renderHasSelectedAudioOnly}
					renderNoPermission={renderNoPermission}
					renderAudioBars={audioPermission === 'granted'}
				/>
			);
		return (
			<div className={el('square-wrapper')} ref={squareWrapperRef}>
				<div className={el('placeholder')}>
					<img
						src={noCameraCanvasPlaceholder}
						alt="noCameraCanvasPlaceholder"
						style={{
							width: squareWrapperSideSize ?? '100%',
							height: squareWrapperSideSize ?? '100%',
						}}
					/>
					{isVoiceDetected && (
						<div
							className={el('active-border')}
							style={{
								width: `calc(${squareWrapperSideSize || 600} - 5px)` ?? '100%',
								height: `calc(${squareWrapperSideSize || 600} - 5px)` ?? '100%',
							}}
						/>
					)}
				</div>

				{audioPermission === 'granted' && (
					<div ref={audioBarsRef} className={el('audio-bars')}>
						{renderBarBins()}
					</div>
				)}
			</div>
		);
	};

	return (
		<div className={className}>
			<div className={el('ai-question-container')}>
				<div className={el('ai-header')}>
					<span className={el('ai-title')} ref={initFocusRef} tabIndex={-1}>
						Permissions Checklist
					</span>
					<span className={el('sub-title')}>Please enable access to participate in the video questions.</span>
				</div>

				{allowAudioOnly ? renderAllowAudio() : renderAllowPermission()}
				<div className={el('ai-footer')}>
					<Button
						isBold
						label="Continue"
						disabled={!canGoNext}
						onClick={() => {
							if (canGoNext) setShowPreview('preview');
						}}
					/>

					<Button isBold color="secondary" label="Skip video questions" onClick={() => setAreYouSure(true)} />
				</div>
			</div>
			<div
				className={el('left-side-container')}
				style={hasClickedEnabled ? { background: '#fff' } : { background: '#fafafa' }}
			>
				{renderPreview()}
			</div>
			{permissionSettings && (audioPermission === 'denied' || cameraPermission === 'denied') && (
				<ModalPermissionSettings
					retry={() => {
						if (isMobile) {
							window.location.reload();
						}
						setPermissionSettings(false);
						const isAudio = audioPermission === 'denied';
						const isVideo = cameraPermission === 'denied';
						return checkAllowPermission({ isAudio, isVideo });
					}}
					isSystem={isSystemDenied}
					onClose={() => {
						setPermissionSettings(false);
					}}
					userPlatform={userPlatform}
					micPermission={audioPermission === 'denied'}
					cameraPermission={cameraPermission === 'denied'}
				/>
			)}
		</div>
	);
};

PermissionCheck.propTypes = {
	setShowPreview: PropTypes.func.isRequired,
	setAreYouSure: PropTypes.func.isRequired,
	qualityTerminate: PropTypes.func.isRequired,
};

export default PermissionCheck;
