// packages
import React from 'react';

// styles
import './styles.scss';

const className = 'heatmap-cursor-pointer-icon';

const PointerIcon = () => (
	<svg width="16px" height="17px" viewBox="0 0 16 17" {...{ className }}>
		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g transform="translate(-302.000000, -93.000000)">
				<g transform="translate(302.000000, 93.000000)">
					<path
						d="M3.3315,12.3799 C3.0475,12.0209 2.7025,11.2869 2.0885,10.3959 C1.7405,9.8919 0.8775,8.9429 0.6205,8.4609 C0.3975,8.0349 0.4215,7.8439 0.4745,7.4909 C0.5685,6.8629 1.2125,6.3739 1.8995,6.4399 C2.4185,6.4889 2.8585,6.8319 3.2545,7.1559 C3.4935,7.3509 3.7875,7.7299 3.9645,7.9439 C4.1275,8.1399 4.1675,8.2209 4.3415,8.4529 C4.5715,8.7599 4.6435,8.9119 4.5555,8.5739 C4.4845,8.0779 4.3685,7.2309 4.2005,6.4819 C4.0725,5.9139 4.0415,5.8249 3.9195,5.3889 C3.7905,4.9249 3.7245,4.5999 3.6035,4.1079 C3.5195,3.7599 3.3685,3.0489 3.3275,2.6489 C3.2705,2.1019 3.2405,1.2099 3.5915,0.7999 C3.8665,0.4789 4.4975,0.3819 4.8885,0.5799 C5.4005,0.8389 5.6915,1.5829 5.8245,1.8799 C6.0635,2.4139 6.2115,3.0309 6.3405,3.8409 C6.5045,4.8719 6.8065,6.3029 6.8165,6.6039 C6.8405,6.2349 6.7485,5.4579 6.8125,5.1039 C6.8705,4.7829 7.1405,4.4099 7.4785,4.3089 C7.7645,4.2239 8.0995,4.1929 8.3945,4.2539 C8.7075,4.3179 9.0375,4.5419 9.1605,4.7529 C9.5225,5.3769 9.5295,6.6519 9.5445,6.5839 C9.6305,6.2079 9.6155,5.3549 9.8285,4.9999 C9.9685,4.7659 10.3255,4.5549 10.5155,4.5209 C10.8095,4.4689 11.1705,4.4529 11.4795,4.5129 C11.7285,4.5619 12.0655,4.8579 12.1565,4.9999 C12.3745,5.3439 12.4985,6.3169 12.5355,6.6579 C12.5505,6.7989 12.6095,6.2659 12.8285,5.9219 C13.2345,5.2829 14.6715,5.1589 14.7265,6.5609 C14.7515,7.2149 14.7465,7.1849 14.7465,7.6249 C14.7465,8.1419 14.7345,8.4529 14.7065,8.8269 C14.6755,9.2269 14.5895,10.1309 14.4645,10.5689 C14.3785,10.8699 14.0935,11.5469 13.8125,11.9529 C13.8125,11.9529 12.7385,13.2029 12.6215,13.7659 C12.5035,14.3279 12.5425,14.3319 12.5195,14.7309 C12.4965,15.1289 12.6405,15.6529 12.6405,15.6529 C12.6405,15.6529 11.8385,15.7569 11.4065,15.6879 C11.0155,15.6249 10.5315,14.8469 10.4065,14.6089 C10.2345,14.2809 9.8675,14.3439 9.7245,14.5859 C9.4995,14.9689 9.0155,15.6559 8.6735,15.6989 C8.0055,15.7829 6.6195,15.7299 5.5345,15.7189 C5.5345,15.7189 5.7195,14.7079 5.3075,14.3609 C5.0025,14.1019 4.4775,13.5769 4.1635,13.3009 L3.3315,12.3799 Z"
						id="cursor"
						fill="#FFFFFF"
					/>
					<path
						d="M3.3315,12.3799 C3.0475,12.0209 2.7025,11.2869 2.0885,10.3959 C1.7405,9.8919 0.8775,8.9429 0.6205,8.4609 C0.3975,8.0349 0.4215,7.8439 0.4745,7.4909 C0.5685,6.8629 1.2125,6.3739 1.8995,6.4399 C2.4185,6.4889 2.8585,6.8319 3.2545,7.1559 C3.4935,7.3509 3.7875,7.7299 3.9645,7.9439 C4.1275,8.1399 4.1675,8.2209 4.3415,8.4529 C4.5715,8.7599 4.6435,8.9119 4.5555,8.5739 C4.4845,8.0779 4.3685,7.2309 4.2005,6.4819 C4.0725,5.9139 4.0415,5.8249 3.9195,5.3889 C3.7905,4.9249 3.7245,4.5999 3.6035,4.1079 C3.5195,3.7599 3.3685,3.0489 3.3275,2.6489 C3.2705,2.1019 3.2405,1.2099 3.5915,0.7999 C3.8665,0.4789 4.4975,0.3819 4.8885,0.5799 C5.4005,0.8389 5.6915,1.5829 5.8245,1.8799 C6.0635,2.4139 6.2115,3.0309 6.3405,3.8409 C6.5045,4.8719 6.8065,6.3029 6.8165,6.6039 C6.8405,6.2349 6.7485,5.4579 6.8125,5.1039 C6.8705,4.7829 7.1405,4.4099 7.4785,4.3089 C7.7645,4.2239 8.0995,4.1929 8.3945,4.2539 C8.7075,4.3179 9.0375,4.5419 9.1605,4.7529 C9.5225,5.3769 9.5295,6.6519 9.5445,6.5839 C9.6305,6.2079 9.6155,5.3549 9.8285,4.9999 C9.9685,4.7659 10.3255,4.5549 10.5155,4.5209 C10.8095,4.4689 11.1705,4.4529 11.4795,4.5129 C11.7285,4.5619 12.0655,4.8579 12.1565,4.9999 C12.3745,5.3439 12.4985,6.3169 12.5355,6.6579 C12.5505,6.7989 12.6095,6.2659 12.8285,5.9219 C13.2345,5.2829 14.6715,5.1589 14.7265,6.5609 C14.7515,7.2149 14.7465,7.1849 14.7465,7.6249 C14.7465,8.1419 14.7345,8.4529 14.7065,8.8269 C14.6755,9.2269 14.5895,10.1309 14.4645,10.5689 C14.3785,10.8699 14.0935,11.5469 13.8125,11.9529 C13.8125,11.9529 12.7385,13.2029 12.6215,13.7659 C12.5035,14.3279 12.5425,14.3319 12.5195,14.7309 C12.4965,15.1289 12.6405,15.6529 12.6405,15.6529 C12.6405,15.6529 11.8385,15.7569 11.4065,15.6879 C11.0155,15.6249 10.5315,14.8469 10.4065,14.6089 C10.2345,14.2809 9.8675,14.3439 9.7245,14.5859 C9.4995,14.9689 9.0155,15.6559 8.6735,15.6989 C8.0055,15.7829 6.6195,15.7299 5.5345,15.7189 C5.5345,15.7189 5.7195,14.7079 5.3075,14.3609 C5.0025,14.1019 4.4775,13.5769 4.1635,13.3009 L3.3315,12.3799 L3.3315,12.3799 Z"
						id="cursor"
						stroke="#000000"
						strokeWidth="0.75"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M11.5664,12.7344 L11.5664,9.2754"
						id="cursor"
						stroke="#000000"
						strokeWidth="0.75"
						strokeLinecap="round"
					/>
					<path
						d="M9.5508,12.7461 L9.5348,9.2731"
						id="cursor"
						stroke="#000000"
						strokeWidth="0.75"
						strokeLinecap="round"
					/>
					<path
						d="M7.5547,9.3047 L7.5757,12.7307"
						id="cursor"
						stroke="#000000"
						strokeWidth="0.75"
						strokeLinecap="round"
					/>
				</g>
			</g>
		</g>
	</svg>
);

export default PointerIcon;
