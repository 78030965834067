import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'src/utilities/bem-cn';
import useIsMobile from 'src/utilities/hooks/useIsMobile';
import useFocusLock from 'src/utilities/hooks/useFocusLock';
import xIcon from 'public/images/x_icon.svg';
import privacyIcon from 'public/images/privacy.svg';
import poweredAiIcon from 'public/images/powered-by-ai.svg';
import lockIcon from 'public/images/lock-responses.svg';
import Button from 'src/components/new/Button';
import './styles.scss';

const className = 'modal-are-you-sure';

const el = (name) => cn(className, name);

const ModalAreYouSure = ({ onClose, onSuccess, onDeny }) => {
	const modalRef = useRef(null);
	const initFocusRef = useRef(null);

	useFocusLock(modalRef.current, { skipInitFocus: true });

	const isMobile = useIsMobile();

	const renderMessage = () => (
		<div className={el('modal-text-container')}>
			<span className={el('modal-text')}>We’d love for you to participate:</span>
			<div className={el('list-rules')}>
				<span className={el('rule-item')}>
					<img className={el('rule-image')} src={privacyIcon} alt="rule" />

					<span className={el('rule-text')}>
						Your video will only be used for internal research and will not be shared with any third
						parties.
					</span>
				</span>

				<span className={el('rule-item')}>
					<img className={el('rule-image')} src={poweredAiIcon} alt="rule" />

					<span className={el('rule-text')}>
						The video question is powered by AI, requiring no human interaction.
					</span>
				</span>
				<span className={el('rule-item')}>
					<img className={el('rule-image')} src={lockIcon} alt="rule" />

					<span className={el('rule-text')}>
						Your responses are anonymous and not linked to any personal data.
					</span>
				</span>
			</div>
		</div>
	);

	const renderFooter = () => {
		if (isMobile) {
			return (
				<div className={el('modal-footer')}>
					<Button isBold label="Skip video questions" color="danger" onClick={onSuccess} />

					<Button isBold label="I changed my mind" color="secondary" onClick={onDeny} />
				</div>
			);
		}

		return (
			<div className={el('modal-footer')}>
				<Button fullWidth={false} isBold label="I changed my mind" color="secondary" onClick={onDeny} />

				<Button fullWidth={false} isBold label="Skip video questions" color="danger" onClick={onSuccess} />
			</div>
		);
	};

	useEffect(() => {
		// Initial focus for screen readers
		initFocusRef.current?.focus();
	}, []);

	return (
		<>
			<div className={el('modal-overlay')} />

			<div ref={modalRef} className={el('modal-container')}>
				<div className={el('modal-header')}>
					{/* <div className={el('modal-header-icon')}></div> */}
					<button
						className={el('icon-button')}
						type="button"
						tabIndex={0}
						onClick={onClose}
						aria-label="Close"
					>
						<img src={xIcon} alt="close" aria-hidden />
					</button>
				</div>

				<div className={el('modal-content')}>
					<span className={el('modal-title')} ref={initFocusRef} tabIndex={-1}>
						Are you sure you want to skip?
					</span>
					{renderMessage()}
				</div>

				{renderFooter()}
			</div>
		</>
	);
};
ModalAreYouSure.propTypes = {
	onSuccess: PropTypes.func,
	onDeny: PropTypes.func,
	onClose: PropTypes.func,
};

export default ModalAreYouSure;
