// packages
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { RadioGroup, RadioButton } from '@upsiide/ui-components';

// hooks
import useIsKeyboardNavigation from 'src/utilities/hooks/useIsKeyboardNavigation';
import useElementRect from 'src/utilities/hooks/useElementRect';

// components
import EmojiButton from './EmojiButton';

// styles
import './styles.scss';

const toggle = (c, v) => (c.indexOf(v) > -1 ? c.filter(($v) => $v !== v) : c.concat([v]));

// constants
const GAP = 4;
const EMOJI_OPTION_SIZE = 56;

const EmojiSelect = ({ t, disabled, value, options, onChange, multipleSelect }) => {
	// state
	const [focusedOptionId, setFocusedOptionId] = useState(null);
	const [showOptionTooltips, setShowOptionTooltips] = useState([]);

	const optionsRef = useRef([]);

	const [groupRect, groupRef] = useElementRect();

	const isKeyboardNavigation = useIsKeyboardNavigation();

	const nColumns = useMemo(() => Math.floor((groupRect.width + GAP) / (EMOJI_OPTION_SIZE + GAP)), [groupRect.width]);

	// functions
	const handleChange = (v) => onChange(toggle(value || [], v));

	const checked = (option) => (value || []).indexOf(option.id.toString()) > -1;

	const hideTooltip = (optionId) => setShowOptionTooltips((prev) => [...prev.filter((id) => id !== optionId)]);

	const activateTooltip = useCallback((optionId, optionLabel) => {
		document?.querySelectorAll('.tooltiptext')?.forEach((tooltip) => (tooltip.style.display = 'none'));
		document.querySelector(`.${optionLabel}`).style.display = 'flex';

		setShowOptionTooltips((prev) => [...prev, optionId]);
		setTimeout(() => {
			hideTooltip(optionId);
		}, 2000);
	}, []);

	const handleRadioButtonFocus = useCallback(
		(e, optionId, optionLabel) => {
			optionLabel && activateTooltip(optionId, optionLabel);
			const inputType = e.target.type;
			const targetInputType = multipleSelect ? 'checkbox' : 'radio';
			const isCheckbox = inputType === targetInputType;
			if (!isKeyboardNavigation || !isCheckbox) return;
			setFocusedOptionId(optionId);
		},
		[activateTooltip, isKeyboardNavigation, multipleSelect],
	);

	const handleRadioButtonBlur = useCallback((optionId) => {
		hideTooltip(optionId);
		setFocusedOptionId(null);
	}, []);

	const handleKeyDown = useCallback(
		(e, index) => {
			// * Arrow keys navigation
			const directions = {
				ArrowLeft: -1,
				ArrowRight: 1,
				ArrowUp: -nColumns,
				ArrowDown: nColumns,
			};

			const move = directions[e.key];

			if (move !== undefined) {
				e.preventDefault();

				const nextIndex = (index + move + options.length) % options.length;

				const nextOptionElement = optionsRef.current[nextIndex];

				nextOptionElement.focus();

				if (!multipleSelect) {
					nextOptionElement.click();
				}
			}
		},
		[multipleSelect, nColumns, options, optionsRef],
	);

	// life cycle
	useEffect(() => {
		const pageContainer = document.querySelector('.scroll-container');
		if (pageContainer) {
			pageContainer.style.scrollBehavior = 'auto';
			pageContainer.scrollTo(0, 0);
		}
		// Reset scroll container
		const scrollContainer = document.querySelector(`[class*='scroll-container']`);
		if (scrollContainer) {
			scrollContainer.classList.remove('scrolled-to-bottom');
			scrollContainer.classList.remove('remove-scroll-indicator');
		}
	}, []);

	return (
		<RadioGroup
			className="question-emoji-select custom-radio-group"
			multiSelect={multipleSelect}
			role={multipleSelect ? 'group' : 'radiogroup'}
			style={{ gap: GAP }}
			ref={groupRef}
		>
			{options.map((option, index) => {
				const isChecked = checked(option);
				const label =
					option.label ||
					option.value ||
					option?.translations?.find(({ languageCode }) => languageCode === option?.languageCode)?.label ||
					'';
				const optionId = option.id;

				return (
					<RadioButton
						id={optionId.toString()}
						onClick={multipleSelect ? handleChange : onChange}
						disabled={disabled}
						checked={isChecked}
						key={optionId}
						value={optionId.toString()}
						onMouseEnter={() => activateTooltip(optionId, label)}
						onMouseLeave={hideTooltip}
						onTouchStart={() => activateTooltip(optionId, label)}
						style={{
							outline: focusedOptionId === optionId ? '2px solid #28B681' : 'none',
							outlineOffset: '2px',
							width: EMOJI_OPTION_SIZE,
							height: EMOJI_OPTION_SIZE,
						}}
						ref={(ref) => (optionsRef.current[index] = ref)}
						inputProps={{
							'aria-label': label,
							onFocus: (e) => handleRadioButtonFocus(e, optionId, label),
							onBlur: () => handleRadioButtonBlur(optionId),
							onKeyDown: (e) => handleKeyDown(e, index),
						}}
					>
						<EmojiButton showTooltip={showOptionTooltips.includes(optionId)} {...{ label, isChecked }} />
					</RadioButton>
				);
			})}
		</RadioGroup>
	);
};

EmojiSelect.propTypes = {
	t: PropTypes.func,
	disabled: PropTypes.bool,
	value: PropTypes.array,
	options: PropTypes.array,
	onChange: PropTypes.func,
	multipleSelect: PropTypes.bool,
};

export default EmojiSelect;
