// packages
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

// utilities
import cn from 'src/utilities/bem-cn';
import * as misc from 'src/utilities/misc';

// components
import X from 'src/components/icons/X';

// styles
import './styles.scss';

const className = 'device-preview-header';
const el = (name) => cn(className, name);

// constants
const AUTO_DISMISS_TIMEOUT = 2500;
const TRANSITION_DURATION = 1000;

const DevicePreviewHeader = () => {
	// state
	const urlParams = misc.getAllUrlParams();

	const { study } = useSelector((state) => state.main);

	const previewVisibility =
		(!!Object.prototype.hasOwnProperty.call(urlParams, 'previewUuid') &&
			!Object.prototype.hasOwnProperty.call(urlParams, 'disablePreviewPrompt')) ||
		(!!study?.previewUuid && !study?.disablePreviewPrompt);

	const [isAnimating, setIsAnimating] = useState(previewVisibility);
	const [isPreview, setIsPreview] = useState(previewVisibility);
	const [showDevicePreviewHeader, setShowDevicePreviewHeader] = useState(previewVisibility);

	const classes = useMemo(
		() => (showDevicePreviewHeader ? ` ${className}__hidden` : ` ${className}__hidden disabled`),
		[showDevicePreviewHeader],
	);

	// life cycle
	useEffect(() => {
		if (showDevicePreviewHeader) {
			setTimeout(() => {
				setIsAnimating(true); // * Prevent the header from hiding before the animation is complete
				setShowDevicePreviewHeader(false);
				setTimeout(() => {
					setIsAnimating(false);
					setIsPreview(false);
				}, TRANSITION_DURATION);
			}, AUTO_DISMISS_TIMEOUT);
		}
	}, [showDevicePreviewHeader]);

	useEffect(() => {
		setIsPreview(previewVisibility);
		setShowDevicePreviewHeader(previewVisibility);
	}, [previewVisibility]);

	if (!isPreview && !showDevicePreviewHeader && !isAnimating) {
		return null;
	}

	return (
		<div
			className={`${className}${classes}`}
			style={{ transition: `opacity ${TRANSITION_DURATION}ms ease-in-out 0s` }}
		>
			<p className={el('text')}>Upsiide Study Preview - Your response will not be counted.</p>

			<button
				type="button"
				tabIndex={0}
				className={el('close')}
				onClick={() => {
					setShowDevicePreviewHeader(false);
				}}
				aria-label="Close preview warning"
			>
				<X />
			</button>
		</div>
	);
};

export default DevicePreviewHeader;
