import * as Sentry from '@sentry/browser';
import api from 'src/utilities/api';
import * as researchDefenderApi from 'src/utilities/researchDefenderApi';
import { parseItemString, runLogic, surveyValidateLogic } from '@upsiide/upsiide-frontend-common';
import { datadogRum } from '@datadog/browser-rum';
import { getWpmData } from 'src/utilities/wpm';
import * as misc from 'src/utilities/misc';
import { shuffle, cloneDeep } from 'lodash';
import * as selectors from '../selectors';
import * as actions from '../actions';
import i18n from '../../../i18n';
import { getQuestionsWithLabels, sectionsWithRandomization } from '../../../utilities/misc';

/**
 * Testing
 */

const nopeFunction = (v) => v;

/**
 * Response Effects
 */

const checkResponse = (response) => {
	if (response?.complete || response?.endTime) {
		if (response?.redirectUrl) {
			// TODO - add a "overquota" reject type
			setTimeout(() => {
				window.location.href = response.redirectUrl;
			}, 500);
		}
		return Promise.reject('completed');
	}
	if (response?.redirectUrl) {
		// TODO - add a "overquota" reject type
		setTimeout(() => {
			window.location.href = response.redirectUrl;
		}, 10);
	} else {
		return response;
	}
};

/** user is disqualified, survey terminated */
const setDisqualified = (store, action) => {
	if (action.type === actions.SET_DISQUALIFIED) {
		const state = store.getState();
		const { answers, audienceUuid, reasonForDisqualification, submittedAnswersInQuotas } = action.payload;
		const responseId = selectors.getResponseId(state);
		const urlParams = misc.getAllUrlParams();
		const qualifiers = (urlParams && urlParams.q) || [];
		const testMode = selectors.getPreviewUiid(state);

		if (testMode) {
			store.dispatch(actions.setStep('terminated'));
		}

		const study = selectors.getStudy(state);

		(testMode
			? Promise.resolve()
			: api.createDisqualificationResponse(
					responseId,
					!submittedAnswersInQuotas ? answers : [],
					audienceUuid,
					!submittedAnswersInQuotas ? qualifiers : [],
					study,
					reasonForDisqualification,
			  )
		)
			.then((response) => {
				if (response?.redirectUrl) {
					const search = window.location.search.substring(1);
					const urlParams = search
						? JSON.parse(
								`{"${decodeURI(search)
									.replace(/"/g, '\\"')
									.replace(/&/g, '","')
									.replace(/=/g, '":"')}"}`,
						  )
						: {};
					urlParams.RID = selectors.getResponseRID(store.getState());
					const newPreparedUrl = Object.keys(urlParams).reduce(
						(str, key) => str.replace(`{${key}}`, urlParams[key]),
						response.redirectUrl,
					);
					window.location.replace(newPreparedUrl);
				} else {
					store.dispatch(actions.setStep('terminated'));
				}
			})
			.catch((error) => {
				store.dispatch(actions.setState(0));
				Sentry.captureException(error);
			});
	}
};

const terminateResponse = (store, action) => {
	if (action.type === actions.TERMINATE_RESPONSE) {
		const study = selectors.getStudy(store.getState());
		const responseId = selectors.getResponseId(store.getState());
		const responseRID = selectors.getResponseRID(store.getState());
		api.terminateResponse(
			responseId,
			{
				studyUuid: study.uuid,
				audienceUuid: study.audienceUuid,
				token: responseRID,
			},
			study.useServerlessSurvey,
		).then((response) => {
			if (response.data.redirectUrl) {
				const search = window.location.search.substring(1);
				const urlParams = search
					? JSON.parse(
							`{"${decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"')}"}`,
					  )
					: {};
				urlParams.RID = responseRID;
				const newPreparedUrl = Object.keys(urlParams).reduce(
					(str, key) => str.replace(`{${key}}`, urlParams[key]),
					response.data.redirectUrl,
				);
				window.location.replace(newPreparedUrl);
			} else {
				store.dispatch(actions.setStep('terminated'));
			}
		});
	}
};

/**
 * Study Effects
 */

const checkStudy = (study) => {
	if (study.status !== 'active') {
		return Promise.reject('closed');
	}

	// if (!study.products || !study.products.length) {
	//   return Promise.reject("no-products");
	// }

	return study;
};

/**
 * Question Effects
 */
const jumpToQuestion = (store, action) => {
	if (action.type === actions.JUMP_TO_QUESTION) {
		const { questionId, sectionId } = action.payload;
		const sections = selectors.getSections(store.getState());
		const currentSection = selectors.getCurrentSection(store.getState());

		const findNextEligibleQuestion = (questionIndex, allQuestions) => {
			const nextQuestion = allQuestions[questionIndex + 1];
			if (nextQuestion) {
				const questionLabel = nextQuestion.label.replace(/<\/?p[^>]*>/g, '');
				if (
					!questionLabel ||
					!questionLabel.length ||
					questionLabel === 'Type question here...' ||
					questionLabel === 'Type option here...'
				) {
					return findNextEligibleQuestion(questionIndex + 1, allQuestions);
				}
				if (['single-select', 'multi-select', 'grid', 'emoji', 'ranked'].includes(nextQuestion.style)) {
					const hasNonEmptyOptionLabels = nextQuestion.options.some((o) => {
						if (o.label) {
							return true;
						}

						if (o.maskedOptions.length) {
							return true;
						}

						return false;
					});

					if (!nextQuestion.options || nextQuestion.options.length < 1 || !hasNonEmptyOptionLabels) {
						return findNextEligibleQuestion(questionIndex + 1, allQuestions);
					}
				}
				if (nextQuestion.style === 'grid') {
					const hasAttributeLabels =
						nextQuestion.attributes.length &&
						nextQuestion.attributes.filter(
							(a) => a.translations.findIndex((translation) => translation.label.length !== 0) !== -1,
						).length;
					const hasOptionLabels =
						nextQuestion.options.length && nextQuestion.options.filter((o) => o.label.length).length;
					if (!hasAttributeLabels || !hasOptionLabels)
						return findNextEligibleQuestion(questionIndex + 1, allQuestions);
				}
			}
			return nextQuestion;
		};

		let sectionIndex = -1;
		let section = currentSection;
		if (currentSection.id !== sectionId) {
			sectionIndex = sections.findIndex((section) => sectionId === section.id);
			section = sections[sectionIndex];
			if (sectionIndex > -1) {
				store.dispatch(actions.setCurrentSectionIndex(sectionIndex));
			}
		} else {
			console.warn('SAME SECTION');
		}

		let questions = section?.questions;
		const originalQuestions = [...questions];
		questions = getQuestionsWithLabels({ questions });
		if (questions?.length) {
			let questionIndex = questions.findIndex((question) => questionId === question.id);
			let questionExistsInOriginalQuestionsArray = -1;
			if (questionIndex === -1) {
				questionExistsInOriginalQuestionsArray = originalQuestions?.findIndex((q) => questionId === q.id);
				const questionToJumpTo = findNextEligibleQuestion(
					questionExistsInOriginalQuestionsArray,
					originalQuestions,
				);
				questionIndex = questions.findIndex((question) => questionToJumpTo?.id === question.id);
				if (!questionToJumpTo) {
					store.dispatch(actions.setNextSection());
					questionIndex = 0;
				}
			}
			store.dispatch(actions.setQuestionIndex(questionIndex));
			if (sectionIndex > -1) {
				store.dispatch(actions.setStep('loading'));
				store.dispatch(actions.setCurrentSectionIndex(sectionIndex));
			} else {
				console.warn('not moving sections');
			}
		} else {
			store.dispatch(actions.setNextSection(true));
		}
	}
};

/**
 * Section Effects
 */

const jumpToSection = (store, action) => {
	if (action.type === actions.JUMP_TO_SECTION) {
		const { sectionId } = action.payload;
		const sections = selectors.getSections(store.getState());
		const sectionIndex = sections.findIndex((section) => sectionId === section.id);
		if (sectionIndex > -1) {
			store.dispatch(actions.setStep('loading'));

			// setCurrentSectionIndex includes an actions.setStep of its own
			// this timeout is to ensure React does not batch these two state updates together
			// as components are relying on the loading state between sections
			setTimeout(() => {
				store.dispatch(actions.setCurrentSectionIndex(sectionIndex));
			}, 0);
		}
	}
};

const setNextSection = (store, action) => {
	if (action.type === actions.SET_NEXT_SECTION) {
		const currentSectionIndex = selectors.getCurrentSectionIndex(store.getState());
		const sections = selectors.getSections(store.getState());
		const { skipTimeout } = action.payload;
		if (currentSectionIndex < sections.length - 1) {
			store.dispatch(actions.setStep('loading'));
			if (skipTimeout) {
				store.dispatch(actions.setCurrentSectionIndex(currentSectionIndex + 1));
			} else {
				setTimeout(() => {
					store.dispatch(actions.setCurrentSectionIndex(currentSectionIndex + 1));
				}, 0);
			}
		} else {
			store.dispatch(actions.setStep('end'));
		}
	}
};

const setCurrentSectionIndex = (store, action) => {
	if (action.type === actions.SET_CURRENT_SECTION_INDEX) {
		const sections = selectors.getSections(store.getState());
		const currentSection = sections[action.payload.currentSectionIndex];
		switch (currentSection.type) {
			case 'monadic_split':
				// Make sure we have questions, otherwise skip
				const { questions: monadicQuestions, products: monadicProducts } = currentSection;

				if (!monadicQuestions || !monadicQuestions.length || !monadicProducts || !monadicProducts.length) {
					store.dispatch(actions.setNextSection(true));
				} else {
					store.dispatch(actions.fetchDistributedSplitProducts(currentSection.id));
					store.dispatch(actions.setStep('sections'));
				}
				break;
			case 'custom':
				// Make sure we have questions, otherwise skip
				const customQuestions = currentSection.questions;
				if (!customQuestions || !customQuestions.length) {
					console.warn('EMPTY QUESTIONS, SKIPPING', currentSection);
					const study = selectors.getStudy(store.getState());
					const rid = selectors.getResponseRID(store.getState());
					store.dispatch(actions.checkQuotas({ study, rid }));
				} else {
					store.dispatch(actions.setStep('sections'));
				}
				break;
			case 'statement':
				const { statements } = currentSection;
				if (!statements || !statements.length || !statements[0].text) {
					console.warn('EMPTY STATEMENT, SKIPPING', currentSection);
					store.dispatch(actions.setNextSection(true));
				} else {
					store.dispatch(actions.setStep('sections'));
				}
				break;
			case 'questions':
				// Make sure we have questions, otherwise skip
				const { questions } = currentSection;

				if (!questions || !questions.length) {
					console.warn('EMPTY QUESTIONS, SKIPPING', currentSection);
					store.dispatch(actions.setNextSection(true));
				} else {
					store.dispatch(actions.setStep('sections'));
				}
				break;
			case 'single_question':
				// Make sure we have questions, otherwise skip
				const { questions: singleQuestions } = currentSection;

				if (!singleQuestions || !singleQuestions.length) {
					console.warn('EMPTY QUESTIONS, SKIPPING', currentSection);
					store.dispatch(actions.setNextSection(true));
				} else {
					store.dispatch(actions.setStep('sections'));
				}
				break;
			case 'swipe':
				// Make sure we have questions, otherwise skip
				const { products } = currentSection;

				if (!products || !products.length) {
					console.warn('EMPTY PRODUCTS, SKIPPING', currentSection);
					store.dispatch(actions.setNextSection(true));
				} else {
					store.dispatch(actions.setStep('sections'));
				}
				break;
			case 'text-ai':
				const textAiResults = action.payload.data;
				if (textAiResults) {
					store.dispatch(actions.setResults(textAiResults));
				}
				store.dispatch(actions.setStep('text-ai'));
				break;
			case 'open-ended-pledge':
				store.dispatch(actions.setStep('sections'));
				break;
			case 'red-herring':
				store.dispatch(actions.setStep('sections'));
				break;
			case 'link_routing':
				store.dispatch(actions.setStep('sections'));
				break;
			// case 'end':
			// 	const endResults = action.payload.data;
			// 	if (endResults) {
			// 		store.dispatch(actions.setResults(endResults));
			// 	}
			// 	store.dispatch(actions.setStep('end'));
			// 	break;
			default:
				// todo - add fallback to error
				console.error('Invalid section type');
				store.dispatch(actions.setStep('error'));
				return null;
		}
	}
};

/**
 * Data Effects
 */

const getRedHerringSections = (sections, testMode, provider) => {
	const redHerringSections = [];
	if (sections && !testMode && provider === 'marketplace') {
		const containsOpenEndedQuestions = sections.some(
			(section) => section.questions && section.questions.some((question) => question.style === 'open-ended'),
		);
		if (containsOpenEndedQuestions) {
			redHerringSections.push({
				name: 'Open ended Pledge',
				type: 'open-ended-pledge',
			});
		}

		redHerringSections.push({
			name: 'Red Herring',
			type: 'red-herring',
		});
	}
	return redHerringSections;
};

const fetchData = (store, action) => {
	if (action.type === actions.FETCH_DATA) {
		const { studyId, previewUuid, currentSectionIndex, audienceUuid, language, uuid } = action.payload;
		const urlParams = misc.getAllUrlParams();
		const qualifiers = urlParams && urlParams.q ? urlParams.q : [];
		store.dispatch(actions.setQualifiers(qualifiers));
		let { rnid } = action.payload;
		Promise.resolve()

			/* Fetch study */
			.then(() => api.getStudy(studyId, language, audienceUuid, previewUuid, uuid))

			/* Check it for being open and have products */
			.then((study) => (study.previewUuid ? nopeFunction(study) : checkStudy(study)))

			/* Set title */
			// .then((study) => ((document.title = study.name), study))

			/* for single deploy studies, generate RID */
			.then((study) => {
				if (!rnid && (previewUuid || study.previewUuid)) {
					rnid = `upsiide-preview-${Math.floor(Math.random() * 10000)}-${Date.now()}`;
				}
				if (
					!rnid &&
					(!!study.settings.allowSingleDeploy ||
						!!study.audienceAllowSingleDeploy ||
						!previewUuid ||
						!study.previewUuid)
				) {
					rnid = `upsiide-${Math.floor(Math.random() * 100)}-${Date.now()}`;
				}
				if (!rnid) {
					console.error('no RID');
					throw new Error('INVALID_RNID');
				}
				store.dispatch(actions.setResponseRID(rnid));
				datadogRum.setUser({
					id: rnid,
				});

				// look for randomization in question groups.
				// If there is, randomize the order of the questions
				const sectionsWithRandomizationCheck = sectionsWithRandomization(study.sections);
				const studyWithRandomizationCheck = { ...study, sections: sectionsWithRandomizationCheck };

				store.dispatch(actions.setStudy(studyWithRandomizationCheck));
				return studyWithRandomizationCheck;
			})

			/* Create a response */
			.then((study) =>
				study.previewUuid
					? study
					: api
							.createResponse(study, rnid, study.audienceUuid, uuid)

							/* Check the response for whether it's already complete */
							.then((study) => (study.previewUuid ? nopeFunction(study) : checkResponse(study)))
							.then((response) => {
								if (response?.redirectUrl) {
									console.warn('createResponse has redirect');
									setTimeout(() => {
										window.location.href = response.redirectUrl;
									}, 50);
								} else {
									console.warn('no createResponse redirect');
								}
								store.dispatch(actions.setResponseId(response.id));
								return study;
							}),
			)

			/* Set sections and proceed to first one */
			.then((study) => {
				const { sections } = study;
				if (sections && !study.previewUuid && study.provider === 'marketplace') {
					const redHerringSections = getRedHerringSections(sections, study.previewUuid, study.provider);
					study.sections = [...redHerringSections, ...sections];
				}
				//* ****** */
				store.dispatch(actions.setSections(study.sections));

				const urlParams = misc.getAllUrlParams();
				const sectionIdExists = urlParams.hasOwnProperty('sectionId');
				const questionIdExists = urlParams.hasOwnProperty('questionId');
				if (sectionIdExists) {
					const urlParamsSectionId = Number(urlParams.sectionId);
					if (questionIdExists) {
						const urlParamsQuestionId = Number(urlParams.questionId);
						store.dispatch(actions.jumpToQuestion(urlParamsQuestionId, urlParamsSectionId));
					} else {
						store.dispatch(actions.jumpToSection(urlParamsSectionId));
					}
				} else {
					store.dispatch(actions.setCurrentSectionIndex(currentSectionIndex));
				}

				return study;
			})
			.then((study) => {
				if (study.previewUuid || study.provider !== 'marketplace') return study;
				store.dispatch(actions.validateRespondent(study, audienceUuid, study.previewUuid, rnid));
				return study;
			})
			/* Put error to the state */
			.catch((error) => {
				console.log(error);
				store.dispatch(actions.setState(error));

				Sentry.withScope((scope) => {
					if (error?.response?.data?.message === 'INVALID_AUDIENCE_STATUS' || error === 'completed') {
						scope.setLevel('info');
						const errorMessage = error === 'completed' ? 'Audience already completed' : 'Invalid Audience';
						const newError = new Error(errorMessage);
						newError.cause = error;
						Sentry.captureException(newError);
					} else {
						Sentry.captureException(error);
					}
				});
			});
	}
};

const RESEARCH_DEFENDER_IGNORED_ERRORS = ['ETIMEDOUT', 'ERR_NETWORK'];

const validateRespondent = async (store, action) => {
	if (action.type === actions.VALIDATE_RESPONDENT) {
		try {
			store.dispatch(actions.setValidateResponseLoading(true));
			const { study, audienceUuid, testMode, rnid } = action.payload;
			if (testMode || study.provider !== 'marketplace') return;
			let tokenData = null;

			try {
				const {
					data: {
						results: [result],
					},
				} = await researchDefenderApi.getToken();

				const token = result ? result.token : null;

				tokenData = token;
			} catch (e) {
				Sentry.captureException(e);
				if (!RESEARCH_DEFENDER_IGNORED_ERRORS.includes(e.code)) {
					throw Error(e);
				}
			}

			let activityStatistics = [
				{
					Activity: {
						Token: { Count: undefined, Average: undefined },
					},
				},
			];

			try {
				const {
					data: { Statistics },
				} = await researchDefenderApi.activity(audienceUuid, rnid);

				activityStatistics = Statistics;
			} catch (e) {
				Sentry.captureException(e);
				if (!RESEARCH_DEFENDER_IGNORED_ERRORS.includes(e.code)) {
					throw Error(e);
				}
			}

			const [
				{
					Activity: {
						Token: { Count: ipCount, Average: ipAverage },
					},
				},
			] = activityStatistics;

			const responseId = selectors.getResponseId(store.getState());
			const respondentToken = selectors.getResponseRID(store.getState());

			setTimeout(async () => {
				const passParams = window.passParams?.();

				const data = {
					token: tokenData,
					ipCount: ipCount || 0,
					ipAverage: ipAverage || 0,
					...(study.useServerlessSurvey ? {} : { responseId }),
					actionTaken: 'NO_ACTION',
					fullUrl: window?.location?.href,
					passParams,
				};

				const response = await api.responseQualityLogger(study, audienceUuid, respondentToken, data);
				if (response?.data?.redirectUrl) {
					window.location.replace(response?.data?.redirectUrl);
				}
				store.dispatch(actions.setValidateResponseLoading(false));
			}, 100);
		} catch (error) {
			console.log('catch', error);
			store.dispatch(actions.setValidateResponseLoading(false));
			store.dispatch(actions.setState('error'));
			store.dispatch(actions.setStep('terminated'));

			Sentry.captureException(error);
		}
	}
};

const pushData = async (store, action) => {
	if (action.type === actions.PUSH_DATA) {
		const { force } = action.payload;
		let { audienceUuid } = action.payload;
		const state = store.getState();
		const responseId = selectors.getResponseId(state);
		const results = selectors.getResults(state);
		const answers = selectors.getAnswers(state);
		const study = selectors.getStudy(state);
		const sentimentText = selectors.getSentimentText(state);
		const sentimentTags = selectors.getSentimentTags(state);
		const qualifiers = selectors.getQualifiers(state);
		const { settings } = study;
		const winningProductId = settings.sendWinningProduct ? results.best : undefined;
		const { interests, commitments } = results;
		const data = {
			studyUuid: study.uuid,
			interests,
			commitments,
			answers,
			winningProductId,
			sentimentText,
			sentimentTags,
		};
		const urlParams = misc.getAllUrlParams();

		audienceUuid = audienceUuid || study.audienceUuid;
		(study.previewUuid ? Promise.resolve() : api.closeResponse(responseId, data, audienceUuid, qualifiers, study))
			.then((data) => {
				store.dispatch(actions.setState(true));

				if (data && data.redirectUrl) {
					console.warn('Response has redirect');
					setTimeout(() => {
						window.location.href = data.redirectUrl;
					}, 3000);
				} else {
					// Typical engineered redirection
					console.warn('attempt redirect');
					store.dispatch(actions.attemptToRedirect());
				}
			})
			.catch((error) => {
				store.dispatch(actions.setState(force ? 1 : 0));

				Sentry.withScope((scope) => {
					if (error?.response?.data?.message === 'INVALID_AUDIENCE_STATUS') {
						scope.setLevel('info');
						const newError = new Error('Invalid Audience');
						newError.cause = error;
						Sentry.captureException(newError);
					} else {
						Sentry.captureException(error);
					}
				});
			});
	}
};

const fetchDistributedSplitProducts = async (store, action) => {
	if (action.type === actions.FETCH_DISTRIBUTED_SPLIT_PRODUCTS) {
		try {
			store.dispatch(actions.setDistributedSplitProductsLoading(true));
			const { sectionId } = action.payload;
			const state = store.getState();
			const study = selectors.getStudy(state);
			const language = study.currentLanguage;
			const { audienceUuid, useServerlessSurvey } = study;
			const responseId = selectors.getResponseId(state);
			const rid = selectors.getResponseRID(state);
			const answers = selectors.getAnswers(state);
			const urlParams = misc.getAllUrlParams();
			const qualifiers =
				(urlParams && urlParams.q)?.map((qualifier) => ({
					questionId: Number(qualifier.name),
					value: qualifier.value,
				})) || [];

			const quotaAnswerObject = [];

			Object.keys(answers).forEach((questionId) => {
				// Array is always MS and we can ignore these
				const answer = answers[questionId];
				if (Array.isArray(answer?.value) || answer?.sectionId) return;

				const splitQuestionId = questionId.split('-')[0];
				if (answer?.type === 'guided-video-question' || Number.isNaN(Number(answer?.value))) {
					return;
				}
				quotaAnswerObject.push({
					questionId: Number(splitQuestionId),
					type: answer?.type,
					value: Number(answer?.value),
					attributeId: answer?.attributeId,
				});
			});

			/* Fetch distributed Idea Split products */
			const { data: distributedSplitProducts } = await api.getResponseSplitSectionProducts(
				study,
				sectionId,
				responseId,
				language,
				audienceUuid || null,
				rid,
				quotaAnswerObject?.length && useServerlessSurvey ? quotaAnswerObject : [],
				qualifiers?.length && useServerlessSurvey ? qualifiers : [],
			);

			const newStudySections = study.sections;
			const splitSectionId = newStudySections.findIndex(
				(section) => section.id === distributedSplitProducts.sectionId,
			);

			if (useServerlessSurvey) {
				// Serverless survey only returns the list of IDs so we need to use that as the filter instead of replacing content.
				newStudySections[splitSectionId].products = shuffle(
					newStudySections[splitSectionId]?.products?.filter((product) =>
						distributedSplitProducts?.products?.includes(product.id),
					),
				);
			} else {
				newStudySections[splitSectionId].products = shuffle(distributedSplitProducts.products);
			}

			study.sections = newStudySections;

			/* Set study products */
			const studyProducts = [];
			study.sections.forEach((section) => {
				if (!section.products || !section.products.length) {
					return;
				}

				section.products.forEach((product) => {
					const alreadyInProductsList = studyProducts.find((p) => p.id === product.id);
					if (!alreadyInProductsList) {
						studyProducts.push(product);
					}
				});
			});
			study.products = studyProducts;

			/* Put study to the store */
			store.dispatch(actions.setStudy(study));
			store.dispatch(actions.setDistributedSplitProductsLoading(false));
		} catch (error) {
			store.dispatch(actions.setDistributedSplitProductsLoading(false));
			store.dispatch(actions.setState('error'));
			store.dispatch(actions.setStep('terminated'));
			Sentry.captureException(error);
		}
	}
};

/**
 * Redirect Effects
 */

const attemptToRedirect = (store, action) => {
	if (action.type === actions.ATTEMPT_TO_REDIRECT) {
		const state = store.getState();
		const RID = selectors.getResponseRID(state);
		const study = selectors.getStudy(state);
		const results = selectors.getResults(state);
		const { settings } = study;
		const { redirectUrl } = settings;

		const allProducts = study.sections.reduce((currentValue, section) => {
			if (section?.products) {
				return [...currentValue, ...section.products];
			}

			return currentValue;
		}, []);

		const winningProductId =
			settings.sendWinningProduct && results && results.best
				? (allProducts.filter((product) => product.id == results.best).pop() || {}).localProductId
				: 0;
		const likedProducts =
			settings.sendLikedProducts && results && results.interests && results.interests.length
				? results.interests
						.filter(({ interest }) => interest)
						.map(({ id }) => id)
						// Transform IDs to LOCAL PRODUCT IDs
						.map((id) => (allProducts.filter((product) => product.id == id).pop() || {}).localProductId)
						.filter((localProductId) => !!localProductId)
						.join(',') || 0 // 0 returned if interests but all dislikes
				: 0;

		if (redirectUrl) {
			const search = location.search.substring(1);

			const urlParams = search
				? JSON.parse(`{"${decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"')}"}`)
				: {};
			urlParams.RID = RID;

			let newPreparedUrl = Object.keys(urlParams).reduce(
				(str, key) => str.replace(`{${key}}`, urlParams[key]),
				redirectUrl,
			);

			// If {winner} present replace it regardless of if there was a winner
			if (newPreparedUrl.includes('{winner}')) {
				newPreparedUrl = newPreparedUrl.replace('{winner}', winningProductId);
			} else if (winningProductId) {
				// Only append if there is a winner
				if (!newPreparedUrl.includes('?')) {
					newPreparedUrl = `${newPreparedUrl}?winner=${winningProductId}`;
				} else {
					newPreparedUrl = `${newPreparedUrl}&winner=${winningProductId}`;
				}
			}

			// If {liked} present replace it regardless of if there was a winner
			if (newPreparedUrl.includes('{liked}')) {
				newPreparedUrl = newPreparedUrl.replace('{liked}', likedProducts);
			} else if (likedProducts) {
				// Only append if there is liked Products
				if (!newPreparedUrl.includes('?')) {
					newPreparedUrl = `${newPreparedUrl}?liked=${likedProducts}`;
				} else {
					newPreparedUrl = `${newPreparedUrl}&liked=${likedProducts}`;
				}
			}

			window.location.href = newPreparedUrl;
		}
	}
};

/**
 * State Effects
 */

const saveState = (store, action) => {
	if (action.type === actions.SAVE_STATE) {
		const state = selectors.getMainState(store.getState());
		localStorage.setItem('saved-state', JSON.stringify(state));
	}
};

const loadState = (store, action) => {
	if (action.type === actions.LOAD_STATE) {
		const savedState = localStorage.getItem('saved-state');
		if (savedState) {
			store.dispatch(actions.replaceState(JSON.parse(savedState)));
		}
	}
};

const saveAndLoadUserData = (store, action) => {
	if (action.type === actions.SAVE_DATA_TO_SERVERLESS) {
		const { token, audienceUuid, studyUuid, data, redirectUrl, timeout = 0 } = action.payload;

		const {
			RID,
			answers,
			currentSectionIndex,
			questionIndex,
			responseId,
			results,
			sentimentTags,
			sentimentText,
			state,
			step,
			qualifiers,
			study,
		} = data.main;


		const search = location.search.substring(1);

		const urlParams = search
			? JSON.parse(`{"${decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"')}"}`)
			: {};


		const newData = {
			urlParams,
			main: {
				RID,
				answers,
				currentSectionIndex,
				questionIndex,
				responseId,
				results,
				sentimentTags,
				sentimentText,
				state,
				step,
				qualifiers,
				language: study.currentLanguage,
			},
		};
		Promise.all([
			api.saveUserData({ token, audienceUuid, studyUuid: studyUuid.toString(), data: newData }),
			new Promise((resolve) => setTimeout(resolve, timeout)),
		])
			.then(() => {
				window.location.replace(redirectUrl);
			})
			.catch(() => {
				store.dispatch(actions.setState('error'));
				store.dispatch(actions.setStep('terminated'));
				Sentry.captureException(new Error('SERVERLESS POST API FAILURE'));
			});
	}
};

const getAndLoadUserData = async (store, action) => {
	if (action.type === actions.GET_USER_DATA) {
		const { token, audienceUuid, studyId, uuid } = action.payload;
		api.getUserData({ token, audienceUuid })
			.then(async ({ data }) => {
				const nextStore = data?.data?.main;

				const urlParams = data?.data?.urlParams || {};
				const searchParams = new URLSearchParams(window.location.search);
				Object.entries(urlParams).forEach(([key, value]) => {
					const existingParam = searchParams.get(key);
					if (existingParam) return;
					searchParams.set(key, value);
				});
				const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
				window.history.pushState({}, '', newUrl);

				const study = await api.getStudy(studyId, nextStore.language, audienceUuid, previewUuid, uuid);
				const { currentLanguage, previewUuid } = study;
				// localization
				i18n.changeLanguage(currentLanguage);
				window.dispatchEvent(new Event('changeLanguage'));
				const initializeNextStore = () => {
					let { currentSectionIndex } = nextStore;
					let { sections, previewUuid } = study;

					const redHearringSections = getRedHerringSections(sections, previewUuid, study.provider);
					sections = [...redHearringSections, ...sections];

					sections = sectionsWithRandomization(sections);

					if (currentSectionIndex < sections?.length - 1) {
						currentSectionIndex += 1;
						const currentStore = store.getState().main;
						store.dispatch(
							actions.setMainStore({
								...currentStore,
								...nextStore,
								sections,
								study,
							}),
						);
						store.dispatch(actions.setCurrentSectionIndex(currentSectionIndex));
					} else {
						const currentStore = store.getState().main;
						store.dispatch(
							actions.setMainStore({
								...currentStore,
								...nextStore,
								sections,
								study,
								currentSectionIndex,
								step: 'end',
							}),
						);
					}
				};
				// checking if the response is already submitted in survey mode
				if (previewUuid) {
					initializeNextStore();
				} else {
					api.createResponse(study, token, audienceUuid)
						.then(previewUuid ? nopeFunction : checkResponse)
						.then((response) => {
							initializeNextStore();
							if (response?.redirectUrl) {
								console.warn('createResponse has redirect');
								setTimeout(() => {
									window.location.href = response.redirectUrl;
								}, 50);
							} else {
								console.warn('no createResponse redirect');
							}
							store.dispatch(actions.setResponseId(response.id));
						})
						.catch((err) => {
							// setting study to attempt redirect when survey is already submitted
							console.log(err);
							store.dispatch(actions.setStudy(study));
							store.dispatch(actions.setState(err));
						});
				}
			})
			.catch((er) => {
				console.log(er);
				const currentSectionIndex = selectors.getCurrentSectionIndex(store.getState());
				const previewUiid = selectors.getPreviewUiid(store.getState());
				store.dispatch(actions.fetchData(studyId, token, audienceUuid, previewUiid, currentSectionIndex));
				Sentry.captureException(new Error('SERVERLESS GET API FAILURE'));
			});
	}
};

const checkQuotas = async (store, action) => {
	if (action.type === actions.CHECK_QUOTAS) {
		const { study, rid } = action.payload;
		const urlParams = misc.getAllUrlParams();
		const qualifiers =
			(urlParams && urlParams.q)?.map((qualifier) => ({
				questionId: Number(qualifier.name),
				value: qualifier.value,
			})) || [];

		if (!qualifiers?.length || !study?.hasQuotas || !study?.enforceSampleLimit)
			return store.dispatch(actions.setNextSection(true));

		const response = await api.validateQuestionsQuota(study, study.audienceUuid, [], rid, qualifiers);

		if (response?.data?.isOverQuota == true) {
			store.dispatch(actions.setDisqualified([], study.audienceUuid, 'quota-reached', true));
			store.dispatch(actions.setStep('terminated'));
			if (response?.data?.redirectUrl) {
				// setSec // seta a section pra "terminated"
				return setTimeout(() => {
					window.location.replace(response?.data?.redirectUrl);
				}, 3000);
			}
		} else {
			// Set answers as empty array so we don't save them twice!
			if (!study.useServerlessSurvey) {
				const answers = selectors.getAnswers(store.getState());

				store.dispatch(actions.setRemovedAnswers(answers));
				store.dispatch(actions.setAnswers([]));
				store.dispatch(actions.setQualifiers([]));
			}
			store.dispatch(actions.setNextSection(true));
		}
	}
};

// Regular expression pattern to match
// only 0s and 1s
const isBinaryString = (str) => {
	const binaryPattern = /^[01]+$/;
	return binaryPattern.test(str);
};

export const endScreeners = async (store, action) => {
	if (action.type === actions.END_SCREENERS) {
		const { study, RID, answers } = action.payload;

		// If it is the end of the questions then set the currentSectionIndex to the survey index
		// const surveyIndex = sections.findIndex(section => section === 'survey');
		const quotaAnswerObject = [];

		const rankedQuestionAnswerRankByIds = {};

		Object?.keys(answers || {}).forEach((questionId) => {
			if (answers[questionId]?.type === 'ranked') {
				rankedQuestionAnswerRankByIds[questionId] = 0;
			}
		});

		Object.keys(answers).forEach((questionId) => {
			const selectedAnswers = Array.isArray(answers[questionId]?.value)
				? answers[questionId]?.value
				: [answers[questionId]?.value];
			const splitQuestionId = questionId.split('-')[0];
			for (const answer of selectedAnswers) {
				if (answers[questionId]?.type !== 'guided-video-question') {
					if (answers[questionId]?.type === 'ranked') {
						rankedQuestionAnswerRankByIds[questionId] += 1;
					}

					quotaAnswerObject.push({
						questionId: Number(splitQuestionId),
						type: answers[questionId]?.type,
						...(answers[questionId]?.type === 'ranked'
							? { ranking: rankedQuestionAnswerRankByIds[questionId] }
							: {}),
						...(!Number.isNaN(Number(answer)) ? { value: Number(answer) } : {}),
						...(answers[questionId].attributeId !== null
							? { attributeId: answers[questionId].attributeId }
							: {}),
					});
				}
			}
		});

		const urlParams = misc.getAllUrlParams();
		const qualifierData =
			(urlParams && urlParams.q)?.map((qualifier) => ({
				questionId: Number(qualifier.name),
				value: qualifier.value,
			})) || [];

		const testMode = !!study.previewUuid;
		if (testMode) {
			store.dispatch(actions.setQuestionIndex(0));
			store.dispatch(actions.setAnswers(answers));
			store.dispatch(actions.setNextSection());

			return;
		}

		if (study?.hasQuotas && study?.enforceSampleLimit) {
			const quotaResponse = await api.validateQuestionsQuota(
				study,
				study.audienceUuid,
				quotaAnswerObject,
				RID,
				qualifierData,
			);

			// eslint-disable-next-line eqeqeq
			if (quotaResponse?.data?.isOverQuota == true) {
				// setDisqualifiedWithReason([], 'quota-reached', study.audienceUuid, true);
				store.dispatch(actions.setDisqualified([], study.audienceUuid, 'quota-reached', true));

				// setStep('terminated');
				store.dispatch(actions.setStep('terminated'));
			} else {
				store.dispatch(actions.setQuestionIndex(0));
				if (!study.useServerlessSurvey) {
					// Set answers as empty array so we don't save them twice!
					store.dispatch(actions.setRemovedAnswers(answers));
					store.dispatch(actions.setAnswers([]));
					store.dispatch(actions.setQualifiers([]));
				} else {
					const qualifiers = urlParams && urlParams.q ? urlParams.q : [];
					store.dispatch(actions.setAnswers(answers));
					store.dispatch(actions.setQualifiers(qualifiers));
				}

				store.dispatch(actions.setNextSection());
			}
		} else {
			store.dispatch(actions.setQuestionIndex(0));
			store.dispatch(actions.setAnswers(answers));
			store.dispatch(actions.setNextSection());
		}
	}
};

export const enhanceAnswers = (store, action) => {
	if (action.type === actions.ENHANCE_ANSWERS) {
		const { answers, currentQuestion, focusEndTime, focusStartTime } = action.payload;
		const answer = answers[currentQuestion?.id];
		if (answer && answer.type === 'open-ended') {
			const wpmData = getWpmData({ answer, focusStartTime, focusEndTime });
			const answerWithWpmData = {
				...answer,
				...wpmData,
			};
			answers[currentQuestion.id] = answerWithWpmData;
			store.dispatch(actions.setAnswers(answers));
			// setAnswers(answers);
		}
		if (currentQuestion && currentQuestion.style === 'heatmap') {
			const isOptional = misc.getQuestionSetting(currentQuestion, 'optional') === 'true';
			if (isOptional && !(answer?.value?.length > 0)) {
				const updatedAnswer = {
					...answer,
					type: 'heatmap',
					skipped: true,
				};
				answers[currentQuestion.id] = updatedAnswer;
				store.dispatch(actions.setAnswers(answers));
			}
		}
	}
};

export const checkLogic = async (store, action) => {
	if (action.type === actions.CHECK_LOGIC) {
		const { question, answers, currentQuestionIndex, study, focusStartTime, focusEndTime } = action.payload;
		const RID = selectors.getResponseRID(store.getState());

		const results = selectors.getResults(store.getState());
		const { interests } = results;

		const currentSectionIndex = selectors.getCurrentSectionIndex(store.getState());
		const allSections = selectors.getSections(store.getState());
		const currentSection = allSections[currentSectionIndex];

		const interestWithLocalId = interests.map((interest) => ({ ...interest, id: interest.localProductId }));

		const params = Object.fromEntries(new URLSearchParams(window.location.search));

		const qualData = Object.entries(params)
			.map(([key, value]) => {
				const regex = /q\[(\d+)\]/;
				const match = key.match(regex);
				if (!match) {
					return null;
				}

				const lucidId = match[1];
				// look up the demographic question by lucidId
				const demoQuestionData = study.demoGroupQuestions.find((q) => q.qualificationId === Number(lucidId));

				if (!demoQuestionData) {
					return null;
				}

				let theOptionId = demoQuestionData?.options?.find(
					(option) => Number(option.value) === Number(value),
				)?.id;

				// if there is no options id perhaps its a binary sequence.
				if (!theOptionId && lucidId.toString() === '42') {
					theOptionId = value.toString();
				} else if (!theOptionId && isBinaryString(value)) {
					const optionValues = [];

					const optionBinary = value.toString().split('');
					let hasNothingSelected = true;
					optionBinary.forEach((binary, index) => {
						const isSelected = Boolean(Number(binary));
						if (isSelected) {
							const validOption = demoQuestionData?.options.find(
								(option) => Number(option.value) === index + 1,
							);

							if (validOption) {
								hasNothingSelected = false;
								optionValues.push(validOption.id);
							} else if (demoQuestionData?.options.length === index + 1 && hasNothingSelected) {
								// None of the above option
								const noneOfTheAboveOption = demoQuestionData?.options.find(
									(option) => Number(option.value) === -3105,
								);
								optionValues.push(noneOfTheAboveOption?.id);
							}
						}
					});

					theOptionId = optionValues;
				}

				return {
					questionId: demoQuestionData.questionId,
					value: theOptionId,
				};
			})
			.filter((q) => q);

		const currentProductIndex = selectors.getProductIndex(store.getState());
		const splitCurrentProduct =
			currentSection.type === 'monadic_split' ? currentSection?.products[currentProductIndex]?.id : null;

		const removedAnswers = selectors.getRemovedAnswers(store.getState());
		const response = question?.logic
			? runLogic({
					answers: { ...answers, ...removedAnswers },
					swipes: { interests: interestWithLocalId },
					demographic: {
						studyId: study.id,
						language: study.language,
						productOrder: study.productOrder, // TODO - should we save this still?
						audienceUuid: study.audienceUuid,
						fullUrl: window.location.href,
					},
					logic: cloneDeep(question.logic),
					qualifiers: qualData || [],
					splitCurrentProduct,
			  })
			: null;

		let logicIsValid = true;
		if (question?.logic) {
			logicIsValid = surveyValidateLogic({
				studySections: allSections,
				logicBlock: question,
			});
		}

		// logic was met!
		if (response && response.then.item && logicIsValid) {
			// Handle disqualification
			if (response.then.item === '$disqualify') {
				store.dispatch(actions.setDisqualified(answers, study.audienceUuid));

				const testMode = !!study.previewUuid;
				if (testMode) {
					store.dispatch(actions.setStep('terminated'));
				} else {
					setDisqualified(answers, study.audienceUuid);
				}

				return;
			}

			if (response.then.item === '$end_screeners') {
				store.dispatch(actions.endScreeners({ study, RID, answers }));
				return;
			}

			if (response.then.item === '$end_section') {
				if (currentSection.type === 'monadic_split') {
					const nextProductIndex = currentProductIndex + 1;

					if (currentSection?.products?.length > nextProductIndex) {
						store.dispatch(actions.setProductIndex(nextProductIndex));

						return;
					}
				}

				const nextSection = allSections[currentSectionIndex + 1];

				if (nextSection) {
					store.dispatch(actions.setQuestionIndex(0));
					store.dispatch(actions.setAnswers(answers));
					store.dispatch(actions.jumpToSection(Number(nextSection.id)));
				} else {
					const currentQuestion = currentSection.questions[currentQuestionIndex];
					store.dispatch(actions.enhanceAnswers({ answers, currentQuestion, focusStartTime, focusEndTime }));
					store.dispatch(actions.setAnswers(answers));
					store.dispatch(actions.setStep('end'));
				}

				return;
			}

			if (response.then.item === '$end_study') {
				const currentQuestion = currentSection.questions[currentQuestionIndex];
				store.dispatch(actions.enhanceAnswers({ answers, currentQuestion, focusStartTime, focusEndTime }));
				store.dispatch(actions.setAnswers(answers));
				store.dispatch(actions.setStep('end'));

				return;
			}

			const { type, id } = parseItemString(response.then.item) || { type: '', id: '' };

			if (type === 'question') {
				// put all questions from all sections into one array
				// then find the destination question object to know which section ID to use
				const destinationSectionId = allSections
					.flatMap((section) => section.questions || [])
					.find((q) => q.id === Number(id))?.sectionId;

				store.dispatch(actions.jumpToQuestion(Number(id), destinationSectionId));
			} else if (type === 'section') {
				store.dispatch(actions.setQuestionIndex(0));
				store.dispatch(actions.setAnswers(answers));
				store.dispatch(actions.jumpToSection(Number(id)));
			}
		} else {
			const questionsWithLabels = getQuestionsWithLabels({ questions: currentSection.questions });

			const endOfQuestions = currentQuestionIndex + 1 === questionsWithLabels.length;

			// if endOfQuestions go to next section
			// else go to next question
			const sectionQuestionsWithLabels = getQuestionsWithLabels({
				questions: currentSection.questions,
			});

			const currentQuestion = sectionQuestionsWithLabels[currentQuestionIndex];

			if (currentQuestion?.type === 'qualifier') {
				if (endOfQuestions) {
					store.dispatch(actions.endScreeners({ study, RID, answers }));
				} else {
					// go to next question
					store.dispatch(actions.setQuestionIndex(currentQuestionIndex + 1));
				}
			} else {
				store.dispatch(actions.enhanceAnswers({ answers, currentQuestion, focusStartTime, focusEndTime }));

				// clearInputFocusStartTime();
				store.dispatch(actions.setInputFocusStartTime(null));
				if (endOfQuestions) {
					// If it is the end of the questions then set the currentSectionIndex to the survey index
					// const surveyIndex = sections.findIndex(section => section === 'survey');
					// setQuestionIndex(0);
					store.dispatch(actions.setQuestionIndex(0));
					let wpmAgr = 0;
					let openEndedAnswersLength = 0;
					for (const key in answers) {
						const currentAnswer = answers[key];
						if (currentAnswer.type === 'open-ended') {
							wpmAgr += currentAnswer.wpm;
							openEndedAnswersLength++;
							console.log(
								`id:${key}, total typing time: ${answers[key].totalTypingTime} sec, total no of chars typed: ${answers[key].noOfChars}, wpm: ${answers[key].wpm}`,
							);
						}
					}
					console.log(`Average WPM: ${Math.ceil(wpmAgr / openEndedAnswersLength)}`);
					// setSectionAnswers(answers);
					store.dispatch(actions.setAnswers(answers));
					// handleSetNextSection();
					store.dispatch(actions.setNextSection());
				} else {
					// go to next question
					store.dispatch(actions.setQuestionIndex(currentQuestionIndex + 1));
				}
			}
		}
	}
};

export default [
	// Data
	fetchData,
	pushData,
	fetchDistributedSplitProducts,
	setDisqualified,

	// State
	saveState,
	loadState,

	// Redirects
	attemptToRedirect,

	// Section
	jumpToSection,
	jumpToQuestion,
	setCurrentSectionIndex,
	setNextSection,

	// Response
	terminateResponse,
	validateRespondent,

	saveAndLoadUserData,
	getAndLoadUserData,

	checkQuotas,
	checkLogic,
	endScreeners,
	enhanceAnswers,
];
