import { shuffle } from 'lodash';

export const getUrlParameter = (name) => {
	const cleanName = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
	const regex = new RegExp(`[\\?&]${cleanName}=([^&#]*)`);
	const results = regex.exec(location.search);
	return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};
export const getAllUrlParams = (url = location.href) => {
	// get query string from url (optional) or window
	url = decodeURI(url);
	let queryString = url ? url.split('?')[1] : window.location.search.slice(1);

	// we'll store the parameters here
	const obj = {};

	// if query string exists
	if (queryString) {
		// stuff after # is not part of query string, so get rid of it
		queryString = queryString.split('#')[0];

		// split our query string into its component parts
		const arr = queryString.split('&');

		for (let i = 0; i < arr.length; i++) {
			// separate the keys and the values
			const a = arr[i].split('=');

			// set parameter name and value (use 'true' if empty)
			const paramName = a[0];
			const paramValue = typeof a[1] === 'undefined' ? true : a[1];

			// if the paramName ends with square brackets, e.g. colors[] or colors[2]
			if (paramName && paramName.match(/\[(\w+)?\]$/)) {
				// create key if it doesn't exist
				const key = paramName.replace(/\[(\w+)?\]/, '');
				if (!obj[key]) obj[key] = [];

				// if it's an indexed array e.g. colors[2]
				// if (paramName.match(/\[\d+\]$/)) {
				// 	// get the index value and add the entry at the appropriate position
				// 	var index = /\[(\d+)\]/.exec(paramName)[1];
				// 	obj[key][index] = paramValue;
				// } else
				if (paramName.match(/\[\w+\]$/)) {
					// get the index value and add the entry at the appropriate position
					const index = /\[(\w+)\]/.exec(paramName)[1];
					obj[key].push({ name: index, value: paramValue });
				} else {
					// otherwise add the value to the end of the array
					obj[key].push(paramValue);
				}
			} else {
				// we're dealing with a string
				if (!obj[paramName]) {
					// if it doesn't exist, create property
					obj[paramName] = paramValue;
				} else if (obj[paramName] && typeof obj[paramName] === 'string') {
					// if property does exist and it's a string, convert it to an array
					obj[paramName] = [obj[paramName]];
					obj[paramName].push(paramValue);
				} else {
					// otherwise add the property
					obj[paramName].push(paramValue);
				}
			}
		}
	}

	return obj;
};

export const setUrlParams = (params = {}) => {
	const url = new URL(window.location.href);

	Object.keys(params).forEach((key) => {
		const value = params[key];
		if (typeof value !== 'string' && value?.length) {
			return;
		}
		url.searchParams.set(key, value);
	});

	window.history.replaceState(null, null, url);
};

export const checkForValidOpenEnded = (question, value) => {
	const openEndLengthLimit = 1024;
	const numericValueOnly =
		question && question.settings && question.settings.find((s) => s.label === 'numeric')
			? question.settings.find((s) => s.label === 'numeric').value === 'true'
			: null;

	const openEndNumericMin =
		question && question.settings && question.settings.find((s) => s.label === 'numeric-min')
			? question.settings.find((s) => s.label === 'numeric-min').value
			: null;

	const openEndNumericMax =
		question && question.settings && question.settings.find((s) => s.label === 'numeric-max')
			? question.settings.find((s) => s.label === 'numeric-max').value
			: null;

	const openEndOptional =
		question && question.settings && question.settings.find((s) => s.label === 'optional')
			? question.settings.find((s) => s.label === 'optional').value === 'true'
			: null;

	return (
		(openEndOptional && value.length === 0) || //
		(!numericValueOnly && value.length > 0 && value.length <= openEndLengthLimit) || // Any input is valid
		(numericValueOnly &&
			value.length <= openEndLengthLimit &&
			value.match(/^[+-]?\d+(\.\d+)?$/) && // Numeric only setting checking that only numbers with one decimal
			(!openEndNumericMin || (openEndNumericMin && parseFloat(value) >= parseFloat(openEndNumericMin))) && // Check input is >= min setting
			(!openEndNumericMax || (openEndNumericMax && parseFloat(value) <= parseFloat(openEndNumericMax))))
	); // Check input is <= max setting
};

export const checkForValidInputs = (question, value, style, noneOfTheAboveSelected, otherValue = '') => {
	const multipleSelect =
		question && question.settings && question.settings.find((s) => s.label === 'multi-select')
			? question.settings.find((s) => s.label === 'multi-select').value === 'true'
			: null;

	const limitSettingType =
		question && question.settings && question.settings.find((s) => s.label === 'limit-type')
			? question.settings.find((s) => s.label === 'limit-type').value
			: null;

	const limitSetting =
		question && question.settings && question.settings.find((s) => s.label === 'limit')
			? question.settings.find((s) => s.label === 'limit').value
			: null;

	const hasOtherSpecify = question && question.options && question.options.find((o) => o.isOtherSpecify);
	const otherSpecifyOptionId = hasOtherSpecify ? hasOtherSpecify.id.toString() : null;

	const otherSpecifyOptionSelected = Array.isArray(value)
		? value.find((v) => v === otherSpecifyOptionId)
		: value === otherSpecifyOptionId;

	const validInputs =
		noneOfTheAboveSelected || // None of the above has been selected
		(!multipleSelect && value.length > 0) || // Not multi-select - any input is valid
		(multipleSelect && !limitSettingType && value.length > 0) || // Multi-select setting with no limit-type setting - any number of inputs is valid
		(multipleSelect && limitSettingType && limitSettingType === 'unlimited' && value.length > 0) || // Multi-selct setting with limit-type setting as unlimited - any number of inputs is valid
		(multipleSelect && limitSettingType && !limitSetting && limitSettingType === 'exact' && value.length == 1) || // Multi-select setting with limit-type setting as exact but now limit set - only 1 input is valid
		(multipleSelect &&
			limitSettingType &&
			limitSetting &&
			limitSettingType === 'exact' &&
			value.length == limitSetting) || // Multi-select setting with limit type setting as exact and limit set - must select exact amount = to limit
		(multipleSelect && limitSettingType && limitSettingType === 'range' && value.length > 0) || // Multi-select setting with limit-type setting as range - any number of inputs is valid
		(style === 'single-select' && value.length > 0); // For old content - single select question with any answer

	return question.hasOtherSpecifyOption && otherSpecifyOptionSelected // Mutli-select setting with other-type setting selected - 'Please Specify' field must have a value before checking for the other validInputs
		? otherValue.length > 0 && validInputs
		: validInputs;
};

export const checkForValidHeatmap = (question, value) => {
	const isOptional = getQuestionSetting(question, 'optional') || 'false';
	const allowMultipleClicks = getQuestionSetting(question, 'multi-click') || 'true';
	const clickLimitType = getQuestionSetting(question, 'click-limit-type') || 'unlimited';
	const clickLimit = getQuestionSetting(question, 'click-limit');
	if (isOptional === 'true') {
		return true;
	}

	if (value?.length < 1) {
		return false;
	}

	if (allowMultipleClicks === 'false' && value?.length === 1) {
		return true;
	}

	if (clickLimitType === 'range' && !!clickLimit && value?.length <= parseInt(clickLimit)) {
		return true;
	}

	if (clickLimitType === 'exact' && !!clickLimit && value?.length === parseInt(clickLimit)) {
		return true;
	}

	if (allowMultipleClicks === 'true' && clickLimitType === 'unlimited' && value?.length >= 1) {
		return true;
	}

	return false;
};

export const getQuestionSetting = (question, setting) => {
	if (question && question.settings) {
		if (question.settings.find((s) => s.label === setting)) {
			return question.settings.find((s) => s.label === setting).value;
		}
		return null;
	}
	return null;
};

export const getAssetVariationUrl = (asset, requestType = ['thumbnail']) => {
	// return an emptry string if no asset is provided
	if (!asset) {
		return '';
	}

	// If asset is a string, send it back
	if (typeof asset === 'string') {
		return asset;
	}

	// Look up the requested variation
	if (asset?.variations?.length || asset?.assetVariations?.length) {
		const variations = asset?.variations || asset?.assetVariations;

		const filteredTypes = variations.filter((assetVariation) => requestType.includes(assetVariation.type));

		if (filteredTypes && filteredTypes.length) {
			const sortedTypes = filteredTypes.sort((a, b) => requestType.indexOf(a.type) - requestType.indexOf(b.type));

			const requestedAsset = sortedTypes.find((assetVariation) => requestType.includes(assetVariation.type));

			if (requestedAsset) {
				return requestedAsset.url || requestedAsset.location;
			}
		}
	}

	// Defailt return asset URL
	return asset.url || '';
};

export const productsContainVideo = (products) => {
	if (!products || products.length === 0) {
		return false;
	}

	const hasVideo = products.some((product) => {
		if (product?.fieldOneType === 'asset' && product?.fieldOne[0]?.mediaType === 'video') {
			return true;
		}

		if (product?.fieldTwoType === 'asset' && product?.fieldTwo[0]?.mediaType === 'video') {
			return true;
		}

		if (product?.fieldThreeType === 'asset' && product?.fieldThree[0]?.mediaType === 'video') {
			return true;
		}

		return false;
	});

	return hasVideo;
};

export const detectBrowser = () => {
	const userAgentString = navigator.userAgent;

	// Detect browsers
	const isChrome = userAgentString.indexOf('Chrome') > -1;
	const isSafari = userAgentString.indexOf('Safari') > -1 && !isChrome;
	const isIE = userAgentString.indexOf('MSIE') > -1 || userAgentString.indexOf('rv:') > -1;
	const isEdge = userAgentString.indexOf('Edg') > -1;
	const isFirefox = userAgentString.indexOf('Firefox') > -1;
	const isOpera = userAgentString.indexOf('OP') > -1 && isChrome;

	// Return the browser name
	if (isEdge) return 'Edge';
	if (isChrome && !isOpera) return 'Chrome';
	if (isSafari) return 'Safari';
	if (isFirefox) return 'Firefox';
	if (isIE) return 'Internet Explorer';
	if (isOpera) return 'Opera';

	return 'Unknown';
};

export const getQuestionsWithLabels = ({ questions }) => {
	const questionsWithLabels = questions.filter((q) => {
		if (q.style === 'logic-block') {
			return q;
		}
		const questionLabel = q.label.replace(/<\/?p[^>]*>/g, '');
		// remove questions with no labels
		if (
			q.style !== 'guided-video-question' && (
				!questionLabel ||
				!questionLabel.length ||
				questionLabel === 'Type question here...' ||
				questionLabel === 'Type option here...'
			)

		) {
			return false;
		}
		// remove questions with no answer/option labels
		if (['single-select', 'multi-select', 'grid', 'emoji', 'ranked'].includes(q.style)) {
			const hasNonEmptyOptionLabels = q.options.some((o) => {
				if (o.label) {
					return true;
				}

				if (o.maskedOptions.length) {
					return true;
				}

				return false;
			});

			if (!q.options || q.options.length < 1 || !hasNonEmptyOptionLabels) {
				return false;
			}
		}
		// remove Grid type questions with no attributes
		if (q.style === 'grid') {
			const hasAttributeLabels =
				q.attributes.length &&
				q.attributes.filter(
					(a) => a.translations?.findIndex((translation) => translation.label.length !== 0) !== -1,
				).length;
			const hasOptionLabels = q.options.length && q.options.filter((o) => o.label.length).length;
			if (!hasAttributeLabels || !hasOptionLabels) return false;
		}
		return q;
	});

	return questionsWithLabels;
};

export const resetKeyboardNavigationFocus = ({ delay = 100, blurOnly = false } = {}) => {
	// Get the currently focused element
	const { activeElement } = document;

	// Remove focus from the currently focused element
	if (activeElement) {
		activeElement.blur();
	}

	if (blurOnly) {
		// Reset focus context to prevent pressing `Tab` twice after calling `blur` on the active element
		document.body.setAttribute('tabindex', '-1'); // Temporarily make <body> focusable
		document.body.focus(); // Focus the <body>
		document.body.removeAttribute('tabindex'); // Clean up by removing the tabindex
		return;
	}

	setTimeout(() => {
		// Get all focusable elements in the document
		const focusableElements = Array.from(
			document.querySelectorAll(
				'input:not([tabindex="-1"]), textarea:not([tabindex="-1"]), button:not([tabindex="-1"]), select:not([tabindex="-1"]), a[href]:not([tabindex="-1"]), [tabindex]:not([tabindex="-1"]), [contenteditable="true"]:not([tabindex="-1"])',
			),
		);

		// Focus the first available element
		if (focusableElements.length > 0) {
			focusableElements[0].focus();
		}
	}, delay);
};

export const sectionsWithRandomization = (sections) => {
	const randomSections = sections?.map((section) => {
		if (section.type === 'questions') {
			let { questions } = section;

			const hasRandomization = section?.settings?.some(
				(setting) => setting.label === 'randomize_questions' && setting.value === '1',
			);

			if (hasRandomization) {
				questions = shuffle(section?.questions);
			}

			questions?.forEach((question, index) => {
				question.sortOrder = index;
			});

			return { ...section, questions };
		}
		return section;
	});

	return randomSections;
};
