import * as deviceDetect from 'react-device-detect';
import { shuffle } from 'lodash';
import * as misc from 'src/utilities/misc';
import axios from './axios';
import i18n from '../i18n';

const API_PATH = `${process.env.apiPath}`;
const SERVERLESS_SURVEY_PATH = `${process.env.serverlessSurveyApi}`;

/* A hack for now */
const fix = (v) => (v === '[]' ? [] : v);
const fixOptions = (v) => (v === null ? {} : v);

const getStudy = (studyId, language, previewUuid) =>
	axios
		.get(`${API_PATH}/studies/${studyId}/survey?languageCode=${language}&previewUuid=${previewUuid}`)
		.then(({ data }) => data);

const getAudience = (studyId, audienceUuid, previewUuid) =>
	axios
		.get(`${API_PATH}/studies/${studyId}/audiences/${audienceUuid}/survey?&previewUuid=${previewUuid}`)
		.then(({ data }) => data);

const getSections = (studyId, language, previewUuid, audienceUuid) =>
	axios
		.get(`${API_PATH}/studies/${studyId}/sections/survey?languageCode=${language}&previewUuid=${previewUuid}`, {
			headers: {
				'x-survey-uuid': audienceUuid,
			},
		})
		.then(({ data }) => data);

const createResponse = (body, study) => {
	console.log('createResponse: data: ', body);
	if (study.useServerlessSurvey) {
		return axios
			.post(`${SERVERLESS_SURVEY_PATH}/response/create`, {
				studyUuid: study.uuid,
				...body,
			})
			.then(({ data }) => data);
	}
	return axios
		.post(`${API_PATH}/responses`, {
			studyId: study.id,
			...body,
		})
		.then(({ data }) => data);
};

const closeResponse = (responseId, data, loi, useServerlessSurvey = false) => {
	console.log('closeResponse: data: ', data);
	if (useServerlessSurvey) {
		return axios.patch(`${SERVERLESS_SURVEY_PATH}/response/${responseId}`, { ...data, loi });
	}
	return axios.put(`${API_PATH}/responses/${responseId}`, data);
};

const sendMediaResponse = (responseId, questionId, media) =>
	axios.post(`${API_PATH}/responses/${responseId}/questions/${questionId}/media`, media, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});

const sendInteractionMediaResponse = (responseId, questionId, media, useServerlessSurvey = false) => {
	if (useServerlessSurvey) {
		return axios.post(`${SERVERLESS_SURVEY_PATH}/interaction/${questionId}`, media);
	}
	return axios.post(`${API_PATH}/responses/${responseId}/questions/${questionId}/interaction`, media, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});
};

const getSignedUrl = (questionId, data, useServerlessSurvey = false) => {
	if (useServerlessSurvey) {
		return axios.post(`${SERVERLESS_SURVEY_PATH}/get-signed-url/${questionId}`, data);
	}
	return null;
};

const uploadVideo = (url, video, contentType) =>
	axios.put(url, video, {
		headers: {
			'Content-Type': contentType,
		},
	});

const transcribeAudioFragment = (responseId, questionId, data, useServerlessSurvey = false) => {
	if (useServerlessSurvey) {
		return axios.post(`${SERVERLESS_SURVEY_PATH}/transcribe/${questionId}`, data);
	}
	return axios.post(`${API_PATH}/responses/${responseId}/questions/${questionId}/transcribe`, data, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});
};

const moderateVideoResponse = (responseId, questionId, data, useServerlessSurvey = false) => {
	if (useServerlessSurvey) {
		return axios.post(`${SERVERLESS_SURVEY_PATH}/moderate/${questionId}`, data);
	}

	return axios.post(`${API_PATH}/responses/${responseId}/questions/${questionId}/moderate`, data, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});
};

const quarantineVideoResponse = (data) => axios.post(`${SERVERLESS_SURVEY_PATH}/quarantine`, data);

const responseQualityLogger = (study, audienceUuid, respondentToken, data) => {
	if (study.useServerlessSurvey) {
		return axios.post(`${SERVERLESS_SURVEY_PATH}/logger/${study.uuid}/${audienceUuid}/${respondentToken}`, data);
	}
	return axios.post(`${API_PATH}/studies/${study.uuid}/${audienceUuid}/responses/${respondentToken}/logger`, data);
};

const terminateResponse = (terminateResponseId, payload, useServerlessSurvey = false) => {
	if (useServerlessSurvey) {
		return axios.post(`${SERVERLESS_SURVEY_PATH}/response/delete`, payload);
	}
	return axios.delete(`${API_PATH}/responses/${terminateResponseId}`, { data: payload });
};

const createDisqualificationResponse = (responseId, payload, useServerlessSurvey = false) => {
	if (useServerlessSurvey) {
		return axios.post(`${SERVERLESS_SURVEY_PATH}/response/delete`, payload);
	}
	return axios.delete(`${API_PATH}/responses/${responseId}`, { data: payload }); // using DELETE with a request body
};

const saveUserData = (data) => axios.post(`${SERVERLESS_SURVEY_PATH}/link-routing`, data);

const getUserData = ({ token, audienceUuid }) =>
	axios.get(`${SERVERLESS_SURVEY_PATH}/link-routing?token=${token}&audienceUuid=${audienceUuid}`);

const getDatafromShortURL = (uuid) =>
	axios.get(`${API_PATH}/shortlink/${uuid}/survey`).catch(() => (window.location.href = 'https://upsiide.com/'));

const getResponseSplitSectionProducts = (
	study,
	sectionId,
	responseId,
	languageCode,
	audienceUuid,
	token,
	answers = [],
	qualifiers = [],
) => {
	if (study.useServerlessSurvey) {
		return axios.post(
			`${SERVERLESS_SURVEY_PATH}/studies/${study.uuid}/section/${sectionId}/audiences/${audienceUuid}/responses/${token}/split-products`,
			{
				languageCode,
				answers,
				qualifiers,
			},
		);
	}
	return axios.get(
		`${API_PATH}/studies/${study.id}/section/${sectionId}/audiences/${audienceUuid}/responses/${responseId}/split-products?languageCode=${study.currentLanguage}`,
	);
};

const validateQuestionsQuota = async (study, audienceUuid, answers, rid, qualifiers) => {
	if (study.useServerlessSurvey) {
		return axios.post(`${SERVERLESS_SURVEY_PATH}/quota/${study.uuid}/${audienceUuid}/${rid}`, {
			qualifiers,
			answers,
		});
	}
	return axios.post(`${API_PATH}/studies/${study.uuid}/audiences/${audienceUuid}/response/${rid}/quota`, {
		qualifiers,
		answers,
	});
};

const fetchForceWebcam = async (study, audienceUuid, questionId) => {
	if (study.useServerlessSurvey) {
		return axios.post(`${SERVERLESS_SURVEY_PATH}/video-question-sampling/${questionId}`, {
			studyUuid: study.uuid,
			audienceUuid,
		});
	}
	return axios.post(
		`${API_PATH}/studies/${study.uuid}/audiences/${audienceUuid}/video-question-sampling/${questionId}`,
	);
};
/**
 * API stub.
 */
export default {
	/**
	 * Retrieve information about study
	 */
	getStudy: async (studyId, queryLanguage, audienceUuid = null, previewUuid = null, uuid) => {
		let browserLanguage = queryLanguage || i18n.language;
		const urlParams = misc.getAllUrlParams();
		const study = uuid
			? (await getDatafromShortURL(uuid)).data
			: await getStudy(studyId, browserLanguage, previewUuid);
		let audience;
		if (!studyId && uuid) {
			studyId = study?.uuid;
			previewUuid = study?.previewUuid;
			browserLanguage = study?.currentLanguage;
			audienceUuid = study?.audienceUuid;
		}

		if (!uuid && previewUuid) {
			study.previewUuid = previewUuid;
		}

		if (!uuid && audienceUuid) {
			study.audienceUuid = audienceUuid;
		}

		if (audienceUuid) {
			audience = await getAudience(studyId, audienceUuid, previewUuid);
			study.audienceAllowSingleDeploy = audience.allowSingleDeploy;
			study.useServerlessSurvey = audience.useServerlessSurvey;
			study.settings.redirectUrl = audience.redirectUrl;
			study.customQuestions = audience.customQuestions;
			study.demoGroupQuestions = audience.demoGroupQuestions;
			study.enforceSampleLimit = audience.enforceSampleLimit;
			study.hasQuotas = audience.hasQuotas || false;
			study.provider = audience.provider;
			study.countryCode = audience.countryCode;
		}

		const supportedLanguages = study.languages || [];
		const defaultLanguage = study.defaultLanguage || 'en';
		let language = browserLanguage;
		if (audience) {
			language = audience.languageCode || defaultLanguage;
		} else if (study.currentLanguage === browserLanguage) {
			language = study.currentLanguage;
		} else {
			language = supportedLanguages.indexOf(browserLanguage) > -1 ? browserLanguage : defaultLanguage;
		}
		i18n.changeLanguage(language);
		window.dispatchEvent(new Event('changeLanguage'));
		const sections = await getSections(studyId, language, previewUuid, audienceUuid);
		sections.push({
			name: 'Custom Questions Section',
			type: 'custom',
			order: -1,
			questions: study.customQuestions,
		});

		/* Set orders */
		let productOrder = [];

		// TODO - remove when ordering is fixed on the API
		sections.sort((a, b) => a.order - b.order);

		// Any pre-config stuff here for sections
		const preparedSections = sections?.map((section) => {
			if (section.type === 'swipe') {
				section.productOrder = shuffle(section?.products?.map(({ id }) => id));
				productOrder = [...productOrder, ...section.productOrder];
			}
			return section;
		});
		study.sections = preparedSections;
		study.productOrder = productOrder;
		const { disablePreviewPrompt } = urlParams;
		return {
			...study,
			...(disablePreviewPrompt ? { disablePreviewPrompt } : {}),
			language,
			sections,
			loi: study.loi,
		};
	},
	createDisqualificationResponse: async (
		responseId,
		answers,
		audienceUuid,
		qualifiers,
		study,
		reasonForDisqualification = null,
	) => {
		try {
			const rankedQuestionAnswerRankByIds = {};
			Object?.keys(answers || {}).forEach((questionId) => {
				if (answers[questionId]?.type === 'ranked') {
					rankedQuestionAnswerRankByIds[questionId] = 0;
				}
			});
			const mappedData = {
				questions: Object.keys(answers || {})
					.map((questionId) =>
						Array.isArray(answers[questionId].value)
							? answers[questionId].value.map((value) => ({
									questionId: questionId.split('-')[0],
									answer: { ...answers[questionId], value },
							  }))
							: { questionId: questionId.split('-')[0], answer: answers[questionId] },
					)
					.reduce((acc, item) => acc.concat(Array.isArray(item) ? item : [item]), [])
					.map(({ questionId, answer }) => {
						if (answer?.type === 'ranked') {
							rankedQuestionAnswerRankByIds[questionId] += 1;
						}
						return {
							questionId: parseInt(questionId),
							...(!Number.isNaN(parseInt(answer.value)) ? { optionId: parseInt(answer.value) } : {}),
							...(answer.type === 'ranked' ? { ranking: rankedQuestionAnswerRankByIds[questionId] } : {}),
							value: answer.otherOptionId === answer.value ? answer.otherValue : undefined,
							attributeId: answer.attributeId,
						};
					}),
				audienceUuid: audienceUuid || study.audienceUuid,
				qualifiers,
				reasonForDisqualification,
				studyUuid: study.uuid,
				token: responseId,
			};
			const response = await createDisqualificationResponse(responseId, mappedData, study.useServerlessSurvey);
			console.groupEnd();
			return response.data;
		} catch (e) {
			console.warn(e);
			throw e;
		}
	},
	/**
	 * Create a response with user data
	 */
	createResponse: async (study, rnid, audienceUuid) => {
		const data = {
			rid: rnid,
			language: study.language,
			productOrder: study.productOrder, // TODO - should we save this still?
			device: deviceDetect && deviceDetect.isMobile ? 'mobile' : 'desktop',
			audienceUuid,
			fullUrl: window.location.href,
		};

		const response = await createResponse(data, study);
		return response;
	},
	terminateResponse,

	/**
	 * Close Response at the end of the flow
	 */
	closeResponse: async (responseId, data, audienceUuid, qualifiers, study) => {
		const { sentimentText, sentimentTags, studyUuid } = data;
		const rankedQuestionAnswerRankByIds = {};
		Object?.keys(data?.answers || {}).forEach((questionId) => {
			if (data?.answers[questionId]?.type === 'ranked') {
				rankedQuestionAnswerRankByIds[questionId] = 0;
			}
		});

		try {
			const mappedData = {
				winningProductId: data.winningProductId,
				liked: data.liked,
				interests: data.interests.map((interest) => ({
					productId: interest.id,
					interest: interest.interest,
					considered: interest.considered,
					sectionId: interest.sectionId,
				})),
				commitments: data.commitments.map((commitment) => ({
					productIdWon: commitment.won,
					productIdLost: commitment.loose,
					sectionId: commitment.sectionId,
				})),
				questions: Object.keys(data.answers || {})
					.map((questionId) =>
						data.answers[questionId]?.type !== 'heatmap' && Array.isArray(data.answers[questionId].value)
							? data.answers[questionId].value.map((value) => ({
									questionId: questionId.split('-')[0],
									answer: { ...data.answers[questionId], value },
							  }))
							: {
									questionId: questionId.split('-')[0],
									answer: data.answers[questionId],
									productID:
										// eslint-disable-next-line no-nested-ternary
										typeof questionId !== 'string'
											? questionId
											: questionId.includes('-')
											? questionId.split('-')[1]
											: null,
							  },
					)
					.reduce((acc, item) => acc.concat(Array.isArray(item) ? item : [item]), [])
					.map(({ questionId, answer, productID }, index) => {
						if (answer?.type === 'ranked') {
							if (answer?.productId) {
								rankedQuestionAnswerRankByIds[`${questionId}-${answer.productId}`] += 1;
							} else {
								rankedQuestionAnswerRankByIds[questionId] += 1;
							}
						}
						if (answer.type === 'heatmap') {
							return {
								questionId: parseInt(questionId),
								sectionId: answer.sectionId,
								value: answer.value,
								skipped: answer.skipped,
								productId: productID ? parseInt(productID) : null,
							};
						}
						return {
							questionId: parseInt(questionId),
							skipped:
								answer.type === 'guided-video-question' && answer.value === 'SKIP' ? true : undefined,
							optionId:
								answer.type === 'open-ended' || answer.type === 'guided-video-question'
									? undefined
									: parseInt(answer.value),
							...(answer.type === 'ranked'
								? {
										ranking: answer.productId
											? rankedQuestionAnswerRankByIds[`${questionId}-${answer.productId}`]
											: rankedQuestionAnswerRankByIds[questionId],
								  }
								: {}),
							...(answer.type === 'custom-age' ? { type: answer.type } : {}),
							value:
								answer.otherOptionId === answer.value
									? answer.otherValue
									: answer.type === 'open-ended'
									? answer.value
									: undefined,
							...(answer.type === 'guided-video-question' ? { isVideoQuestion: true } : {}),
							sectionId: answer.sectionId,
							productId: answer.productId,
							productOrder: answer.productOrder,
							attributeId: answer.attributeId,
							wpm: answer.wpm,
						};
					}),
				audienceUuid,
				studyUuid,
				qualifiers,
			};
			const response = await closeResponse(responseId, mappedData, study.loi, study.useServerlessSurvey);
			console.groupEnd();
			return response.data;
		} catch (e) {
			console.warn(e);
			throw e;
		}
	},
	getResponseSplitSectionProducts,
	responseQualityLogger,
	saveUserData,
	getUserData,
	getDatafromShortURL,
	validateQuestionsQuota,
	sendMediaResponse,
	transcribeAudioFragment,
	sendInteractionMediaResponse,
	moderateVideoResponse,
	quarantineVideoResponse,
	fetchForceWebcam,
	getSignedUrl,
	uploadVideo,
};
