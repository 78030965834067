import React, { useState, useEffect, useRef, useCallback } from 'react';
import { connect, useDispatch } from 'react-redux';
import { withTranslation } from 'react-i18next';
import cn from 'src/utilities/bem-cn';
import { motion } from 'framer-motion';
import Button from 'src/components/new/Button';
import useDebounceCallback from 'src/utilities/debounceCallback';
import useIsMobile from 'src/utilities/hooks/useIsMobile';
import * as misc from 'src/utilities/misc';
import { size } from 'lodash';
import GlobalScrollIndicator from 'src/components/helpers/GlobalScrollIndicator';
import RankedResetButton from '../../../../components/shared/RankedResetButton';
import Question from '../../components/Question';
import * as actions from '../../actions';
import * as selectors from '../../selectors';
import './styles.scss';
import { getQuestionsWithLabels } from '../../../../utilities/misc';

const className = 'questions-container';
const el = (name, mod) => cn(className, name, mod);
const globalButtonHeight = 68;

const animation = {
	start: {
		y: 50,
		opacity: 0,
	},
	completed: {
		y: 0,
		opacity: 1,
		transition: {
			staggerChildren: 100,
		},
	},
};

const questionAnimation = {
	start: {
		y: 50,
		opacity: 0,
	},
	completed: {
		y: 0,
		opacity: 1,
	},
};

const Questions = ({
	t,
	study = {},
	RID,
	setNextSection,
	setSectionAnswers,
	currentSection,
	sections,
	jumpToSection,
	jumpToQuestion,
	questionIndex,
	setStep,
	setQuestionIndex,
	swipeResults,
	allAnswers,
	terminateResponse,
	focusEndTime,
	focusStartTime,
	clearInputFocusStartTime,
	isLastSection,
}) => {
	const dispatch = useDispatch();
	const { questions } = currentSection;

	const isMobile = useIsMobile();

	// Filter through currentSection.questions and remove any questions with no question label, any questions with no answer/option labels, or Grid questions with no attribute labels
	const questionsWithLabels = getQuestionsWithLabels({ questions });

	const isLastQuestion = isLastSection && questionIndex === questionsWithLabels.length - 1;

	const [answers, setAnswers] = useState({});
	const [contentOverflow, setContentOverflow] = useState(false);
	const [justifyContent, setJustifyContent] = useState(false);
	const [pose, setPose] = useState('start');
	const [heatmapOptional, setHeatmapOptional] = useState(false);
	// const [questionIndex, setQuestionIndex] = useState(0);
	const [buttonEnabled, setButtonEnabled] = useState(false);
	const [resetRankedOptions, setResetRankedOptions] = useState(false);
	const [updateDebounceTimer, debounceCallback] = useDebounceCallback();
	const scrollContainerClass = 'scroll-container';

	const runFirstSectionLogicCheck = useRef(true);

	useEffect(() => {
		setPose('completed');
		window.addEventListener('resize', updateQuestionMinHeight);
		window.addEventListener('orientationchange', updateQuestionMinHeight);
		return () => {
			window.removeEventListener('resize', updateQuestionMinHeight);
			window.removeEventListener('orientationchange', updateQuestionMinHeight);
		};
	}, []);

	const preQuestion = useRef(true);
	const elementRef = useRef(true);

	// For centering smaller questions vertically
	const [questionMinHeight, setQuestionMinHeight] = useState(window.innerHeight);
	useEffect(() => {
		// Update debounce timer
		updateDebounceTimer();
		// Wait for render cycle to check client height
		setTimeout(() => {
			setQuestionMinHeight(window.innerHeight);
			const continueButtonBuffer = globalButtonHeight / 2;
			const isContentOverflowing =
				elementRef &&
				elementRef.current &&
				elementRef.current.clientHeight - continueButtonBuffer > window.innerHeight;
			setContentOverflow(isContentOverflowing);
		}, 100);
		setTimeout(() => {
			setJustifyContent(elementRef && elementRef.current && elementRef.current.clientHeight > window.innerHeight);
		}, 30);
	}, [questionIndex]);

	const updateQuestionMinHeight = () => {
		setQuestionMinHeight(window.innerHeight);
		// Wait for render cycle to check client height
		setTimeout(() => {
			const continueButtonBuffer = globalButtonHeight / 2;
			const isContentOverflowing =
				elementRef &&
				elementRef.current &&
				elementRef.current.clientHeight - continueButtonBuffer > window.innerHeight;
			setContentOverflow(isContentOverflowing);
			setJustifyContent(elementRef && elementRef.current && elementRef.current.clientHeight > window.innerHeight);
		}, 100);
	};

	const isGridQuestion = questionsWithLabels[questionIndex]?.style === 'grid';
	const isVideoQuestion = questionsWithLabels[questionIndex]?.style === 'guided-video-question';
	const isRankedQuestion = questionsWithLabels[questionIndex]?.style === 'ranked';

	const isOpenEndedOptional = useCallback(
		(question) => {
			const isOptional = misc.getQuestionSetting(question, 'optional') === 'true';
			if (!isOptional) return;

			const isNumericOnly = misc.getQuestionSetting(question, 'numeric') === 'true';
			const answer = answers[questionsWithLabels[questionIndex].id];
			const hasValue = answer && answer.value;

			if (hasValue && isNumericOnly) {
				const hasValidUserInputAnswer = misc.checkForValidOpenEnded(question, answer ? answer.value : '');
				return hasValidUserInputAnswer;
			}

			return isOptional;
		},
		[answers, questionIndex, questionsWithLabels],
	);

	const isSelectQuestionOptional = useCallback(
		(question) => {
			const isOptional = misc.getQuestionSetting(question, 'optional') === 'true';
			if (!isOptional) return;

			const isMultiSelect = misc.getQuestionSetting(question, 'multi-select') === 'true';
			const answer = answers[questionsWithLabels[questionIndex].id];
			const hasValue = answer?.value?.length;
			if (isOptional && answer?.otherOptionId && answer?.otherValue?.length === 0) return false;
			if (hasValue && isMultiSelect) {
				const hasValidUserInputAnswer = misc.checkForValidInputs(
					question,
					answer?.otherOptionId ? answer?.otherValue : answer?.value,
				);
				return hasValidUserInputAnswer;
			}

			return isOptional;
		},
		[answers, questionIndex, questionsWithLabels],
	);

	useEffect(() => {
		const question = questionsWithLabels[questionIndex];
		if (!question) return;
		const isOptional = misc.getQuestionSetting(question, 'optional') === 'true';
		if (isOptional && question.style === 'open-ended') {
			const isOptionalOE = isOpenEndedOptional(question);
			setButtonEnabled(isOptionalOE);
		}
		if (isOptional && question.style === 'heatmap') {
			const value = answers[question?.id]?.value;
			const hasValidUserInputAnswer = value?.length >= 1;
			if (!hasValidUserInputAnswer) setHeatmapOptional(true);
		} else {
			setHeatmapOptional(false);
		}

		if (question.style === 'ranked') {
			const settings = question?.settings;
			const topNSet = settings?.find((s) => s?.label === 'top-n')?.value === 'true';
			const topN = topNSet
				? Math.min(
						parseInt(settings?.find((s) => s?.label === 'top-n-limit')?.value) || 1,
						answers[question.id]?.availableOptions?.length || 0,
				  )
				: Math.min(answers[question.id]?.availableOptions?.length || 0, 10);

			const answerLength = answers[question.id]?.value?.length || 0;
			const isEnabled = isOptional ? answerLength === 0 || answerLength >= topN : answerLength >= topN;
			setButtonEnabled(isEnabled);
		}

		if (isOptional && ['multi-select', 'emoji', 'grid', 'heatmap'].includes(question.style)) {
			const isOptionalQuestion = isSelectQuestionOptional(question);
			setButtonEnabled(isOptionalQuestion);
		}
	}, [questionsWithLabels, questionIndex, answers, isOpenEndedOptional, isSelectQuestionOptional]);

	const resetScrollContainerClasses = () => {
		setTimeout(() => {
			// Reset scroll container
			const scrollContainer = document.querySelector(`[class*='${scrollContainerClass}']`);
			if (scrollContainer) {
				scrollContainer.classList.remove('scrolled-to-bottom');
				scrollContainer.classList.remove('remove-scroll-indicator');
			}
		}, 100);
	};

	// determine the next section to go to
	const findNext = useCallback(
		(indexToCheck) => {
			const checkIndex = indexToCheck + 1;

			return {
				index: checkIndex,
			};
		},
		[questions.length],
	);

	const handleSetNextSection = useCallback(() => {
		setNextSection();
		runFirstSectionLogicCheck.current = true;
	}, [setNextSection]);

	const handleResults = async (currentQuestion, customAnswers = null) => {
		setButtonEnabled(false);
		resetScrollContainerClasses();

		// Check logic for every question
		dispatch(
			actions.checkLogic({
				question: questionsWithLabels[questionIndex],
				answers: customAnswers || answers,
				currentQuestionIndex: questionIndex,
				study,
				focusStartTime,
				focusEndTime,
			}),
		);
	};

	useEffect(() => {
		if (runFirstSectionLogicCheck.current && questions.length && questionIndex === 0) {
			runFirstSectionLogicCheck.current = false;

			const { index, noMoreQuestions } = findNext(questionIndex - 1);

			if (noMoreQuestions) {
				setQuestionIndex(0);
				setSectionAnswers(answers);
				handleSetNextSection();
			} else if (index !== 0) {
				setQuestionIndex(index);
			}
		}
	}, [answers, findNext, handleSetNextSection, questionIndex, questions.length, setQuestionIndex, setSectionAnswers]);

	const handleChange =
		(question) =>
		(value, type, otherValue = '', otherOptionId = 0, noneOfTheAboveSelected = false) => {
			// * Store the answers in a local state variable
			setAnswers((prev) => {
				if (!value && type === 'open-ended') {
					delete prev[question.id];
					return prev;
				}
				return {
					...answers,
					[question.id]: {
						...answers[question.id],
						value, // * optionId
						type, // * Type of question
						otherValue, // ? Why is this always blank
						otherOptionId, // ? Why is this always 0
						sectionId: currentSection.id, // * ID of the current section
					},
				};
			});

			let hasValidUserInputAnswer = false;

			if (question.style === 'heatmap') {
				hasValidUserInputAnswer = misc.checkForValidHeatmap(question, value);
				if (hasValidUserInputAnswer) {
					setHeatmapOptional(false);
					setAnswers({
						...answers,
						[question.id]: {
							...answers[question.id],
							value, // * optionId
							type, // * Type of question
							sectionId: currentSection.id, // * ID of the current section
						},
					});
				}
			} else if (question.style === 'open-ended') {
				hasValidUserInputAnswer = misc.checkForValidOpenEnded(question, value);
			} else if (question.style === 'guided-video-question') {
				let valueSet = value;
				if (value === 'FAILED-TO-UPLOAD') {
					valueSet = 'SKIP';
				}
				const newAnswers = {
					...answers,
					[question.id]: {
						...answers[question.id],
						value: valueSet || 'OK', // * optionId
						type: 'guided-video-question', // * optionId
						wpm: 20, // * WPM
						sectionId: currentSection.id, // * ID of the current section
					},
				};
				setAnswers(newAnswers);
				if (value !== 'FAILED-TO-UPLOAD') {
					handleResults(question, newAnswers);
				} else {
					setSectionAnswers(newAnswers);
				}
			} else {
				hasValidUserInputAnswer = misc.checkForValidInputs(
					question,
					value,
					question.style,
					noneOfTheAboveSelected,
					otherValue,
				);
			}

			debounceCallback(() => setButtonEnabled(hasValidUserInputAnswer));
		};

	const handleReset = (question) => {
		setAnswers({
			...answers,
			[question.id]: {
				...answers[question.id],
				value: [],
			},
		});
		const scrollContainer = document.querySelector(`[class*='${scrollContainerClass}']`);
		scrollContainer.scrollTo(0, 0, { behavior: 'smooth' });
		setResetRankedOptions(true);
		setTimeout(() => {
			setResetRankedOptions(false);
		}, 50);
	};

	// If the list of questions with labels is empty proceed to the next section
	if (questionsWithLabels.length === 0) {
		handleSetNextSection();
		return false;
	}

	return (
		<motion.div
			animate={pose}
			variants={animation}
			initial="start"
			className={className}
			style={{
				minHeight: `${questionMinHeight - 10}px`,
				maxHeight: `${questionMinHeight - 10}px`,
				height: `${questionMinHeight - 10}px`,
				justifyContent: `${justifyContent ? 'flex-start' : 'center'}`,
			}}
		>
			{/* <h3 className={el('heading')}>{t('get-started-with-questions')}</h3> */}
			<div className={`${el('questions')}`} ref={elementRef}>
				<motion.div
					key={questionsWithLabels[questionIndex]?.id}
					animate={pose}
					variants={questionAnimation}
					initial="start"
				>
					<Question
						study={study}
						language={study.language}
						question={questionsWithLabels[questionIndex]}
						value={
							size(answers) > 0 && answers[questionsWithLabels[questionIndex]?.id]
								? answers[questionsWithLabels[questionIndex]?.id]?.value
								: 0
						}
						onChange={handleChange(questionsWithLabels[questionIndex])}
						answers={answers}
						setAnswers={setAnswers}
						currentSection={currentSection}
						handleResults={() => handleResults(questionsWithLabels[questionIndex])}
						onImageLoad={updateQuestionMinHeight}
						resetRankedOptions={resetRankedOptions}
						isLastQuestion={isLastQuestion}
					/>
				</motion.div>
				{!isVideoQuestion && !isGridQuestion && (
					<div className={`${el('inline-button-container')}${!contentOverflow ? ' no-overflow' : ''}`}>
						<div className={el('inline-button')}>
							<Button
								disabled={!buttonEnabled}
								label={t(heatmapOptional ? 'skip' : 'continue')}
								onClick={(e) => {
									const isMouseClick = e.nativeEvent.pointerType === 'mouse';
									const mouseClicksAmount = e.detail; // * Used to identify synthetic events

									// Reset focus only during keyboard navigation
									if (!isMouseClick || (isMouseClick && !mouseClicksAmount)) {
										misc.resetKeyboardNavigationFocus({ blurOnly: true });
									}

									handleResults(questionsWithLabels[questionIndex]);
								}}
								tabindex={!buttonEnabled ? '-1' : 0}
								dataTestId="continue-button"
							/>
						</div>
					</div>
				)}
				{isRankedQuestion && isMobile && (
					<RankedResetButton
						handleReset={handleReset}
						disabled={answers[questionsWithLabels[questionIndex]?.id]?.value?.length < 1}
						question={questionsWithLabels[questionIndex]}
					/>
				)}
			</div>

			{!isVideoQuestion && !isGridQuestion && (
				<GlobalScrollIndicator
					show={contentOverflow}
					backgroundBlur={questionsWithLabels[questionIndex]?.style === 'ranked'}
				/>
			)}
		</motion.div>
	);
};

const mapStateToProps = (state) => ({
	study: selectors.getStudy(state),
	sections: selectors.getSections(state),
	currentSection: selectors.getCurrentSection(state),
	questionIndex: selectors.getQuestionIndex(state),
	allAnswers: selectors.getAnswers(state),
	swipeResults: selectors.getResults(state),
	RID: selectors.getResponseRID(state),
	focusStartTime: selectors.getFocusStartTime(state),
	focusEndTime: selectors.getFocusEndTime(state),
});

const mapDispatchToProps = (dispatch) => ({
	setSectionAnswers: (answers) => dispatch(actions.setAnswers(answers)),
	setStep: (step) => dispatch(actions.setStep(step)),
	setNextSection: () => dispatch(actions.setNextSection()),
	setQuestionIndex: (index) => dispatch(actions.setQuestionIndex(index)),
	jumpToQuestion: (questionId, sectionId) => dispatch(actions.jumpToQuestion(questionId, sectionId)),
	jumpToSection: (sectionId) => dispatch(actions.jumpToSection(sectionId)),
	terminateResponse: () => dispatch(actions.terminateResponse()),
	clearInputFocusStartTime: () => dispatch(actions.setInputFocusStartTime(null)),
});

export default withTranslation('main')(connect(mapStateToProps, mapDispatchToProps)(Questions));
