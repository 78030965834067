// packages
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
	DndContext,
	closestCorners,
	useSensor,
	useSensors,
	TouchSensor,
	MouseSensor,
	DragOverlay,
} from '@dnd-kit/core';
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import PropTypes from 'prop-types';

// hooks
import useIsMobile from 'src/utilities/hooks/useIsMobile';

// utilities
import cn from 'src/utilities/bem-cn';
import * as misc from 'src/utilities/misc';

// components
import ImageZoom from 'src/components/new/ImageZoom';
import Info from '../../../../../components/icons/Info';
import X from '../../../../../components/icons/X';
import RankedQuestionBanner from '../../../../../components/elements/RankedQuestionBanner';
import RankedQuestionContainer from '../../../containers/RankedQuestionContainer';
import RankedOption from './RankedOption';

// styles
import './styles.scss';

const className = 'ranked-question';
const el = (name, mod) => cn(className, name, mod);

// constants
const CONTAINER_IDS = ['optionsToChooseFrom', 'rankedOptions'];

const RankedQuestion = ({ question, options, onChange, resetRankedOptions, productId }) => {
	// state
	const [optionsToChooseFrom, setOptionsToChooseFrom] = useState(options);
	const [rankedOptions, setRankedOptions] = useState([]);
	const [activeItem, setActiveItem] = useState(null);
	const [draggedItemIndex, setDraggedItemIndex] = useState(null);
	const [overLimitErrorId, setOverLimitErrorId] = useState(null);
	const [justDraggedOptionId, setJustDraggedOptionId] = useState(null);
	const [swipedOptionId, setSwipedOptionId] = useState(null);
	const [showRankedBanner, setShowRankedBanner] = useState(false);

	const { t } = useTranslation('main');

	const isMobile = useIsMobile();

	// * Reverse order of containers for mobile to keep the proper keyboard navigation order
	const containerIds = useMemo(() => (isMobile ? CONTAINER_IDS.toReversed() : CONTAINER_IDS), [isMobile]);

	const topNSet = useMemo(
		() => question?.settings?.find((s) => s?.label === 'top-n')?.value === 'true',
		[question?.settings],
	);

	const topN = useMemo(() => {
		if (topNSet) {
			return Math.min(
				parseInt(question?.settings?.find((s) => s?.label === 'top-n-limit')?.value) || 1,
				options?.length || 1,
			);
		}
		return options?.length > 10 ? 10 : options?.length;
	}, [question?.settings, topNSet, options?.length]);

	const mouseSensor = useSensor(MouseSensor, {
		// Require the mouse to move by 10 pixels before activating
		activationConstraint: {
			distance: 10,
		},
	});
	const touchSensor = useSensor(TouchSensor, {
		// Press delay of 150ms, with tolerance of 5px of movement
		activationConstraint: {
			delay: 150,
			tolerance: 5,
		},
	});

	const sensors = useSensors(mouseSensor, touchSensor);

	// functions
	const handleChange = useCallback(() => {
		const rankedIdsArray = rankedOptions.map((option) => option.id);
		onChange(rankedIdsArray);
	}, [onChange, rankedOptions]);

	const getOptionPosition = (id, container) =>
		container === 'optionsToChooseFrom'
			? optionsToChooseFrom.findIndex((option) => option.id === id)
			: rankedOptions.findIndex((option) => option.id === id);

	const findContainer = (item) => {
		if (item?.data?.current?.type === 'option') {
			if (optionsToChooseFrom?.some((op) => op.id === item.id)) {
				return 'optionsToChooseFrom';
			}
			return 'rankedOptions';
		}
		if (item?.data?.current?.type === 'ranked-question-container') {
			return item?.id;
		}
	};

	const onDragStart = (event) => {
		const { active } = event;
		const activeContainer = findContainer(active);
		const activeItemIndex =
			activeContainer === 'optionsToChooseFrom'
				? optionsToChooseFrom.findIndex((op) => op?.id === active.id)
				: rankedOptions.findIndex((op) => op.id === active.id);
		if (active?.data?.current?.type === 'option') {
			setActiveItem(active?.id);
		}
		if (activeContainer === 'rankedOptions') {
			setDraggedItemIndex(activeItemIndex);
		}
	};

	const handleDragOver = (event) => {
		const { active, over } = event;
		const activeContainer = findContainer(active);
		const overContainer = findContainer(over);
		if (!activeContainer || !overContainer) return;

		const activeItemIndex =
			activeContainer === 'optionsToChooseFrom'
				? optionsToChooseFrom.findIndex((op) => op?.id === active.id)
				: rankedOptions.findIndex((op) => op.id === active.id);

		const overItemIndex =
			overContainer === 'optionsToChooseFrom'
				? optionsToChooseFrom.findIndex((op) => op.id === over.id)
				: rankedOptions.findIndex((op) => op.id === over.id);
		if (overContainer === 'rankedOptions') {
			setDraggedItemIndex(overItemIndex);
		}

		if (activeContainer !== overContainer && active?.data?.current?.type === 'option') {
			const newActiveContainerOptions =
				activeContainer === 'optionsToChooseFrom' ? [...optionsToChooseFrom] : [...rankedOptions];
			const [removedOption] = newActiveContainerOptions.splice(activeItemIndex, 1);
			const newOverContainerOptions =
				overContainer === 'optionsToChooseFrom' ? [...optionsToChooseFrom] : [...rankedOptions];
			newOverContainerOptions.push(removedOption);
			if (activeContainer === 'optionsToChooseFrom') {
				if (rankedOptions.length >= topN) {
					setOverLimitErrorId(removedOption.id);
					setTimeout(() => {
						setOverLimitErrorId(null);
					}, 1500);
					return;
				}
				setOptionsToChooseFrom(newActiveContainerOptions);
				setRankedOptions(newOverContainerOptions);
			} else {
				setOptionsToChooseFrom(newOverContainerOptions);
				setRankedOptions(newActiveContainerOptions);
			}
		}
	};

	const handleDragEnd = (event) => {
		const { active, over } = event;
		setJustDraggedOptionId(active?.id);
		setTimeout(() => {
			setJustDraggedOptionId(null);
		}, 500);
		setActiveItem(null);
		setDraggedItemIndex(null);
		if (!active?.id || !over?.id) return;
		if (active?.id === over?.id || active?.data?.current?.type === 'ranrked-question-container') return;

		const activeContainer = findContainer(active);

		if (activeContainer === 'optionsToChooseFrom') {
			setOptionsToChooseFrom((prevOptions) => {
				const originalPosition = getOptionPosition(active.id, activeContainer);
				const endPosition = getOptionPosition(over.id, activeContainer);
				return arrayMove(prevOptions, originalPosition, endPosition);
			});
		} else {
			setRankedOptions((prevOptions) => {
				const originalPosition = getOptionPosition(active.id, activeContainer);
				const endPosition = getOptionPosition(over.id, activeContainer);
				return arrayMove(prevOptions, originalPosition, endPosition);
			});
		}
	};

	const onIncreaseRanking = useCallback(
		(option) => {
			const optionPosition = rankedOptions.findIndex((op) => op.id === option.id);
			setRankedOptions((prevOptions) => arrayMove(prevOptions, optionPosition, optionPosition - 1));
		},
		[rankedOptions],
	);

	const onDecreaseRanking = useCallback(
		(option) => {
			const optionPosition = rankedOptions.findIndex((op) => op.id === option.id);
			const isLastRank = optionPosition === rankedOptions.length - 1;
			if (isLastRank) {
				setRankedOptions((prevOptions) => prevOptions.filter((op) => op.id !== option.id));
				setOptionsToChooseFrom((prevOptions) => [option, ...prevOptions]);
			} else {
				setRankedOptions((prevOptions) => arrayMove(prevOptions, optionPosition, optionPosition + 1));
			}
		},
		[rankedOptions],
	);

	const onRemoveRankedOption = (option) => {
		setRankedOptions((prevOptions) => prevOptions.filter((op) => op.id !== option.id));
		setOptionsToChooseFrom((prevOptions) => [option, ...prevOptions]);
	};

	const unRankedOptionOnClick = (option) => {
		if (rankedOptions.length >= topN) {
			setOverLimitErrorId(option.id);
			setTimeout(() => {
				setOverLimitErrorId(null);
			}, 1500);
			return;
		}
		setOptionsToChooseFrom((prevOptions) => prevOptions.filter((op) => op.id !== option.id));
		setRankedOptions((prevOptions) => [...prevOptions, option]);
	};

	const handleOptionKeyDown = (e, option) => {
		// Handle keyboard navigation
		const isArrowClicked = e.target.tagName === 'BUTTON';

		if (isArrowClicked || !['Enter', ' '].includes(e.key)) return;

		// Handle selecting or activating the option
		if (optionsToChooseFrom.includes(option)) {
			unRankedOptionOnClick(option);
			return;
		}

		onRemoveRankedOption(option);
	};

	// life cycle
	useEffect(() => {
		if (resetRankedOptions) {
			setRankedOptions([]);
			setOptionsToChooseFrom(options);
		}
	}, [options, resetRankedOptions]);

	useEffect(() => {
		const handleScroll = (e) => {
			if (e.target.scrollTop > 100) {
				setShowRankedBanner(true);
			} else {
				setShowRankedBanner(false);
			}
		};
		const scrollingElement = document.querySelector('.scroll-container');
		scrollingElement.addEventListener('scroll', handleScroll);
		return () => {
			scrollingElement.removeEventListener('scroll', handleScroll);
		};
	}, []);

	useEffect(() => {
		handleChange(rankedOptions);
	}, [rankedOptions]);

	useEffect(() => {
		setOptionsToChooseFrom(options);
	}, [options]);

	useEffect(() => {
		const pageContainer = document.querySelector('.scroll-container');
		if (pageContainer) {
			pageContainer.style.scrollBehavior = 'auto';
			pageContainer.scrollTo(0, 0);
		}
		// Reset scroll container
		const scrollContainer = document.querySelector(`[class*='scroll-container']`);
		if (scrollContainer) {
			scrollContainer.classList.remove('scrolled-to-bottom');
			scrollContainer.classList.remove('remove-scroll-indicator');
		}
	}, []);

	// render functions
	const renderToastr = useCallback(() => {
		if (overLimitErrorId && !isMobile) {
			<div className={el('toast')}>
				<div className={el('left')}>
					<Info />
					<p>{t('rank-list-full')}</p>
				</div>

				<button
					type="button"
					tabIndex={0}
					aria-label="Close warning"
					className={el('close-toast-button')}
					onClick={() => setOverLimitErrorId(null)}
				>
					<X />
				</button>
			</div>;
		}
	}, [overLimitErrorId, isMobile, t]);

	const renderImageOption = (option) =>
		option.asset && (
			<ImageZoom className={el('option-image-container')}>
				<img
					className={`${el('option-image')} ${
						misc.getQuestionSetting(question, 'option_image_cropping') === 'crop'
							? el('option-image-crop')
							: ''
					}`}
					alt={option.label || option.value}
					src={misc.getAssetVariationUrl(option, ['large', 'full', 'medium', 'thumbnail'])}
				/>
			</ImageZoom>
		);

	return (
		<div className={className}>
			{renderToastr()}

			<DndContext
				collisionDetection={closestCorners}
				onDragEnd={handleDragEnd}
				onDragOver={handleDragOver}
				onDragStart={onDragStart}
				modifiers={isMobile ? [restrictToVerticalAxis] : undefined}
				sensors={sensors}
			>
				{isMobile && <RankedQuestionBanner show={showRankedBanner} topN={topN} rankedOptions={rankedOptions} />}

				<SortableContext items={containerIds} strategy={verticalListSortingStrategy}>
					{containerIds.map((id) => (
						<RankedQuestionContainer
							key={id}
							id={id}
							itemsLength={id === 'rankedOptions' ? rankedOptions.length : optionsToChooseFrom.length}
							maxItemLength={topN}
							productId={productId}
						>
							<SortableContext
								items={id === 'rankedOptions' ? rankedOptions : optionsToChooseFrom}
								strategy={verticalListSortingStrategy}
							>
								{id === 'rankedOptions'
									? rankedOptions?.map((option, index) => (
											<RankedOption
												option={option}
												renderImageOption={renderImageOption}
												key={option?.id}
												position={index + 1}
												onDecreaseRanking={onDecreaseRanking}
												onIncreaseRanking={onIncreaseRanking}
												onRemoveRankedOption={onRemoveRankedOption}
												justDraggedOptionId={justDraggedOptionId}
												draggedItemIndex={draggedItemIndex}
												swipedOptionId={swipedOptionId}
												setSwipedOptionId={setSwipedOptionId}
												onKeyDown={(e) => handleOptionKeyDown(e, option)}
												rankedOptionsAmount={rankedOptions?.length || 0}
											/>
									  ))
									: optionsToChooseFrom?.map((option) => (
											<RankedOption
												renderImageOption={renderImageOption}
												option={option}
												key={option?.id}
												unRankedOptionOnClick={unRankedOptionOnClick}
												overLimitErrorId={overLimitErrorId}
												onKeyDown={(e) => handleOptionKeyDown(e, option)}
											/>
									  ))}
							</SortableContext>
						</RankedQuestionContainer>
					))}

					<DragOverlay>
						{activeItem ? (
							<RankedOption
								renderImageOption={renderImageOption}
								option={options?.find((op) => op.id === activeItem)}
								isOverlay
							/>
						) : null}
					</DragOverlay>
				</SortableContext>
			</DndContext>
		</div>
	);
};

RankedQuestion.propTypes = {
	options: PropTypes.array,
	onChange: PropTypes.func,
	question: PropTypes.any,
	resetRankedOptions: PropTypes.bool,
	productId: PropTypes.number,
};

export default RankedQuestion;
