/* eslint-disable no-nested-ternary */
// packages
import React from 'react';
import PropTypes from 'prop-types';

// components
import CrosshairIcon from './components/CrosshairIcon';
import PointerIcon from './components/PointerIcon';
import NotAllowedIcon from './components/NotAllowedIcon';

// styles
import './styles.scss';

const className = 'heatmap-cursor';

const Cursor = ({ x, y, scale, disabled, transitionDuration = 0, getCursorPositionPoint }) => {
	// state
	const isPointer = getCursorPositionPoint({ x, y });

	return (
		<div
			{...{ className }}
			style={{
				top: `${y}%`,
				left: `${x}%`,
				transform: `scale(${scale})`,
				transition: !transitionDuration ? 'none' : `all ${transitionDuration}ms ease-in-out 0ms`,
			}}
		>
			{isPointer ? <PointerIcon /> : disabled ? <NotAllowedIcon /> : <CrosshairIcon />}
		</div>
	);
};

Cursor.propTypes = {
	x: PropTypes.number.isRequired,
	y: PropTypes.number.isRequired,
	scale: PropTypes.number,
	disabled: PropTypes.bool,
	transitionDuration: PropTypes.number,
	getCursorPositionPoint: PropTypes.func,
};

export default Cursor;
