// packages
import { useEffect } from 'react';

// hooks
import useIsKeyboardNavigation from 'src/utilities/hooks/useIsKeyboardNavigation';

const useFocusLock = (lockedElement, { skipInitFocus = false } = {}) => {
	// state
	const isKeyboardNavigation = useIsKeyboardNavigation();

	// life cycle
	useEffect(() => {
		if (!lockedElement) return;

		const focusableElements = lockedElement.querySelectorAll(
			'input:not([tabindex="-1"]), textarea:not([tabindex="-1"]), button:not([tabindex="-1"]), select:not([tabindex="-1"]), a[href]:not([tabindex="-1"]), [tabindex]:not([tabindex="-1"]), [contenteditable="true"]:not([tabindex="-1"])',
		);

		const focusableArray = Array.from(focusableElements);
		const firstElement = focusableArray[0];
		const lastElement = focusableArray[focusableArray.length - 1];

		if (!skipInitFocus && firstElement && isKeyboardNavigation) {
			firstElement.focus();
		}

		const handleKeyDown = (e) => {
			if (e.key !== 'Tab') return;

			if (focusableArray.length === 0) {
				e.preventDefault();
				return;
			}

			// Shift + Tab: Move focus backward
			if (e.shiftKey && document.activeElement === firstElement) {
				lastElement.focus();
				e.preventDefault();
				return;
			}

			// Tab: Move focus forward
			if (document.activeElement === lastElement) {
				firstElement.focus();
				e.preventDefault();
			}
		};

		document.addEventListener('keydown', handleKeyDown);

		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [isKeyboardNavigation, lockedElement, skipInitFocus]);
};

export default useFocusLock;
