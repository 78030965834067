// packages
import React from 'react';
import cards from '@upsiide/product-cards';

// assets
import iconAccept from 'public/images/icon-accept.svg';
import iconReject from 'public/images/icon-reject.svg';

// utilities
import cn from 'src/utilities/bem-cn';

// components
import Stage from './Stage';

// styles
import './styles.scss';

const className = 'card-view';
const el = (name) => cn(className, name);

const debounceDelay = 1000;

const clamp = (n, min, max) => Math.max(Math.min(n, max), min);

class Stack extends React.Component {
	constructor(props) {
		super(props);
		this.stageRef = React.createRef();
		this.state = {
			videoOnlyButtonPulse: true,
			debounceStartTimer: new Date(),
		};
	}

	updateResetDisabledButton = () => {
		this.stageRef?.current?.updateDisabledButtonResetParent();
	};

	getProducts = (products) =>
		Array.from({ length: products.length }, (_, i) => ({
			id: products[i].id,
			localProductId: products[i].localProductId,
			ideaScreenLayout: products[i].ideaScreenLayout,
			imageSize: products[i].imageSize,
			imageCropping: products[i].imageCropping,
			element: (
				<cards.StackCard
					product={products[i]}
					interactionsDisabled
					onOpen={() => this.props.onConsider(products[i].id)}
				/>
			),
		}));

	handleManualInterest = ({ id, localProductId, interest }) => {
		const { onInterest } = this.props;
		onInterest({ id, localProductId, interest });
	};

	shouldComponentUpdate() {
		return false;
	}

	renderButtons = ({ mouseX, resetDisabledButtons, accept, reject }) => {
		const { disableSwipe } = this.props;
		const swipeThreshold = Math.min(window.innerWidth / 4, 200);
		const { videoOnlyButtonPulse, debounceStartTimer } = this.state;
		const rejectOpacity = mouseX < 0 ? 1 : clamp(1 - Math.abs(mouseX) / swipeThreshold, 0, 1);
		const acceptOpacity = mouseX > 0 ? 1 : clamp(1 - Math.abs(mouseX) / swipeThreshold, 0, 1);
		const clickReject = () => {
			const delay = new Date() - debounceStartTimer;
			if (delay > debounceDelay) {
				this.setState({
					videoOnlyButtonPulse: false,
					debounceStartTimer: new Date(),
				});
				reject();
			}
		};
		const clickAccept = () => {
			const delay = new Date() - debounceStartTimer;
			if (delay > debounceDelay) {
				this.setState({
					videoOnlyButtonPulse: false,
					debounceStartTimer: new Date(),
				});
				accept();
			}
		};
		return (
			<div className={el('controls')}>
				<button
					type="button"
					tabIndex={0}
					aria-label="Dislike"
					style={{
						opacity: rejectOpacity || 0,
					}}
					className={`${el('button reject')} ${mouseX < 0 ? ' scale-up' : ''} ${
						disableSwipe && videoOnlyButtonPulse ? 'with-pulse' : ''
					}`}
					onClick={clickReject}
				>
					<span
						className={`${el('button-bot-detection-loader')} reject ${
							resetDisabledButtons ? ' load-in' : ''
						} ${resetDisabledButtons === null ? ' hidden' : ''}`}
					/>
					<img src={iconReject} alt="Reject" draggable="false" />
				</button>

				<button
					type="button"
					tabIndex={0}
					aria-label="Like"
					style={{
						opacity: acceptOpacity || 0,
					}}
					className={`${el('button accept')} ${mouseX > 0 ? ' scale-up' : ''} ${
						disableSwipe && videoOnlyButtonPulse ? 'with-pulse' : ''
					}`}
					onClick={clickAccept}
				>
					<span
						className={`${el('button-bot-detection-loader')} accept ${
							resetDisabledButtons ? ' load-in' : ''
						} ${resetDisabledButtons === null ? ' hidden' : ''}`}
					/>
					<img src={iconAccept} alt="Accept" draggable="false" />
				</button>
			</div>
		);
	};

	render() {
		// console.log('STACK: render - this.props: ', this.props);
		const { currentSection, products, disableSwipe } = this.props;
		const { settings } = currentSection;
		// TODO: Should only have to grab ideaScreenLayout, imageCropping and imageSize once at the top level inside the Survey class and pass it down
		// Idea screen layout
		const ideaScreenLayoutSetting = settings
			? settings.find((s) => s.label === 'idea_screen_layout')
			: { value: '' };
		const ideaScreenLayout =
			ideaScreenLayoutSetting && ideaScreenLayoutSetting.value ? ideaScreenLayoutSetting.value : 'invalid_layout';
		// Idea image size
		const imageSizeSetting = settings ? settings.find((s) => s.label === 'image_size') : { value: '' };
		const imageSize = imageSizeSetting && imageSizeSetting.value ? imageSizeSetting.value : 'medium';
		// Idea image cropping
		const imageCroppingSetting = settings ? settings.find((s) => s.label === 'image_cropping') : { value: '' };
		const imageCropping = imageCroppingSetting && imageCroppingSetting.value ? imageCroppingSetting.value : 'fit';
		// Columns
		const twoColumn = ideaScreenLayout === 'sideBySide';
		products.map((p) => {
			p.twoColumn = twoColumn;
			p.ideaScreenLayout = ideaScreenLayout;
			// TODO: Ideally these would be passed in at the top level only and not prefixed here, however I need to sort of the "Get Started" scenario
			p.imageSize = imageSize;
			p.imageCropping = imageCropping;
			//
			return p;
		});

		return (
			<Stage
				ref={this.stageRef}
				data={this.getProducts(products)}
				onInterest={this.handleManualInterest}
				render={(props) => props.element}
				renderButtons={this.renderButtons}
				disableSwipe={disableSwipe}
			/>
		);
	}
}

export default Stack;
