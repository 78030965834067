import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import cn from 'src/utilities/bem-cn';
import { motion } from 'framer-motion';
import TextAiCard from 'src/domains/main/containers/TextAI/TextAiCard';
import TextAiUtils from 'src/utilities/text-ai';
import * as selectors from '../../../selectors';
import './styles.scss';

const className = 'text-ai-product-intro';
const el = (name, mod) => cn(className, name, mod);

const animation = {
	start: {
		y: 50,
		opacity: 0,
	},
	completed: {
		y: 0,
		opacity: 1,
		transition: {
			staggerChildren: 100,
		},
	},
};

const TextAIProductIntro = ({ t, study = {}, onNext, textAiProduct }) => {
	const [pose, setPose] = useState('start');
	useEffect(() => setPose('completed'), []);
	useEffect(() => {
		TextAiUtils.adjustCardHeight();
		window.addEventListener('resize', () => TextAiUtils.adjustCardHeight(), false);
		if ('onorientationchange' in window) {
			window.addEventListener('orientationchange', () => {
				window.dispatchEvent(new Event('resize'));
			});
		}
	});
	const productPrompt = t('tell-us-how-you-feel-about-this-product');
	const buttonLabel = t('begin');
	return (
		<motion.div animate={pose} initial="start" variants={animation} className={className}>
			<div className="text-ai-card">
				<TextAiCard
					textAiProduct={textAiProduct}
					product={TextAiUtils.prepareProductCardContentForStack(textAiProduct)}
				/>
			</div>
			<div className={el('footer ai-footer')}>
				<div className={el('footer-cta')}>{productPrompt}</div>
				<button
					type="button"
					tabIndex={0}
					className={`basic-button ${el('footer-button')}`}
					onClick={() => onNext()}
				>
					{`${buttonLabel} →`}
				</button>
			</div>
		</motion.div>
	);
};

const mapStateToProps = (state) => ({
	study: selectors.getStudy(state),
});

const mapDispatchToProps = (dispatch) => ({});

export default withTranslation('main')(connect(mapStateToProps, mapDispatchToProps)(TextAIProductIntro));
