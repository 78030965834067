// packages
import * as Sentry from '@sentry/browser';
import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

// actions
import * as actions from 'src/domains/main/actions';

// utilities
import cn from 'src/utilities/bem-cn';
import api from 'src/utilities/api';
import { stripHtmlTags } from 'src/utilities/html';
import * as misc from 'src/utilities/misc';
import useMediaPermissions from 'src/utilities/hooks/useMediaPermissions';
import { recordStartAudio } from 'public/audio/record-start.js';
import { recordStopAudio } from 'public/audio/record-stop.js';

// assets
import noCameraCanvasPlaceholder from 'public/images/no-camera-canvas-placeholder.svg';

// hooks
import useElementRect from 'src/utilities/hooks/useElementRect';
import useIsMobile from 'src/utilities/hooks/useIsMobile';

// components
import CenteredLoader from 'src/components/elements/Loader';
import Transcript from './components/Transcript';
import VideoRecorder from './components/VideoRecorder';
import Toaster from '../Toaster';

// selectors
import * as selectors from '../../../../../selectors';

// styles
import './styles.scss';

const className = 'video-interactor';

const el = (name) => cn(className, name);

const mimeType = MediaRecorder.isTypeSupported('video/webm') ? 'video/webm' : 'video/mp4';
const audioMimeType = MediaRecorder.isTypeSupported('audio/webm') ? 'audio/webm' : 'audio/mp4';

const FREQUENCY_BIN_COUNT = 2 ** 5;
const dataArray = new Uint8Array(FREQUENCY_BIN_COUNT);
const userColor = '#E3F4EC';
const DEFAULT_SYSTEM_FINISH_RESPONSE = 'Thank You';
const DISABLE_VIRTUAL_DEVICE = true;
const DISQUALIFY_STATIC_IMAGE = true;
const IMAGE_RESOLUTION = 2160;

const MAX_IMAGES_TO_CHECK_STATIC_IMAGE = navigator?.deviceMemory > 2 || !!window.chrome ? 30 : 15;
// const DEFAULT_FRAME_RATE = 24; // fps
// const FRAME_COOLDOWN = (1000 / DEFAULT_FRAME_RATE).toFixed(2);
const DEBUG_RECORDER = false;

const formatProduct = (product) => {
	if (!product) return null;
	const idea = {
		productId: product.id,
		title: null,
		description: null,
		assetLocation: null,
		assetDescription: null,
	};
	idea.title = product.name;
	if (product.fieldOneType === 'html') idea.description = stripHtmlTags(product.fieldOne);
	if (product.fieldOneType === 'html' && !idea.title) idea.title = stripHtmlTags(product.fieldOne);
	if (product.fieldTwoType === 'html' && !idea.description) idea.description = stripHtmlTags(product.fieldTwo);
	if (product.fieldTwoType === 'html' && !idea.title) idea.title = stripHtmlTags(product.fieldTwo);
	if (product.fieldThreeType === 'html' && !idea.title) idea.title = stripHtmlTags(product.fieldThree);
	if (product.fieldThreeType === 'html' && !idea.description) idea.description = stripHtmlTags(product.fieldThree);

	const getBiggest = (assets) => {
		if (!assets?.length) return null;
		const sizes = assets.reduce((acc, curr) => ({ ...acc, [curr.type]: curr.location }), {});
		return sizes.full || sizes.large || sizes.medium || assets?.[0]?.location || null;
	};

	if (product.fieldOneType === 'asset') {
		idea.assetLocation = getBiggest(product.fieldOne?.find((p) => p.url)?.variations);
	}
	if (product.fieldTwoType === 'asset' && !idea.assetLocation) {
		idea.assetLocation = getBiggest(product.fieldTwo?.find((p) => p.url)?.variations);
	}
	if (product.fieldThreeType === 'asset' && !idea.assetLocation) {
		idea.assetLocation = getBiggest(product.fieldThree?.find((p) => p.url)?.variations);
	}
	return idea;
};

const cleanupTime = (timeMath) => +(timeMath / 1000).toFixed(3);

const VISIBILITY_TIME = 5; // minutes

const VideoInteractor = ({
	question = {},
	responseId,
	onSuccess,
	productId,
	onRequestPermission,
	canSkip,
	onSkip,
	asyncVideoUpload,
	isLastQuestion,
}) => {
	// state
	const [aiStatus, setAiStatus] = useState('');
	const [isVoiceDetected, setIsVoiceDetected] = useState(false);
	const [showImage, setShowImage] = useState(false);
	const [demoImageURL, setDemoImageURL] = useState(null);
	const [chunks, setChunks] = useState([]);
	const [goodBye, setGoodBye] = useState(false);
	const [loadingVideo, setLoadingVideo] = useState(false);
	const [errorUploadingVideo, setErrorUploadingVideo] = useState(false);
	const [isVideoMirrored, setIsVideoMirrored] = useState(false);
	const [imagesModeration, setImagesModeration] = useState([]);
	const [isStartRecording, setIsStartRecording] = useState(false);
	const [hasPreviewEnabled, setHasPreviewEnabled] = useState(false);
	const [enableSkip, setEnableSkip] = useState(false);

	const [squareWrapperRect, squareWrapperRef] = useElementRect();
	const isStaticImageRef = useRef(false);
	const dispatch = useDispatch();

	const hasSelectedAudioOnly = useSelector((state) => state?.main?.audioOnly);
	const { audienceUuid } = useSelector((state) => state);

	const squareWrapperSideSize = useMemo(() => squareWrapperRect.width, [squareWrapperRect.width]);
	const canvasWidth = useMemo(() => Math.max(squareWrapperSideSize, 600), [squareWrapperSideSize]);
	const canvasHeight = useMemo(() => Math.max(squareWrapperSideSize, 600), [squareWrapperSideSize]);

	const mediaRecorder = useRef(null);
	const canvasRef = useRef(null);
	const contextRef = useRef(null);
	const thumbnailRef = useRef(null);
	const isAiTalking = useRef(false);
	const textToShowRef = useRef(null);
	const recordingTime = useRef(null);
	const audioStream = useRef(null);
	const interactionMap = useRef([]);
	const moderationMap = useRef([]);
	const triesRef = useRef(0);
	const wakeLockRef = useRef(null);

	const isStaticImage = useMemo(() => {
		// Need at least 8 frames for reliable detection
		if (DEBUG_RECORDER) {
			console.table({
				isStaticImage,
				isStaticImageRef: isStaticImageRef.current,
				hasSelectedAudioOnly,
			});
		}
		if (isStaticImage || isStaticImageRef.current) return true;
		if (imagesModeration.length < MAX_IMAGES_TO_CHECK_STATIC_IMAGE) return false;
		if (hasSelectedAudioOnly) return false;
		if (!DISQUALIFY_STATIC_IMAGE) return false;

		// Count consecutive matching frames
		let matchingFramesCount = 0;
		let totalDifferentFrames = 0;

		// Compare each frame with the next one
		for (let i = 1; i < imagesModeration.length; i += 1) {
			const currentFrame = imagesModeration[i];
			const previousFrame = imagesModeration[i - 1];

			if (currentFrame === previousFrame) {
				matchingFramesCount += 1;
				// eslint-disable-next-line no-continue
				continue;
			}
			// If frames are different, check if the difference is very small
			// by comparing first 1000 chars of base64 (header + initial image data)
			const minimalDifference = currentFrame.substring(0, 1000) === previousFrame.substring(0, 1000);

			if (minimalDifference) {
				matchingFramesCount += 1;
			} else {
				totalDifferentFrames += 1;
			}
		}

		const repeatedPercentage = (imagesModeration.length - 1 - totalDifferentFrames) / (imagesModeration.length - 1);
		// Conditions for static image detection:
		if (DEBUG_RECORDER) {
			console.log('Matching frames:', matchingFramesCount);
			console.log('Different frames:', totalDifferentFrames);
			console.log('Repeated percentage:', repeatedPercentage * 100, repeatedPercentage < 0.5);
		}
		// 1. at least 50% of the frames are'nt the same
		if (repeatedPercentage < 0.5) return false;
		if (DEBUG_RECORDER) console.log('%cStatic image detected', 'color: red; font-size: 20px; font-weight: bold;');
		isStaticImageRef.current = true;
		return true;
	}, [hasSelectedAudioOnly, imagesModeration]);

	const frontCamera = useRef(null);
	const cameraSettings = useRef({
		originX: 0,
		originY: 0,
		destW: 380,
		destH: 380,
		translateX: 0,
		scaleX: 1,
		canvasWidth,
		canvasHeight,
	});
	const deviceListRef = useRef([]);
	const audioContextRef = useRef(null);
	const audioPlayerRef = useRef(new Audio());
	const effectPlayerRef = useRef(new Audio());
	const pttEngine = useRef(null);
	const initFocusRef = useRef(null);

	const study = useSelector((state) => selectors.getStudy(state));
	const answers = useSelector((state) => selectors.getAnswers(state));
	const product = useMemo(
		() => formatProduct(study.sections.flatMap(({ products }) => products)?.find((p) => p?.id === productId)),
		[study, productId],
	);
	const isPreview = useMemo(() => !!study.previewUuid, [study.previewUuid]);
	const token = useMemo(() => {
		if (study.previewUuid) return `preview-${crypto.randomUUID()}`;
		return responseId;
	}, [study, responseId]);
	const previewData = useMemo(
		() => ({
			questionGuide: {
				interactionLimit: +(
					question?.settings?.find((setting) => setting.label === 'interaction_limit')?.value || 2
				),
				mandatoryQuestions: question?.mandatoryQuestions || [],
				questionGoal: question?.settings?.find((setting) => setting.label === 'goal')?.value,
			},
			product,
		}),
		[product, question?.mandatoryQuestions, question?.settings],
	);
	const urlParams = misc.getAllUrlParams();
	const { disablePreviewPrompt = false } = urlParams;

	const { checkPermission } = useMediaPermissions();

	// functions
	const terminate = useCallback(
		(reasonForDQ) => {
			stopAllMediaStreams();
			dispatch(actions.setDisqualified([], audienceUuid, reasonForDQ));
		},
		[audienceUuid, dispatch],
	);
	const getWakeLock = async () => {
		try {
			if (wakeLockRef.current) return;
			wakeLockRef.current = await navigator?.wakeLock?.request?.('screen');
		} catch {
			// In case is an iframe or the browser does not support it
		}
	};

	const qualityTerminate = useCallback(() => terminate(`video-question-drop-off`), [terminate]);

	const [toastVisible, setToastVisible] = useState(false);
	const [toastMessage, setToastMessage] = useState('');

	const showToast = () => {
		setToastVisible(true);
		setTimeout(() => {
			setToastVisible(false);
		}, 4000);
	};

	const isMobile = useIsMobile();

	useEffect(() => {
		// Initial focus for screen readers
		initFocusRef.current?.focus();
	}, []);

	useEffect(() => {
		if (!canvasRef.current || contextRef.current) return;
		if (canvasRef.current.width !== canvasWidth || canvasRef.current.height !== canvasHeight) {
			canvasRef.current.width = canvasWidth;
			canvasRef.current.height = canvasHeight;
		}
		contextRef.current = canvasRef.current.getContext('2d', { willReadFrequently: true });
	}, [canvasRef.current, cameraSettings.current]);

	useEffect(() => {
		window.addEventListener('beforeunload', qualityTerminate);

		return () => {
			window.removeEventListener('beforeunload', qualityTerminate);
		};
	}, [qualityTerminate]);

	const hiddenTerminate = useCallback(() => terminate(`video-interview-hidden`), [terminate]);
	const visibilityTimerId = useRef(null);

	// =================================================================================================

	useEffect(() => {
		const handleVisibilityChange = () => {
			if (document.hidden) {
				const id = setTimeout(() => {
					hiddenTerminate();
				}, VISIBILITY_TIME * 60 * 1000);
				visibilityTimerId.current = id;
			} else {
				clearTimeout(visibilityTimerId.current);
				visibilityTimerId.current = null;
			}
		};

		document.addEventListener('visibilitychange', handleVisibilityChange);

		return () => {
			document.removeEventListener('visibilitychange', handleVisibilityChange);
			clearTimeout(visibilityTimerId.current);
		};
	}, [hiddenTerminate]);

	// =================================================================================================

	useEffect(() => {
		const innerScrollContainer = document.querySelector(`.scroll-container__inner`);
		const scrollContainer = document.querySelector(`.scroll-container`);
		const monadic = document.querySelector(
			`.monadic-split-container__product-card-indicator-container-video-question`,
		);
		if (monadic) monadic.style.opacity = 1;
		if (scrollContainer && isMobile) scrollContainer.style.backgroundColor = '#fff';
		if (scrollContainer && !isMobile) scrollContainer.style.backgroundColor = '#F4F4F4';

		if (innerScrollContainer) {
			innerScrollContainer.classList.add('scroll-container__inner--rtl');
		}

		return () => {
			innerScrollContainer.classList.remove('scroll-container__inner--rtl');
			scrollContainer.style.backgroundColor = '#fff';
		};
	}, [isMobile]);

	useEffect(() => {
		if (goodBye && loadingVideo) {
			const monadic = document.querySelector(
				`.monadic-split-container__product-card-indicator-container-video-question`,
			);
			if (monadic) monadic.style.opacity = 0;
			return () => {
				if (monadic) monadic.style.opacity = 1;
			};
		}
	}, [goodBye, loadingVideo]);

	useEffect(() => {
		getWakeLock();
		return () => wakeLockRef?.current?.release?.();
	}, []);

	const initializeAudioPlayer = () => {
		audioPlayerRef.current.muted = true; // safari & iOS
		audioPlayerRef.current.volume = 0; // cross-browser
		audioPlayerRef.current.play(); // this will assure that the audio can actually play
		audioPlayerRef.current.muted = false;
		audioPlayerRef.current.volume = 1;
		effectPlayerRef.current.muted = true; // safari & iOS
		effectPlayerRef.current.volume = 0; // cross-browser
		effectPlayerRef.current.play(); // this will assure that the audio can actually play
		effectPlayerRef.current.muted = false;
		effectPlayerRef.current.volume = 1;
	};

	const playEffect = async (effect) => {
		await new Promise((resolve) => {
			effectPlayerRef.current.src = effect;
			effectPlayerRef.current.addEventListener('ended', () => {
				resolve();
			});
			effectPlayerRef.current.play();
		});
	};

	const speechHandler = (ourText, base64) => {
		try {
			// play ai generated audio
			setAiStatus('Speaking...');
			isAiTalking.current = true;
			if (base64) {
				audioPlayerRef.current.src = base64;
				const typewriterListenerFunction = () => {
					startTypewriterEffect(ourText, (audioPlayerRef.current?.duration || 0) * 900);
					audioPlayerRef.current.removeEventListener('loadedmetadata', typewriterListenerFunction);
				};
				audioPlayerRef.current.addEventListener('loadedmetadata', typewriterListenerFunction);
				audioPlayerRef.current.addEventListener('ended', () => checkNextInteraction(ourText), { once: true });
				const start = cleanupTime(recordingTime?.current ? performance.now() - recordingTime.current : 0);
				interactionMap.current.push({
					role: 'assistant',
					text: ourText,
					words: [],
					start: +(audioPlayerRef.current?.duration || 0) + start,
				});
				audioPlayerRef.current.play();
			} else {
				checkNextInteraction(ourText);
			}
		} catch (error) {
			console.error('catch', error);
			checkNextInteraction(ourText);
		}
	};

	const setPttEngine = (value) => {
		pttEngine.current = value;
	};

	const startTypewriterEffect = (text, duration) => {
		if (!text || !duration || text?.length === 0) return;
		const charsToAdd = text.split('');
		if (textToShowRef.current?.textContent) textToShowRef.current.textContent = '';
		let charIdx = 0;
		const interval = setInterval(() => {
			if (charIdx < charsToAdd.length && textToShowRef?.current) {
				textToShowRef.current.textContent += charsToAdd[charIdx];
				charIdx += 1;
			} else {
				clearInterval(interval);
			}
		}, (duration - duration / text.length) / text.length);
	};

	const checkNextInteraction = (ourText) => {
		isAiTalking.current = false;
		if (!interactionMap.current?.[interactionMap.current.length - 1]?.end) {
			interactionMap.current[interactionMap.current.length - 1].end = +(
				(performance.now() - recordingTime.current) /
				1000
			).toFixed(3);
		}
		if (ourText !== DEFAULT_SYSTEM_FINISH_RESPONSE) return startAudioTranscription();

		setShowImage(false);
		setAiStatus('DONE');
		onStopRecord();
		setGoodBye(true);
	};

	useEffect(() => {
		if (
			!canvasRef.current ||
			!DISQUALIFY_STATIC_IMAGE ||
			isStaticImage ||
			!isStartRecording ||
			aiStatus === 'DONE'
		) {
			if (isStaticImage && DEBUG_RECORDER) debugger;
			return;
		}
		const interval = setInterval(() => {
			const img = canvasRef?.current?.toDataURL('image/jpeg', 1.0);
			if (!img) return;
			thumbnailRef.current = img;
			setImagesModeration((prevState) => {
				// 30 images, 1 image for each second, so we can tell if it's freezing a lot or not
				if (prevState.length + 1 > MAX_IMAGES_TO_CHECK_STATIC_IMAGE) {
					prevState.shift();
				}
				return [...prevState, img];
			});
			if (DEBUG_RECORDER) {
				console.table({
					'Tab memory used': `${(performance.memory.usedJSHeapSize / 1024 ** 2).toFixed(3)} MB`,
					'images stored for static moderation': imagesModeration.length,
					'memory used': `${(
						imagesModeration.reduce((acc, curr) => acc + new Blob([curr], { type: 'image/jpeg' }).size, 0) /
						1024 ** 2
					).toFixed(3)} MB`,
				});
			}
			// imagesModeration.reduce((acc, image) => acc + new Blob(image).size, 0) / 1024 ** 2,
		}, 1000);
		return () => clearInterval(interval);
	}, [imagesModeration, isStaticImage, isStartRecording, aiStatus]);

	useEffect(() => {
		getPermission();
	}, []);

	const getPermission = async () => {
		try {
			const devices = await navigator.mediaDevices.enumerateDevices();
			const cameras = devices.filter((device) => {
				if (device.kind !== 'videoinput') return false;
				if (!DISABLE_VIRTUAL_DEVICE) return true;
				return !device.label.toLowerCase().includes('virtual');
			});
			if (cameras.length && !hasSelectedAudioOnly) {
				deviceListRef.current = cameras;
				const audioMedia = await navigator.mediaDevices.getUserMedia({ audio: true });
				saveMediaStream(audioMedia);
				audioStream.current = audioMedia;
				startCameraPreview(cameras[0]);
			} else if (hasSelectedAudioOnly) {
				const audioMedia = await navigator.mediaDevices.getUserMedia({ audio: true });
				saveMediaStream(audioMedia);
				audioStream.current = audioMedia;
				startAudioPreview();
			} else {
				console.log('no cameras available', JSON.stringify(devices));
				setHasPreviewEnabled(false);
			}
		} catch (error) {
			console.log(`No permission was given? => `, error);
			await onRequestPermission();
			setHasPreviewEnabled(false);
			setEnableSkip(true);
		}
	};

	const saveMediaStream = (mediaStream) => {
		if (!navigator?.allMediaStreams) {
			navigator.allMediaStreams = [];
		}
		navigator.allMediaStreams.push(mediaStream);
	};

	const stopAllMediaStreams = () => {
		if (navigator?.allMediaStreams?.length) {
			navigator.allMediaStreams.forEach((stream) => {
				stream.getTracks().forEach((track) => {
					track.stop();
				});
			});
		}
	};

	const getToastMessage = (moderationResponse) => {
		let text = 'Attention:';
		if (moderationResponse?.inappropriateLanguage) text += ' avoid inappropriate language;';
		if (!hasSelectedAudioOnly) {
			if (moderationResponse?.inappropriateDrawn) text += ' avoid inappropriate drawings;';
			if (moderationResponse?.inappropriateDressed) text += ' avoid inappropriate dressing;';
			if (moderationResponse?.faceCoveredPercentage >= 50) text += ' your face might be covered;';
			if (moderationResponse?.isUnderaged) text += ' you might be underaged;';
			if (moderationResponse?.nudity) text += ' do not show nudity;';
			if (!moderationResponse?.person) text += ' make sure there is a person;';
			if (!moderationResponse?.realPerson) text += ' make sure there is a real person;';
		}
		return text;
	};

	const startAudioTranscription = async () => {
		try {
			const audioRecorder = new MediaRecorder(audioStream?.current, { mimeType: audioMimeType });
			audioRecorder?.start(100); // gets 100ms sample data (needed for mp4 transcriptions)
			interactionMap.current.push({
				role: 'user',
				start: cleanupTime(performance.now() - recordingTime.current),
			});

			playEffect(recordStartAudio);
			setAiStatus('Listening...');

			isAiTalking.current = false;
			const localAudioChunks = [];
			// const context = new (window.AudioContext || window.webkitAudioContext)();
			const context = audioContextRef.current;
			const source = context.createMediaStreamSource(audioStream?.current);
			const analyser = context.createAnalyser();
			// Create a high-pass filter node
			const filter = context.createBiquadFilter();
			filter.type = 'highpass';
			filter.frequency.value = 300;
			// Connect the source to the filter, and the filter to the destination
			source.connect(analyser);
			filter.connect(context.destination);
			audioRecorder.ondataavailable = (event) => {
				if (typeof event.data === 'undefined') return;
				if (event.data.size === 0) return;
				localAudioChunks.push(event.data);
			};

			audioRecorder.onstop = async () => {
				interactionMap.current[interactionMap.current.length - 1].end = +(
					(performance.now() - recordingTime.current) /
					1000
				).toFixed(3);
				setShowImage(false);
				await playEffect(recordStopAudio);
				setAiStatus('Thinking...');

				const audioBlob = new Blob(localAudioChunks, { type: audioMimeType });
				const canvasFrame = canvasRef?.current?.toDataURL('image/jpeg', 1.0);
				const buffer = [...new Int8Array(await audioBlob.arrayBuffer())];
				try {
					const transcribedAudioData = await api.transcribeAudioFragment(
						responseId,
						question?.id,
						{
							buffer,
							studyUuid: study.uuid,
							audienceUuid: study.audienceUuid,
							productId,
							respondentToken: token,
							interactionMap: interactionMap.current,
							isPreview,
							...(isPreview ? { previewData } : {}),
						},
						study.useServerlessSurvey || isPreview,
					);

					if (transcribedAudioData?.data?.text?.length) {
						const userSegment = transcribedAudioData?.data?.transcription;
						const askedQuestion =
							interactionMap.current.findLast((interaction) => interaction.role === 'assistant')?.text ||
							null;
						if (!disablePreviewPrompt) {
							const startTime =
								interactionMap?.current?.findLast((interaction) => interaction?.role === 'user')
									?.start || null;

							api.moderateVideoResponse(
								responseId,
								question?.id,
								{
									videoFrame: hasSelectedAudioOnly ? 'audioOnly' : canvasFrame,
									studyUuid: study.uuid,
									audienceUuid: study.audienceUuid,
									respondentToken: token,
									userSegment,
									askedQuestion,
									startTime,
								},
								study.useServerlessSurvey || isPreview,
							)
								.then((moderationResponse) => {
									moderationMap.current.push(moderationResponse?.data);
									const msg = getToastMessage(moderationResponse?.data?.aIModerationOutput);
									if (msg.length && msg !== 'Attention:') {
										setToastMessage(msg);
										showToast();
									}
								})
								.catch((error) => {
									console.log('Moderation Error: ', error);
								});
						}
						interactionMap.current[interactionMap.current.length - 1].text =
							transcribedAudioData.data.transcription;
						interactionMap.current[interactionMap.current.length - 1].words =
							transcribedAudioData?.data?.words;

						if (
							transcribedAudioData?.data?.url &&
							transcribedAudioData?.data?.url.length &&
							transcribedAudioData?.data?.url !== 'null'
						) {
							setDemoImageURL(transcribedAudioData?.data?.url);
							setShowImage(true);
						} else {
							setDemoImageURL(null);
						}
						speechHandler(transcribedAudioData.data.text, transcribedAudioData.data.naturalVoice);
					}
				} catch (error) {
					console.log(error);
					setAiStatus('Listening...');
				}
			};

			let voiceDetectedAt = performance.now();
			let hasEverTalked = false;
			const detectTime = 2000;
			const statusHoldTime = 500;
			const voiceRms = 32;

			const processAudio = () => {
				const data = new Uint8Array(analyser.frequencyBinCount);
				analyser.getByteFrequencyData(data);
				let rms = 0;

				for (let byteFrequency of data) {
					if (byteFrequency > 120) byteFrequency = 120;
					rms += byteFrequency * byteFrequency;
				}

				rms = Math.sqrt(rms / data.length);
				const silenceTime = performance.now() - voiceDetectedAt;
				const shouldStop =
					(silenceTime >= detectTime && hasEverTalked && pttEngine?.current === null) ||
					pttEngine?.current === false;
				if (rms > voiceRms) {
					hasEverTalked = true;
					voiceDetectedAt = performance.now();
					setIsVoiceDetected(true);
				} else if (shouldStop) {
					setIsVoiceDetected(false);
					audioRecorder.stop();
					cancelAnimationFrame(processAudio);
					return;
				} else if (silenceTime >= statusHoldTime) {
					setIsVoiceDetected(false);
				}
				window.requestAnimationFrame(processAudio);
			};

			processAudio();
		} catch (error) {
			console.log('error', error);
			Sentry.captureException(error);
		}
	};

	const sendVideoToApi = async (videoData) => {
		if (isLastQuestion) {
			await asyncVideoUpload(
				videoData,
				setLoadingVideo,
				disablePreviewPrompt,
				question,
				study,
				moderationMap?.current,
				isStaticImageRef?.current,
				thumbnailRef?.current,
				interactionMap?.current,
				hasSelectedAudioOnly,
				onSuccess,
				token,
				productId,
				responseId,
				isPreview,
				isLastQuestion,
			);
		} else {
			asyncVideoUpload(
				videoData,
				setLoadingVideo,
				disablePreviewPrompt,
				question,
				study,
				moderationMap?.current,
				isStaticImageRef?.current,
				thumbnailRef?.current,
				interactionMap?.current,
				hasSelectedAudioOnly,
				onSuccess,
				token,
				productId,
				responseId,
				isPreview,
				isLastQuestion,
			);
			setLoadingVideo(true);
			onSuccess('OK', question?.style);
		}
	};

	const drawBars = (analyser, color) => {
		try {
			// if (
			// 	hasSelectedAudioOnly &&
			// 	canvasRef.current &&
			// 	((!isAiTalking?.current && color === userColor) || (isAiTalking?.current && color === aiColor))
			// ) {
			// 	analyser.getByteFrequencyData(dataArray);
			// const minBarHeight = 1;
			// const baseBarHeight = cameraSettings?.current?.canvasHeight;
			// const spacing = cameraSettings?.current?.canvasWidth * 0.02;
			// const barWidth =
			// 	cameraSettings?.current?.canvasWidth / FREQUENCY_BIN_COUNT -
			// 	spacing -
			// 	spacing / FREQUENCY_BIN_COUNT;
			// const borderRadius = barWidth / 2;
			// canvasRef.current.width = cameraSettings?.current?.canvasWidth;
			// canvasRef.current.height = cameraSettings?.current?.canvasHeight;
			// canvasRef.current.style.backgroundColor = 'black';
			// const ctx = canvasRef.current.getContext('2d');
			// ctx.reset();
			// const indexes = Array(FREQUENCY_BIN_COUNT)
			// 	.fill(0)
			// 	.map((_, i) => i)
			// 	.sort((a, b) => {
			// 		const aOdd = a % 2 === 0;
			// 		const bOdd = b % 2 === 0;
			// 		if (aOdd === bOdd) return aOdd ? a - b : b - a;
			// 		return aOdd ? 1 : -1;
			// 	});
			// for (let i = 0; i < FREQUENCY_BIN_COUNT; i++) {
			// 	const freqPctg =
			// 		(dataArray[indexes[i]] / 255) * ((1 - minBarHeight / baseBarHeight) * 100) +
			// 		(minBarHeight / baseBarHeight) * 100;
			// 	const barHeight =
			// 		(cameraSettings?.current?.canvasHeight / 2) * (freqPctg / 100) <= barWidth
			// 			? barWidth
			// 			: (cameraSettings?.current?.canvasHeight / 2) * (freqPctg / 100);
			// 	const x = i * (barWidth + spacing) + spacing;
			// 	const y = (cameraSettings?.current?.canvasHeight - barHeight) / 2;
			// ctx.fillStyle = color;
			// ctx.beginPath();
			// ctx.moveTo(x + borderRadius, y);
			// ctx.arcTo(x + barWidth, y, x + barWidth, y + barHeight, borderRadius);
			// ctx.arcTo(x + barWidth, y + barHeight, x, y + barHeight, borderRadius);
			// ctx.arcTo(x, y + barHeight, x, y, borderRadius);
			// ctx.arcTo(x, y, x + barWidth, y, borderRadius);
			// ctx.closePath();
			// ctx.fill();
			// }
			// }
			const canvas = document.getElementsByClassName(`video-interactor__canvas-video`);
			if (canvas && hasSelectedAudioOnly) {
				renderImageOnCanvas();
			}
			const binI = document.getElementById(`bin-${0}`);
			if (!binI) {
				requestAnimationFrame(() => drawBars(analyser, color));
			} else {
				analyser.getByteFrequencyData(dataArray);
				const drawThreshold = 5;
				const barHeight = 150;
				const minBarHeight = 1;
				for (let i = 0; i < FREQUENCY_BIN_COUNT; i++) {
					if (isAiTalking.current && color === userColor) break;
					const binI = document.getElementById(`bin-${i}`);
					const freqPctg =
						(dataArray[i] / 255) * ((1 - minBarHeight / barHeight) * 100) +
						(minBarHeight / barHeight) * 100 -
						20;

					binI.style.height = `${freqPctg >= drawThreshold ? freqPctg : drawThreshold}%`;
					// binI.style.backgroundColor = color;
				}
				requestAnimationFrame(() => drawBars(analyser, color));
			}
		} catch (error) {
			console.log('error', error);
			cancelAnimationFrame(() => drawBars(analyser, color));
		}
	};

	const renderImageOnCanvas = () => {
		const img = new Image(IMAGE_RESOLUTION, IMAGE_RESOLUTION);
		img.src = noCameraCanvasPlaceholder;
		img.onload = () => {
			if (contextRef?.current) {
				canvasRef.current.style.backgroundColor = '#fafafa';
				canvasRef.current.width = IMAGE_RESOLUTION;
				canvasRef.current.height = IMAGE_RESOLUTION;
				contextRef.current.drawImage(img, 0, 0, IMAGE_RESOLUTION, IMAGE_RESOLUTION);
			}
		};
	};

	async function startCameraPreview(device) {
		try {
			const initialStream = await getDeviceStream(device);
			frontCamera.current.srcObject = initialStream;
			await new Promise((resolve) => {
				frontCamera.current.addEventListener('loadedmetadata', resolve);
			});
			const localAudioStream = await navigator.mediaDevices.getUserMedia({
				audio: true,
			});
			saveMediaStream(localAudioStream);

			const joinAudioContext = audioContextRef.current;

			// Check if the audioPlayer is already connected to a MediaElementSourceNode
			if (!audioPlayerRef.current.sourceNode) {
				audioPlayerRef.current.sourceNode = joinAudioContext.createMediaElementSource(audioPlayerRef.current);
			}

			const micMediaSource = joinAudioContext.createMediaStreamSource(localAudioStream);
			const gainNode = joinAudioContext.createGain();
			const mediaStreamDestination = joinAudioContext.createMediaStreamDestination();

			const analyser = joinAudioContext.createAnalyser();
			audioPlayerRef.current.sourceNode.connect(analyser);
			analyser.fftSize = 8 * FREQUENCY_BIN_COUNT;
			analyser.connect(joinAudioContext.destination);

			const micAnalyser = joinAudioContext.createAnalyser();
			micMediaSource.connect(micAnalyser);
			micAnalyser.fftSize = 8 * FREQUENCY_BIN_COUNT;

			drawBars(micAnalyser, userColor);
			// drawBars(analyser, aiColor);

			// connect the mic to the destination
			micMediaSource.connect(mediaStreamDestination);

			// connect the gain node to the destination
			gainNode.connect(mediaStreamDestination);

			// connect the player to the gainNode
			audioPlayerRef.current.sourceNode.connect(gainNode);

			// connect player to client output
			gainNode.connect(joinAudioContext.destination);

			const combinedStream = new MediaStream([
				...canvasRef.current.captureStream().getVideoTracks(),
				...mediaStreamDestination.stream.getAudioTracks(),
			]);

			mediaRecorder.current = new MediaRecorder(combinedStream, { mimeType });

			setTimeout(() => {
				// get thumbnail in 1.5 seconds
				thumbnailRef.current = canvasRef?.current?.toDataURL('image/jpeg', 1.0);
			}, 1500);

			// contextRef.current.translate(cameraSettings?.current?.translateX, 0);
			const screenDensity = window.devicePixelRatio || 1;
			const sourceSize = calculateSourceDimensions(
				frontCamera.current.videoWidth,
				frontCamera.current.videoHeight,
				canvasRef.current.width,
				canvasRef.current.height,
			);
			const drawImage = {
				screenDensity,
				leftSource: sourceSize.sx, // space left origin (from camera)
				topSource: sourceSize.sy, // space top origin (from camera)
				widthSource: sourceSize.sWidth, // width size origin (from camera)
				heightSource: sourceSize.sHeight, // height size origin (from camera)
				leftTarget: 0, // space left destination (from canvas)
				topTarget: 0, // space top destination (from canvas)
				widthTarget: canvasWidth, // width size destination (from canvas)
				heightTarget: canvasHeight, // height size destination (from canvas)
			};
			if (DEBUG_RECORDER) {
				console.log('cameraSettings', {
					cameraSettings: cameraSettings.current,
					videoWidth: frontCamera.current.videoWidth,
					videoHeight: frontCamera.current.videoHeight,
					drawImage,
				});
			}
			canvasRef.current.style.backgroundColor = 'white';
			const poll = async () => {
				try {
					if (!canvasRef.current || !contextRef?.current)
						return frontCamera?.current?.requestVideoFrameCallback(poll);
					contextRef.current.clearRect(0, 0, canvasWidth, canvasHeight);
					contextRef.current?.drawImage?.(
						frontCamera.current,
						drawImage.leftSource,
						drawImage.topSource,
						drawImage.widthSource,
						drawImage.heightSource,
						drawImage.leftTarget,
						drawImage.topTarget,
						drawImage.widthTarget,
						drawImage.heightTarget,
					);
					triesRef.current = 0;
					// await new Promise((resolve) => {
					// 	setTimeout(resolve, FRAME_COOLDOWN);
					// });
					// requestAnimationFrame(poll);
					frontCamera.current.requestVideoFrameCallback(poll);
				} catch (error) {
					Sentry.captureException(error); // log error on sentry?
					console.error(error);
					triesRef.current += 1;
					if (triesRef.current >= 20) return;
					// requestAnimationFrame(poll);
					frontCamera.current.requestVideoFrameCallback(poll);
				}
			};
			setHasPreviewEnabled(true);

			poll();
		} catch (error) {
			console.log(error);
			Sentry.captureException(error); // log error on sentry?
			setHasPreviewEnabled(false);
		}
	}

	async function startAudioPreview() {
		try {
			const localAudioStream = await navigator.mediaDevices.getUserMedia({
				audio: true,
			});
			saveMediaStream(localAudioStream);

			const joinAudioContext = audioContextRef.current;

			// Check if the audioPlayer is already connected to a MediaElementSourceNode
			if (!audioPlayerRef.current.sourceNode) {
				audioPlayerRef.current.sourceNode = joinAudioContext.createMediaElementSource(audioPlayerRef.current);
			}

			const micMediaSource = joinAudioContext.createMediaStreamSource(localAudioStream);
			const gainNode = joinAudioContext.createGain();
			const mediaStreamDestination = joinAudioContext.createMediaStreamDestination();

			const analyser = joinAudioContext.createAnalyser();
			audioPlayerRef.current.sourceNode.connect(analyser);
			analyser.fftSize = 8 * FREQUENCY_BIN_COUNT;
			analyser.connect(joinAudioContext.destination);

			const micAnalyser = joinAudioContext.createAnalyser();
			micMediaSource.connect(micAnalyser);
			micAnalyser.fftSize = 8 * FREQUENCY_BIN_COUNT;

			renderImageOnCanvas();
			// drawBars(analyser, aiColor);
			drawBars(micAnalyser, userColor);

			// connect the mic to the destination
			micMediaSource.connect(mediaStreamDestination);

			// connect the gain node to the destination
			gainNode.connect(mediaStreamDestination);

			// connect the player to the gainNode
			audioPlayerRef.current.sourceNode.connect(gainNode);

			// connect player to client output
			gainNode.connect(joinAudioContext.destination);

			const combinedStream = new MediaStream([
				...canvasRef.current.captureStream().getVideoTracks(),
				...mediaStreamDestination.stream.getAudioTracks(),
			]);

			mediaRecorder.current = new MediaRecorder(combinedStream, { mimeType });

			setTimeout(() => {
				// get thumbnail in 1.5 seconds
				thumbnailRef.current = canvasRef?.current?.toDataURL('image/jpeg', 1.0);
			}, 1500);

			setHasPreviewEnabled(true);
		} catch (error) {
			console.error(error);
			setHasPreviewEnabled(false);
		}
	}

	const calculateSourceDimensions = (videoWidth, videoHeight, targetWidth, targetHeight) => {
		if (!videoWidth || !videoHeight || !targetWidth || !targetHeight) {
			console.error('Invalid input dimensions', { videoWidth, videoHeight, targetWidth, targetHeight });
			return null;
		}

		// If video is already square, return full frame
		if (videoWidth === videoHeight) {
			return {
				sx: 0,
				sy: 0,
				sWidth: videoWidth,
				sHeight: videoHeight,
			};
		}

		const videoAspect = videoWidth / videoHeight;
		const targetAspect = targetWidth / targetHeight;

		let sx = 0;
		let sy = 0;
		let sWidth;
		let sHeight;

		if (videoAspect > targetAspect) {
			// Video is wider than target: crop width
			sHeight = videoHeight;
			sWidth = Math.round(videoHeight * targetAspect);
			sx = Math.round((videoWidth - sWidth) / 2);
		} else {
			// Video is taller than target: crop height
			sWidth = videoWidth;
			sHeight = Math.round(videoWidth / targetAspect);
			sy = Math.round((videoHeight - sHeight) / 2);
		}

		return {
			sx: Math.max(0, sx),
			sy: Math.max(0, sy),
			sWidth: Math.min(sWidth, videoWidth),
			sHeight: Math.min(sHeight, videoHeight),
		};
	};

	const getDeviceStream = async (device) => {
		const capabilities = typeof device?.getCapabilities === 'function' ? device.getCapabilities() : null;

		const videoStream = await navigator.mediaDevices.getUserMedia({
			audio: false,
			video: {
				...(device?.deviceId ? { deviceId: device?.deviceId } : {}),
				width: { min: 480, ideal: 1280, max: 1920 },
				height: { min: 480, ideal: 720, max: 1920 },
				frameRate: { min: 24, ideal: 60, max: 60 },
				facingMode: 'user',
			},
		});
		saveMediaStream(videoStream);
		await new Promise((resolve) => {
			const onResolve = () => {
				frontCamera.current.removeEventListener('loadedmetadata', onResolve);
				frontCamera.current.removeEventListener('playing', onResolve);
				resolve();
			};
			if (!frontCamera.current.paused || hasSelectedAudioOnly) {
				resolve();
				return;
			}
			frontCamera.current.addEventListener('loadedmetadata', onResolve);
			frontCamera.current.addEventListener('playing', onResolve);
			frontCamera.current.play();
		});
		const videoTrack = videoStream.getVideoTracks()[0];
		const videoSettings = videoTrack.getSettings();

		const isMirrored = capabilities?.facingMode?.includes('user') || false;

		setIsVideoMirrored(isMirrored);

		if (!isMirrored) {
			cameraSettings.current.translateX = canvasWidth;
			cameraSettings.current.scaleX = -1;
		} else {
			cameraSettings.current.translateX = 0;
			cameraSettings.current.scaleX = 1;
		}

		const isHorizontal = videoSettings.width > videoSettings.height;

		const aspectRatio = isHorizontal
			? videoSettings.height / videoSettings.width
			: videoSettings.width / videoSettings.height;

		let newWidth = canvasWidth / aspectRatio;
		let newHeight = canvasHeight;

		if (!isHorizontal) {
			newWidth = canvasWidth;
			newHeight = canvasHeight / aspectRatio;
		}

		// const x = canvasWidth / 2 - newWidth / 2;
		// const y = canvasHeight / 2 - newHeight / 2;

		const destW = newWidth;
		const destH = newHeight;

		let originX = 0;
		let originY = 0;
		if (destW !== destH) {
			if (destW > destH) originX = Math.abs(destW - destH) / 2;
			if (destH > destW) originY = Math.abs(destW - destH) / 2;
		}

		const squaredSize = Math.min(destW, destH);

		const newSize = {
			...cameraSettings.current,
			originX,
			originY,
			destW,
			destH,
			squaredSize,
		};
		cameraSettings.current = newSize;

		return videoStream;
	};

	function debounce(func, delay) {
		let timeoutId;
		return function (...args) {
			clearTimeout(timeoutId);
			timeoutId = setTimeout(() => {
				func.apply(this, args);
			}, delay);
		};
	}

	const debouncedSpeechHandler = debounce((label, naturalVoice) => speechHandler(label, naturalVoice), 500);

	async function onStartRecord() {
		recordingTime.current = performance.now();
		if (mediaRecorder?.current) {
			mediaRecorder.current.ondataavailable = function (e) {
				chunks.push(e.data);
			};
			mediaRecorder.current.onstop = onStop;
			setTimeout(() => {
				mediaRecorder.current.start(100);
			}, 500);
			const label = (question?.label || '').replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim, '');

			debouncedSpeechHandler(label, question?.naturalVoice);
		}
	}

	async function onStopRecord() {
		mediaRecorder.current.stop();
		setTimeout(() => {
			stopAllMediaStreams();
		}, 500);
	}

	const validateVideoModeration = () => {
		let faceCoveredCount = 0;
		let inappropriateDrawnCount = 0;
		let inappropriateDressedCount = 0;
		let inappropriateLanguageCount = 0;
		let isUnderagedCount = 0;
		let nudityCount = 0;
		let personCount = 0;
		let nonSenseCount = 0;
		let noResponseCount = 0;

		for (const moderation of moderationMap.current) {
			if (moderation?.faceCoveredPercentage >= 50) faceCoveredCount += 1;
			if (moderation?.inappropriateDrawn) inappropriateDrawnCount += 1;
			if (moderation?.inappropriateDressed) inappropriateDressedCount += 1;
			if (moderation?.inappropriateLanguage) inappropriateLanguageCount += 1;
			if (moderation?.isUnderaged && moderation?.age > 5 && moderation?.age < 18) isUnderagedCount += 1;
			if (moderation?.nudity) nudityCount += 1;
			if (moderation?.person || moderation?.realPerson) personCount += 1;
			if (moderation?.isNonSense) nonSenseCount += 1;
			if (moderation?.noResponse) noResponseCount += 1;
		}
		if (!hasSelectedAudioOnly && moderationMap.current.length > 1) {
			if (
				personCount < 1 ||
				faceCoveredCount > 1 ||
				inappropriateDrawnCount > 1 ||
				inappropriateLanguageCount > 1
			) {
				return false;
			}
			if (nonSenseCount > 1 || noResponseCount > 1) return false;
			if (moderationMap.current.length > 1 && isUnderagedCount > 1) return false;
			if (inappropriateDressedCount > 0 || nudityCount > 0) return false;
		} else if (moderationMap.current.length > 1 && inappropriateLanguageCount > 1) {
			return false;
		}

		return true;
	};

	const downloadVideo = (blob) => {
		const url = URL.createObjectURL(blob);
		const a = document.createElement('a');
		document.body.appendChild(a);
		a.style = 'display: none';
		a.href = url;
		a.download = 'test.webm';
		a.click();
		window.URL.revokeObjectURL(url);

		setLoadingVideo(true);
		onSuccess('OK', question?.style);
	};

	async function onStop() {
		const blob = new Blob(chunks, { type: mimeType });
		if (DEBUG_RECORDER) return downloadVideo(blob);
		await sendVideoToApi(blob);
	}

	useEffect(() => {
		// Initialize AudioContext if it's not already created
		if (!audioContextRef.current) {
			audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
		}

		// Clean up the AudioContext when the component unmounts
		return () => {
			if (audioContextRef.current && typeof audioContextRef.current.close === 'function') {
				audioContextRef.current.close();
			}
		};
	}, []);

	useEffect(() => {
		const skipVideoQuestion = window.sessionStorage.getItem('skipVideoQuestion');
		let previousSectionsSkippedVideoQuestions = false;
		Object.keys(answers).forEach((key) => {
			if (answers[key].type === 'guided-video-question' && answers[key].value === 'SKIP') {
				previousSectionsSkippedVideoQuestions = 'true';
			}
		});
		if (skipVideoQuestion === 'true' || previousSectionsSkippedVideoQuestions === 'true') return;

		if (!goodBye) {
			getPermission();
			initializeAudioPlayer();
		}
		// eslint-disable-next-line
	}, [goodBye]);

	const handleContinue = async () => {
		const camera = await checkPermission('camera');
		const microphone = await checkPermission('microphone');

		if (!microphone || (!hasSelectedAudioOnly && !camera)) {
			setHasPreviewEnabled(false);
			await onRequestPermission();
			setEnableSkip(true);
			return;
		}

		if (!hasPreviewEnabled) {
			await getPermission();
			setIsStartRecording(true);
			return setTimeout(() => {
				onStartRecord();
			}, 500);
		}

		if (camera && microphone) {
			setIsStartRecording(true);
			return onStartRecord();
		}

		if (hasSelectedAudioOnly && microphone) {
			setIsStartRecording(true);
			return onStartRecord();
		}

		// fallback
		setHasPreviewEnabled(false);
		await onRequestPermission();
		setEnableSkip(true);
	};

	if (goodBye && loadingVideo)
		return (
			<div className={el('welcome')} onClick={() => setLoadingVideo(false)}>
				<CenteredLoader />

				<div className={el('welcome-container')}>
					<h1 className={el('welcome-title')} ref={initFocusRef} tabIndex={-1}>
						Processing Your Video Response
					</h1>
					<p className={el('welcome-content')} style={{ marginBottom: 0 }}>
						Thank you for your response! Please do not close the browser window as there are more questions
						to follow in the survey
					</p>
				</div>
			</div>
		);

	if (goodBye && !loadingVideo && errorUploadingVideo)
		return (
			<div className={el('welcome')}>
				<div className={el('welcome-container')}>
					<h1 className={el('welcome-title')}>Sorry, we have an error uploading your video.</h1>
				</div>
			</div>
		);

	return (
		<div className={className}>
			<div className={el('square-wrapper')}>
				<Transcript
					ref={textToShowRef}
					{...{
						aiStatus,
						showImage,
						demoImageURL,
						squareWrapperSideSize,
						setPttEngine,
						question,
						isStartRecording,
						handleContinue,
						canSkip,
						onSkip,
						enableSkip,
					}}
				/>
			</div>

			<div
				ref={squareWrapperRef}
				className={el('square-wrapper')}
				style={{
					...(DEBUG_RECORDER ? { overflow: 'visible' } : {}),
				}}
			>
				<VideoRecorder
					{...{
						isVideoMirrored,
						isVoiceDetected,
						showImage,
						demoImageURL,
						squareWrapperSideSize,
						canvasRef,
						frontCamera,
						debug: DEBUG_RECORDER,
					}}
				/>
			</div>
			{toastVisible && <Toaster message={toastMessage} visible onClose={() => false} />}
		</div>
	);
};

VideoInteractor.propTypes = {
	question: PropTypes.object.isRequired,
	productId: PropTypes.any,
	responseId: PropTypes.string,
	onSuccess: PropTypes.func.isRequired,
	canSkip: PropTypes.bool.isRequired,
	onSkip: PropTypes.func.isRequired,
	onRequestPermission: PropTypes.func.isRequired,
	asyncVideoUpload: PropTypes.func.isRequired,
	isLastQuestion: PropTypes.bool,
};

export default VideoInteractor;
