// packages
import React, { useState, useRef, useEffect, useId } from 'react';
import PropTypes from 'prop-types';
import { useGesture } from '@use-gesture/react';

// utilities
import cn from 'src/utilities/bem-cn';

// hooks
import useFocusLock from 'src/utilities/hooks/useFocusLock';
import useIsKeyboardNavigation from 'src/utilities/hooks/useIsKeyboardNavigation';

// styles
import './styles.scss';

const baseClassName = 'image-zoom';
const el = (name) => cn(baseClassName, name);

// constants
const SCALE_UPPER_BOUND = 2;
const dragConfigOptions = {
	swipe: {
		distance: 25 /** Default is 50 https://use-gesture.netlify.app/docs/options/#swipedistance */,
	},
};

const ImageZoom = ({ children, className = '', onKeyDown, ...rest }) => {
	// state
	const [expanded, setExpanded] = useState(false);
	const [tap, setTap] = useState(false);
	// * Used for drag events
	const [expandedPosition, setExpandedPosition] = useState([0, 0]);
	// * Used for pinch events
	const [expandedScale, setExpandedScale] = useState(1);
	const [cancelTransition, setCancelTransition] = useState(true);
	const [modalRef, setModalRef] = useState(null);

	const uniqId = useId();

	const thumbnailButtonRef = useRef(null);
	const expandedRef = useRef(false);
	const expandedImageRef = useRef(null);

	useFocusLock(modalRef);

	const isKeyboardNavigation = useIsKeyboardNavigation();

	// functions
	const handleKeyboardInput = (event) => {
		if (!expandedRef.current) return;
		if (event.key === 'Escape' || event.key === 'Esc') {
			setExpanded(false);
			expandedRef.current = false;
		}
	};

	const handleOpen = (event) => {
		event.stopPropagation();
		event.preventDefault();
		setExpanded(true);
		expandedRef.current = true;
	};

	const handleClose = (event) => {
		event.preventDefault();
		event.stopPropagation();
		if (expanded) {
			setExpandedPosition([0, 0]);
			setExpandedScale(1);
			setExpanded(false);
			expandedRef.current = false;
			if (isKeyboardNavigation) {
				thumbnailButtonRef.current?.focus();
			}
		}
	};

	const cancelEvent = (e) => {
		if (e?.preventDefault) {
			e.preventDefault();
		}
		e.stopPropagation();
	};

	const handleDrag = ({ event, cancel, ...drag }) => {
		if (drag.pinching) return;
		const x = expandedScale <= 1 && drag.last ? 0 : drag.offset[0];
		const y = expandedScale <= 1 && drag.last ? 0 : drag.offset[1];
		setCancelTransition(!drag.last);
		setExpandedPosition([x, y]);
		if (expandedScale <= 1 && (drag.swipe[1] > 0 || (drag.last && drag.offset[1] > 100))) {
			setExpandedPosition([0, 0]);
			handleClose(event);
		}
	};

	const handlePinch = ({ event, ...pinch }) => {
		const [scale, _] = pinch.offset;

		if (scale < SCALE_UPPER_BOUND && pinch.last && scale < (1 + SCALE_UPPER_BOUND) / 2) {
			setCancelTransition(false);
			setExpandedPosition([0, 0]);
		}
		setExpandedScale(scale);
	};

	const handleGestures = useGesture(
		{
			onDrag: (event) => {
				handleDrag(event);
			},
			onPinch: (event) => {
				handlePinch(event);
			},
		},
		{
			drag: {
				...dragConfigOptions,
				from: () => {
					const { x, y } = expandedImageRef.current.getBoundingClientRect();
					return [expandedPosition[0], expandedPosition[1]];
				},
				bounds: () => ({
					left: -(window.innerWidth / 2),
					right: window.innerWidth / 2,
					top: -(window.innerHeight / 2),
					bottom: window.innerHeight / 2,
				}),
				rubberband: true,
			},
			pinch: { scaleBounds: { min: 1, max: SCALE_UPPER_BOUND }, rubberband: true },
		},
	);
	const resetExpandedPosition = () => {
		if (expanded) {
			setExpandedScale(expandedScale < 2 ? 2 : 1);
			setExpandedPosition([0, 0]);
		}
	};

	// life cycle
	useEffect(() => {
		window.addEventListener('keyup', handleKeyboardInput);
		return () => {
			window.removeEventListener('keyup', handleKeyboardInput);
		};
	}, []);

	return (
		<div {...rest} className={`${baseClassName} ${className || ''} ${expanded ? 'expanded' : 'collapsed'}`}>
			<span className={el('preview-container')}>
				<button
					ref={thumbnailButtonRef}
					type="button"
					tabIndex={0}
					className={el('preview-trigger')}
					onClick={handleOpen}
					onTouchStart={(e) => {
						e.preventDefault();
						cancelEvent(e);
						setTap(true);
					}}
					onTouchEnd={(e) => {
						cancelEvent(e);
						e.preventDefault();
						if (tap) handleOpen(e);
					}}
					onTouchMove={(e) => {
						cancelEvent(e);
						e.preventDefault();
						setTap(false);
					}}
					aria-expanded={expanded}
					aria-label="Expand the image"
					{...{ onKeyDown }}
				>
					{children}
				</button>
			</span>

			{expanded && (
				<div
					{...handleGestures()}
					onClick={cancelEvent}
					onDoubleClick={resetExpandedPosition}
					onTouchStart={cancelEvent}
					ref={expandedImageRef}
					style={{
						transform: `translate3d(${expandedPosition[0]}px, ${expandedPosition[1]}px, 0) scale(${expandedScale})`,
						transition: cancelTransition ? 'none' : null,
					}}
					className={`${el('asset-window')} ${expanded ? 'expanded' : ''}`}
					id={`image-zoom-preview-${uniqId}`}
				>
					<div
						className={el('overlay')}
						onTouchStart={(e) => {
							cancelEvent(e);
							setTap(true);
						}}
						onTouchMove={(e) => {
							cancelEvent(e);
							setTap(false);
						}}
						onTouchEnd={(e) => {
							cancelEvent(e);
							if (tap) handleClose(e);
						}}
						onMouseDown={(e) => {
							e.preventDefault();
							cancelEvent(e);
							setTap(true);
						}}
						onMouseMove={(e) => {
							cancelEvent(e);
							e.preventDefault();
							setTap(false);
						}}
						onMouseUp={(e) => {
							cancelEvent(e);
							e.preventDefault();
							if (tap) handleClose(e);
						}}
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							if (tap) handleClose(e);
						}}
					/>
					<div ref={setModalRef} className={el('asset-window-inner')}>
						<button
							type="button"
							tabIndex={0}
							className={el('close')}
							onClick={handleClose}
							onTouchStart={cancelEvent}
							disabled={!expanded} // Prevent keyboard focusing when not expanded
							aria-label="Close the image preview"
							aria-controls={`image-zoom-preview-${uniqId}`}
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								xlinkHref="http://www.w3.org/1999/xlink"
								width="39"
								height="37"
								viewBox="0 0 39 37"
								className="x-icon"
							>
								<g id="a">
									<g transform="translate(-1456 -1104)">
										<g transform="translate(1464.5 1112.5)">
											<line
												x2="18.886"
												y2="18.346"
												transform="translate(0.54 1.079)"
												fill="none"
												stroke="#000000"
												strokeWidth={1}
											/>
											<line
												x1="20.074"
												y2="20.065"
												transform="translate(0)"
												fill="none"
												stroke="#000000"
												strokeWidth={1}
											/>
										</g>
									</g>
								</g>
							</svg>
						</button>
						{children}
					</div>
				</div>
			)}
		</div>
	);
};

ImageZoom.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
	className: PropTypes.string,
	style: PropTypes.object,
	onKeyDown: PropTypes.func,
};

export default ImageZoom;
