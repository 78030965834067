import React, { useState, useEffect, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { withTranslation } from 'react-i18next';
import cn from 'src/utilities/bem-cn';
import Button from 'src/components/new/Button';
import * as misc from 'src/utilities/misc';
import useIsMobile from 'src/utilities/hooks/useIsMobile';
import useDebounceCallback from 'src/utilities/debounceCallback';
import _ from 'lodash';
import GlobalScrollIndicator from 'src/components/helpers/GlobalScrollIndicator';
import RankedResetButton from '../../../../components/shared/RankedResetButton';
import Question from '../../components/Question';
import * as actions from '../../actions';
import * as selectors from '../../selectors';
import './styles.scss';
import { getQuestionsWithLabels } from '../../../../utilities/misc';

const containerHeightBuffer = 10;
const buttonHeight = 68;
const className = 'questions-container';
const el = (name, mod) => cn(className, name, mod);

const CustomQuestion = ({
	t,
	study = {},
	RID,
	setNextSection,
	setSectionAnswers,
	setQualifiers,
	currentSection,
	sections,
	jumpToSection,
	jumpToQuestion,
	questionIndex,
	setStep,
	setQuestionIndex,
	swipeResults,
	allAnswers,
	setDisqualified,
	setDisqualifiedWithReason,
}) => {
	const { questions } = currentSection;
	const dispatch = useDispatch();

	const [containerHeight, setContainerHeight] = useState(window.innerHeight - containerHeightBuffer);
	const [justifyContent, setJustifyContent] = useState(false);
	const responseLoading = useRef(false);
	// Filter through currentSection.questions and remove any questions without a label

	const questionsWithLabels = getQuestionsWithLabels({ questions });

	questionsWithLabels.forEach((question) => {
		if (!question?.options) return;
		question.options = question.options.sort((a, b) => a.order - b.order);
	});

	// If the list of questions with labels is empty proceed to the next section
	if (questionsWithLabels.length === 0) {
		setNextSection();
		return false;
	}

	const [answers, setAnswers] = useState({});

	// const [questionIndex, setQuestionIndex] = useState(0);
	const [buttonEnabled, setButtonEnabled] = useState(false);
	const [contentOverflow, setContentOverflow] = useState(false);
	const [resetRankedOptions, setResetRankedOptions] = useState(false);

	const [updateDebounceTimer, debounceCallback] = useDebounceCallback();
	const isRankedQuestion = questionsWithLabels[questionIndex]?.style === 'ranked';
	const scrollContainerClass = 'scroll-container';
	const isMobile = useIsMobile();

	useEffect(() => {
		updateContainerHeight();
		window.addEventListener('resize', updateContainerHeight);
		window.addEventListener('orientationchange', updateContainerHeight);
		return () => {
			window.removeEventListener('resize', updateContainerHeight);
			window.removeEventListener('orientationchange', updateContainerHeight);
		};
	}, []);

	useEffect(() => {
		updateContainerHeight();
		updateDebounceTimer();
	}, [questionIndex]);

	const preQuestion = useRef(true);
	const elementRef = useRef(true);

	useEffect(() => {
		const question = questionsWithLabels[questionIndex];
		if (!question) return;

		const isOptional = misc.getQuestionSetting(question, 'optional') === 'true';
		const settings = question?.settings;
		if (question?.style === 'ranked') {
			const topNSet = settings?.find((s) => s?.label === 'top-n')?.value === 'true';
			const topN = topNSet
				? parseInt(settings?.find((s) => s?.label === 'top-n-limit')?.value) || 1
				: Math.min(answers[question.id]?.availableOptions?.length || question?.options?.length || 0, 10);
			const answerLength = answers[question.id]?.value?.length || 0;
			const isEnabled = isOptional ? answerLength === 0 || answerLength >= topN : answerLength >= topN;
			setButtonEnabled(isEnabled);
			return;
		}

		if (!isOptional) return;

		if (question?.style !== 'ranked') {
			setButtonEnabled(true);
		}
	}, [questionsWithLabels, questionIndex, answers]);

	const updateContainerHeight = () => {
		setContainerHeight(window.innerHeight - containerHeightBuffer);
		setTimeout(() => {
			const isScrolled =
				elementRef?.current?.scrollHeight && elementRef.current.scrollHeight > window.innerHeight;

			setJustifyContent(isScrolled);
			setContentOverflow(isScrolled);
		}, 100);
	};

	const isGridQuestion = questionsWithLabels[questionIndex]?.style === 'grid';

	useEffect(() => {
		// Do pre-logic before we render a question
		if (preQuestion.current) {
			preQuestion.current = false;
		}
	}, [answers, preQuestion, questionIndex, questionsWithLabels, setNextSection, setQuestionIndex, setSectionAnswers]);
	const handleResults = async () => {
		// Reset the button active state to disabled after answering a question
		setButtonEnabled(false);

		// Check logic for every question
		dispatch(
			actions.checkLogic({
				question: questionsWithLabels[questionIndex],
				answers,
				currentQuestionIndex: questionIndex,
				study,
			}),
		);
	};

	const handleReset = (question) => {
		setAnswers({
			...answers,
			[question.id]: {
				...answers[question.id],
				value: [],
			},
		});
		const scrollContainer = document.querySelector(`[class*='${scrollContainerClass}']`);
		scrollContainer.scrollTo(0, 0, { behavior: 'smooth' });
		setResetRankedOptions(true);
		setTimeout(() => {
			setResetRankedOptions(false);
		}, 50);
	};

	const handleChange =
		(question) =>
		(value, type, otherValue = '', otherOptionId = 0, noneOfTheAboveSelected = false) => {
			// if waiting for response... exit
			if (responseLoading.current) {
				return;
			}

			// Store the answers in a local state variable
			setAnswers({
				...answers,
				[question.id]: {
					value,
					type,
					otherValue,
					otherOptionId,
					sectionId: currentSection.id,
				},
			});

			const hasValidUserInputAnswer = misc.checkForValidInputs(
				question,
				value,
				question.style,
				noneOfTheAboveSelected,
				otherValue,
			);

			debounceCallback(() => setButtonEnabled(hasValidUserInputAnswer));
		};

	if (!questionsWithLabels[questionIndex]) {
		return null;
	}

	return (
		<div
			className={className}
			ref={elementRef}
			style={{
				height: `${containerHeight}px`,
				maxHeight: `${containerHeight}px`,
				minHeight: `${containerHeight}px`,
				justifyContent: `${justifyContent ? 'flex-start' : 'center'}`,
			}}
		>
			{/* <h3 className={el('heading')}>{t('get-started-with-questions')}</h3> */}
			<div className={el('questions')}>
				<div key={questionsWithLabels[questionIndex].id}>
					<Question
						language={study.language}
						question={questionsWithLabels[questionIndex]}
						value={
							_.size(answers) > 0 && answers[questionsWithLabels[questionIndex].id]
								? answers[questionsWithLabels[questionIndex].id].value
								: 0
						}
						onChange={handleChange(questionsWithLabels[questionIndex])}
						answers={answers}
						setAnswers={setAnswers}
						currentSection={currentSection}
						handleResults={handleResults}
						onImageLoad={updateContainerHeight}
						resetRankedOptions={resetRankedOptions}
					/>
				</div>
				{!isGridQuestion && (
					<div className={el('inline-button')}>
						<Button
							disabled={!buttonEnabled}
							label={t('continue')}
							onClick={handleResults}
							tabindex={!buttonEnabled ? '-1' : 0}
							dataTestId="continue-button"
						/>
					</div>
				)}
				{isRankedQuestion && isMobile && (
					<RankedResetButton
						handleReset={handleReset}
						disabled={answers[questionsWithLabels[questionIndex]?.id]?.value?.length < 1}
						question={questionsWithLabels[questionIndex]}
					/>
				)}
				<GlobalScrollIndicator show={contentOverflow} />
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	study: selectors.getStudy(state),
	sections: selectors.getSections(state),
	currentSection: selectors.getCurrentSection(state),
	questionIndex: selectors.getQuestionIndex(state),
	allAnswers: selectors.getAnswers(state),
	swipeResults: selectors.getResults(state),
	RID: selectors.getResponseRID(state),
});

const mapDispatchToProps = (dispatch) => ({
	setSectionAnswers: (answers) => dispatch(actions.setAnswers(answers)),
	setQualifiers: (qualifiers) => dispatch(actions.setQualifiers(qualifiers)),
	setStep: (step) => dispatch(actions.setStep(step)),
	setNextSection: () => dispatch(actions.setNextSection()),
	setQuestionIndex: (index) => dispatch(actions.setQuestionIndex(index)),
	jumpToQuestion: (questionId, sectionId) => dispatch(actions.jumpToQuestion(questionId, sectionId)),
	jumpToSection: (sectionId) => dispatch(actions.jumpToSection(sectionId)),
	setDisqualified: (answers, audienceUuid) => dispatch(actions.setDisqualified(answers, audienceUuid)),
	setDisqualifiedWithReason: (answers, reason, audienceUuid, setDisqualifiedWithReason) =>
		dispatch(actions.setDisqualified(answers, audienceUuid, reason, setDisqualifiedWithReason)),
});

export default withTranslation('main')(connect(mapStateToProps, mapDispatchToProps)(CustomQuestion));
